import React from 'react';
import { ASTM_IMPERIAL_DIAMETER_REBAR_SIZE, MeasurementFullData } from '../../../../../types/measurement';
import { DerivedProps } from '../../SharedTypes';
import { ScanType } from '../../../../../types/proceq';
import { getConvertedSectionTableData, RawSectionTableData } from '../../../../../utils/tableDataUtils';
import SectionTable from '../../shared/SectionTable';
import styles from '../../DataViewers.styl';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { getProfometerValue } from './getMeasurementPresets';
import { useFormatMessage } from '../../../../../localization/useFormatMessage';
import { FormatMessageFunc } from '../../../../../types';
import { CORROSION_SCAN_TYPES } from '../../../../../types/profometer';

export const title = (
    <>
        <FormattedMessage id={'Proceq.Settings'} />
        {' ­­» '}
        <FormattedMessage id={'Proceq.Settings.ImageProcessing'} />
    </>
);

export const getMeasurementsData = (
    data: MeasurementFullData | undefined,
    scanType: ScanType,
    isMetric: boolean,
    convert: (value: string | number, unitId: string) => string | number,
    formatMessage: FormatMessageFunc
) => {
    if (!data) {
        return [];
    }
    const dataContent = data.settings[0].content;

    const {
        firstLayerRebarDiameter,
        secondLayerRebarDiameter,
        autoRebarSpacing,
        nrc,
        ambientTempInCelsius,
        ambientTempInFahrenheit,
        autoPotentialShift,
        potentialShift,
    } = dataContent.imageProcessing || {};
    const { gridSpacingX, gridSpacingY } = dataContent.areaScanStatus?.areaScanParameters || {};
    const { numberOfVerticalLines = 0, numberOfHorizontalLines = 0 } =
        dataContent.areaScanStatus?.areaScanParameters || {};

    const gridWidthX = gridSpacingX * (numberOfVerticalLines - 1);
    const gridHeightY = gridSpacingY * (numberOfHorizontalLines - 1);
    const isLineScan = scanType === ScanType.LineScan;
    const isLineAreaScan = scanType === ScanType.AreaScan || isLineScan;
    const isCorrosion = CORROSION_SCAN_TYPES.has(scanType);

    const presetsRaw: RawSectionTableData[] = [
        {
            label: 'App.HTML.Profometer.Logbook.ConcreteTemperatureUnits',
            unitId: 'Profometer.Logbook.AmbientTemperature',
            value: isMetric ? ambientTempInCelsius : ambientTempInFahrenheit,
            enable: isCorrosion,
        },
        {
            label: 'App.HTML.Profometer.Logbook.AutoPotentialShift',
            value: autoPotentialShift ? formatMessage({ id: 'Proceq.On' }) : formatMessage({ id: 'Proceq.Off' }),
            enable: isCorrosion,
        },
        {
            label: 'App.HTML.Profometer.Logbook.PotentialShift',
            value: potentialShift,
            enable: isCorrosion,
        },
        {
            label: isLineScan
                ? isMetric
                    ? 'App.HTML.Profometer.MeasurementSettings.FirstLayerRebarDiameterUnits.LineScan'
                    : 'App.HTML.Profometer.MeasurementSettings.FirstLayerRebarDiameter.LineScan'
                : isMetric
                  ? 'App.HTML.Profometer.MeasurementSettings.FirstLayerRebarDiameterUnits'
                  : 'App.HTML.Profometer.MeasurementSettings.FirstLayerRebarDiameter',
            unitId: 'Profometer.Measurement.RebarDiameter',
            value:
                !isMetric && firstLayerRebarDiameter
                    ? ASTM_IMPERIAL_DIAMETER_REBAR_SIZE[firstLayerRebarDiameter]
                    : firstLayerRebarDiameter,
            enable: isLineAreaScan && !isCorrosion,
        },
        {
            label: isLineScan
                ? isMetric
                    ? 'App.HTML.Profometer.MeasurementSettings.SecondLayerRebarDiameterUnits.LineScan'
                    : 'App.HTML.Profometer.MeasurementSettings.SecondLayerRebarDiameter.LineScan'
                : isMetric
                  ? 'App.HTML.Profometer.MeasurementSettings.SecondLayerRebarDiameterUnits'
                  : 'App.HTML.Profometer.MeasurementSettings.SecondLayerRebarDiameter',
            unitId: 'Profometer.Measurement.RebarDiameter',
            value:
                !isMetric && secondLayerRebarDiameter
                    ? ASTM_IMPERIAL_DIAMETER_REBAR_SIZE[secondLayerRebarDiameter]
                    : secondLayerRebarDiameter,
            enable: isLineAreaScan && !isCorrosion,
        },
        {
            label: 'App.HTML.Profometer.MeasurementSettings.AutoRebarSpacingA1',
            value: autoRebarSpacing ? formatMessage({ id: 'Proceq.On' }) : formatMessage({ id: 'Proceq.Off' }),
            enable: !isCorrosion && nrc && isLineAreaScan,
        },
        {
            label: isLineScan
                ? 'App.HTML.Profometer.MeasurementSettings.FirstLayerRebarSpacing.LineScan'
                : 'App.HTML.Profometer.MeasurementSettings.FirstLayerRebarSpacing',
            unitId: 'Profometer.Measurement.RebarSpacing',
            value: dataContent.imageProcessing?.firstLayerRebarSpacing,
            enable: !isCorrosion && nrc && !autoRebarSpacing && isLineAreaScan,
        },
        {
            label: isLineScan
                ? 'App.HTML.Profometer.MeasurementSettings.SecondLayerRebarSpacing.LineScan'
                : 'App.HTML.Profometer.MeasurementSettings.SecondLayerRebarSpacing',
            unitId: 'Profometer.Measurement.RebarSpacing',
            value: dataContent.imageProcessing?.secondLayerRebarSpacing,
            enable: !isCorrosion && nrc && isLineAreaScan,
        },
        {
            label: 'App.HTML.PUNDIT.MeasurementPresets.XSpacing',
            unitId: 'Profometer.Measurement.GridSpacing',
            value: gridSpacingX,
            enable: scanType === ScanType.AreaScan,
        },
        {
            label: 'App.HTML.PUNDIT.MeasurementPresets.YSpacing',
            unitId: 'Profometer.Measurement.GridSpacing',
            value: gridSpacingY,
            enable: scanType === ScanType.AreaScan,
        },
        {
            label: 'App.HTML.Profometer.MeasurementSettings.GridWidthX',
            unitId: 'Profometer.Measurement.GridSpacing',
            value: gridWidthX,
            enable: scanType === ScanType.AreaScan,
        },
        {
            label: 'App.HTML.Profometer.MeasurementSettings.GridHeightY',
            unitId: 'Profometer.Measurement.GridSpacing',
            value: gridHeightY,
            enable: scanType === ScanType.AreaScan,
        },
        {
            label: 'Proceq.ScanDirection',
            value: formatMessage({
                id:
                    dataContent.areaScanStatus?.areaScanParameters.startDirection === 'X'
                        ? 'App.HTML.Profometer.MeasurementSettings.ScanDirection.Horizontal'
                        : 'App.HTML.Profometer.MeasurementSettings.ScanDirection.Vertical',
            }),
            enable: scanType === ScanType.AreaScan,
        },
    ];

    return getConvertedSectionTableData(presetsRaw, scanType, isMetric, getProfometerValue(convert));
};

const ImageProcessing: React.FunctionComponent<{ data: MeasurementFullData } & DerivedProps> = (props) => {
    const { data, scanType, isMetric, convert, showTitle } = props;
    const formatMessage = useFormatMessage();
    const sectionTableData = getMeasurementsData(data, scanType, isMetric, convert, formatMessage);

    return (
        <SectionTable
            showTitle={showTitle}
            className={[styles.table, 'table-measurement-presets'].join(' ')}
            title={<span className={styles.sub_header}>{title}</span>}
            dataSource={sectionTableData}
        />
    );
};

export default ImageProcessing;
