import React, { useCallback, useMemo, useState } from 'react';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { Pagination } from 'antd';
import styles from '../DataView.styl';
import ButtonExportHTML from '../../MeasurementExport/ButtonExportHTML';
import { MeasurementPage } from '../../../analytics/analyticsConstants';
import MeasurementTable, { getMeasurementColumns } from '../MeasurementTable';
import AnalyticsButton from '../../AnalyticsComponents/Button';
import FormattedMessage from '../../../localization/FormatMessage';
import { useHTMLViewExportIDs, useMeasurements } from '../../../hooks/useProductData';
import { ProductCode } from '../../../types/proceq';
import { DEFAULT_PAGE_SIZE } from '../DataViewProvider';
import useMeasurementImportParams from '../../MeasurementImport/useMeasurementImportParams';
import { getHTMLViewerMeasurementPath } from '../../Routes/urls';

const HTMLViewerTable: React.FunctionComponent<{ productCode: ProductCode }> = (props) => {
    const { productCode } = props;
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const handleSelectKeys = useCallback((values: React.Key[]) => {
        setSelectedKeys(values.map((d) => d.toString()));
    }, []);

    const measurements = useMeasurements();
    const measurementIDs = useHTMLViewExportIDs(productCode);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

    const dataSource = (measurementIDs ?? []).map((id) => {
        const measurement = measurements[id].measurement;
        const settings = measurements[id]?.settings;
        return {
            key: measurement.id,
            ...measurement,
            settings,
        };
    });

    const { k, d, n } = useMeasurementImportParams();

    const { columns, columnsWidth } = useMemo(() => {
        const renderActionCell = (value: string) => {
            return (
                <div className={styles.action_wrapper}>
                    <AnalyticsButton
                        type="link"
                        className={styles.action_icon}
                        onClick={() =>
                            window.open(getHTMLViewerMeasurementPath(productCode, { k, d, n, id: value }), '_blank')
                        }
                    >
                        <FormattedMessage id="App.View" />
                    </AnalyticsButton>

                    <ButtonExportHTML
                        type="link"
                        product={productCode}
                        mIDs={[value]}
                        analyticsPage={MeasurementPage.htmlViewer}
                    />
                </div>
            );
        };
        const { columns, columnsWidth } = getMeasurementColumns({
            product: productCode,
            fileType: 'measurement',
            renderActionCell,
            isHTMLView: true,
            preventFlagClick: false,
        });
        return { columns, columnsWidth };
    }, [d, k, n, productCode]);

    const paginationConfig: TablePaginationConfig = {
        pageSize,
        current: page,
        style: {
            display: 'none',
        },
    };

    return (
        <div className={styles.content_column_html_viewer}>
            <div className={styles.dataView_header}>
                <div className={styles.common_actions}>
                    {productCode && (
                        <ButtonExportHTML
                            product={productCode}
                            mIDs={selectedKeys}
                            analyticsPage={MeasurementPage.htmlViewer}
                        />
                    )}
                </div>
                <Pagination
                    size="small"
                    pageSize={pageSize}
                    current={page}
                    total={measurementIDs?.length ?? 0}
                    pageSizeOptions={['20', '50', '100']}
                    onChange={(page, pageSize) => {
                        setPage(page);
                        setPageSize(pageSize ?? DEFAULT_PAGE_SIZE);
                    }}
                    className={styles.dataView_pagination}
                    showSizeChanger
                />
            </div>
            <div className={styles.dataView_content_html_viewer}>
                <MeasurementTable
                    columns={columns}
                    columnsWidth={columnsWidth}
                    tableKey={productCode}
                    dataSource={dataSource}
                    loading={false}
                    rowSelection={{
                        selectedRowKeys: selectedKeys,
                        onChange: handleSelectKeys,
                    }}
                    pagination={paginationConfig}
                />
            </div>
        </div>
    );
};

export default HTMLViewerTable;
