import React from 'react';
import CreateRenameModal, { CreateRenameModalAction } from './FolderManagement/CreateRenameModal';
import { CustomCurveFileType } from '../../types/measurement';
import MoveModal from './FolderManagement/MoveModal';
import DeleteConfirmationModal, { DeletedType } from './FolderManagement/DeleteConfirmationModal';
import MeasurementCopyLink from './MeasurementCopyLink';
import { useMeasurementModalContext } from './MeasurementModals/MeasurementModalProvider';
import { useDataViewContext } from './DataViewProvider';

const MeasurementModals: React.FunctionComponent = () => {
    const { fileType, product } = useDataViewContext();
    const {
        measurementID,
        setMeasurementID,
        measurementName,
        setMeasurementName,
        showRenameMeasurement,
        setShowRenameMeasurement,
        showCreateFolder,
        setShowCreateFolder,
        showMoveMeasurement,
        setShowMoveMeasurement,
        showDeleteConfirmationModal,
        setShowDeleteConfirmationModal,
        showShareMeasurement,
        setShowShareMeasurement,
    } = useMeasurementModalContext();

    const resetMeasurementDetails = () => {
        setMeasurementID(undefined);
        setMeasurementName(undefined);
    };

    return (
        <>
            <CreateRenameModal
                action={CreateRenameModalAction.newFolder}
                visible={showCreateFolder}
                onCancel={() => {
                    setShowCreateFolder(false);
                }}
            />
            {measurementID && product && (
                <>
                    <CreateRenameModal
                        action={CreateRenameModalAction.renameMeasurement}
                        visible={showRenameMeasurement}
                        onCancel={() => {
                            setShowRenameMeasurement(false);
                            resetMeasurementDetails();
                        }}
                        currentName={measurementName}
                        id={measurementID}
                        fileType={fileType}
                        asciiPrintableName={fileType === CustomCurveFileType.dgscc}
                    />
                    <MoveModal
                        visible={showMoveMeasurement}
                        onCancel={() => {
                            setShowMoveMeasurement(false);
                            resetMeasurementDetails();
                        }}
                        addNewFolder={() => setShowCreateFolder(true)}
                        measurementIDs={[measurementID]}
                    />
                    <DeleteConfirmationModal
                        visible={showDeleteConfirmationModal}
                        onCancel={() => {
                            setShowDeleteConfirmationModal(false);
                            resetMeasurementDetails();
                        }}
                        deletedType={DeletedType.file}
                        ids={[measurementID]}
                        name={measurementName}
                    />
                    <MeasurementCopyLink
                        visible={showShareMeasurement}
                        onCancel={() => {
                            setShowShareMeasurement(false);
                            resetMeasurementDetails();
                        }}
                        mIDs={[measurementID]}
                        product={product!}
                    />
                </>
            )}
        </>
    );
};

export default MeasurementModals;
