import React from 'react';
import { isFinite } from 'lodash';
import { MeasurementFullData } from '../../../../../types/measurement';
import { normalizer } from '../../../../../utils/genericNormalizer';
import getSequenceNumber from './getSequenceNumberUtil';
import { ScanType } from '../../../../../types/proceq';
import ConvertedUnits from '../../shared/ConvertedUnits';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { FormatIDs } from '../../../../../types';
import { convertAndDisplay } from '../../utils/conversionRates';

export interface LineScanDetails {
    [key: string]: { reading: any; lineScanStatus: any; sequenceNo: string; lineContents: any };
}

export const getLineScanDetails = (data: MeasurementFullData, scanType: ScanType) => {
    const { lineScanStatus, areaScanParameters } = data.settings[0].content.areaScanStatus || {};
    const startDirection = areaScanParameters?.startDirection;
    const { entities } = normalizer(lineScanStatus, 'aScanID');
    const lineContents = data.measurement.content.lineScanContent?.lineContents ?? [];

    const reduceFunction = (acc: LineScanDetails, lineScan: { aScanID: any }) => {
        const { aScanID } = lineScan;
        const reading = data.readings.find((r) => r.content.aScanID === aScanID);
        const lineContentIndex = lineContents.findIndex((lineContent: any) => lineContent === aScanID);
        const sequenceNo = getSequenceNumber(
            scanType,
            startDirection,
            reading?.content.scanPosition,
            areaScanParameters
        );
        return {
            ...acc,
            [aScanID]: {
                reading,
                sequenceNo,
                lineScanStatus: entities[aScanID],
                lineContents:
                    lineContentIndex >= 0 && lineContents[lineContentIndex + 1]
                        ? lineContents[lineContentIndex + 1]
                        : {},
            },
        };
    };
    return lineScanStatus.reduce(reduceFunction, {});
};

interface ColumnConfig {
    title: FormatIDs;
    dataIndex: string;
    unitId?: string;
}

interface GetRebarParams {
    lineContents: any;
    lineScanStatus: any;
    isMetric: boolean;
    scanType: ScanType;
    convert: (value: string | number, unitId: string) => string | number;
}

export const getRebarDetails = (getRebarParams: GetRebarParams) => {
    const { lineContents, isMetric, scanType, convert, lineScanStatus } = getRebarParams;
    const columnsRaw: ColumnConfig[] = [
        {
            title: 'App.HTML.Profometer.ScanDetails.Distance',
            dataIndex: 'distance',
            unitId: 'Profometer.ScanDetails.Distance',
        },
        {
            title: 'App.HTML.Profometer.ScanDetails.Cover',
            dataIndex: 'cover',
            unitId: 'Profometer.ScanDetails.Cover',
        },
        {
            title: 'App.HTML.Profometer.ScanDetails.MeasuredDiameter',
            dataIndex: 'diameter',
            unitId: 'Profometer.ScanDetails.Diameter',
        },
        {
            title: 'App.HTML.Profometer.ScanDetails.ChangedDiameter',
            dataIndex: 'changedDiameter',
            unitId: 'Profometer.ScanDetails.Diameter',
        },
    ];
    const columns = columnsRaw.map((columnConfig) => ({
        render: (text: string) => text,
        ...columnConfig,
        title: columnConfig.unitId ? (
            <ConvertedUnits
                id={columnConfig.title}
                unitId={columnConfig.unitId}
                scanType={scanType}
                isMetric={isMetric}
            />
        ) : (
            <FormattedMessage id={columnConfig.title} />
        ),
    }));
    const rebarDiameters: {
        position: number;
        diameterDisplay: string;
        isDiameterValueEnabled?: boolean;
        adjustedDiameterDisplay?: string;
    }[] = lineContents.rebarDiameters;
    const { entities: rebarDiameterByPosition } = normalizer(rebarDiameters, 'position');
    const dataSource = lineContents.rebarCovers?.map((rebarCover: { position: any; coverDisplay: any }) => {
        const rebarCoverPosition = rebarCover.position;
        // value is alr saved in expected units, only need to handle rounding
        const cover = isFinite(Number(rebarCover.coverDisplay))
            ? convertAndDisplay('Profometer.ScanDetails.Cover', scanType, isMetric).roundNumber(
                  +rebarCover.coverDisplay
              )
            : '';
        const distance = rebarCoverPosition
            ? convert(rebarCoverPosition * lineScanStatus.scanResolution, 'Profometer.ScanDetails.Distance')
            : '';
        const diameter =
            rebarCoverPosition &&
            rebarDiameterByPosition[rebarCoverPosition] &&
            rebarDiameterByPosition[rebarCoverPosition].isDiameterValueEnabled
                ? rebarDiameterByPosition[rebarCoverPosition].diameterDisplay
                : '';
        const changedDiameter =
            rebarCoverPosition && rebarDiameterByPosition[rebarCoverPosition]
                ? rebarDiameterByPosition[rebarCoverPosition].adjustedDiameterDisplay
                : '';
        return {
            key: distance,
            distance,
            cover,
            diameter,
            changedDiameter,
        };
    });
    return { columns, dataSource };
};
