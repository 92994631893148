import React, { useCallback, useState } from 'react';
import { message } from 'antd';
import { useIntl } from 'react-intl';
import AnalyticsButton from '../AnalyticsComponents/Button';
import styles from './ButtonShare.styl';
import { ReactComponent as IconArchive } from '../../images/iconArchive.svg';
import { ReactComponent as IconRestore } from '../../images/iconRestore.svg';
import FormattedMessage from '../../localization/FormatMessage';
import { ProductCode } from '../../types/proceq';
import { useMeasurements, useProductData } from '../../hooks/useProductData';
import { archiveRestoreMeasurements } from '../../api/measurementService';
import { getMeasurementFolders } from '../../api/folderService';
import { useDataViewContext } from './DataViewProvider';
import analytics from '../../analytics/firebaseAnalytics';
import { FileManagementCategory, MeasurementMenuCategory } from '../../analytics/analyticsConstants';
import { useSelectedContractFeatures } from '../../hooks/useContract';
import InvalidShareExportModal, { InvalidShareExportType } from './DataViewModal/InvalidShareExportModal';
import { FormatIDs } from '../../types';
import Tooltip from '../shared/Tooltip';

interface ArchiveRestoreInfo {
    buttonText: FormatIDs;
    buttonIcon: React.ReactNode;
    multipleSuccessMessage: FormatIDs;
    singleSuccessMessage?: FormatIDs;
    errorMessage: FormatIDs;
    logBulkAction: (product: ProductCode) => void;
    logNonEditable: (product: ProductCode) => void;
    logFileManagementExclude: (product: ProductCode) => void;
    logCancel: (product: ProductCode) => void;
}

const ARCHIVE_RESTORE_INFO: Record<string, ArchiveRestoreInfo> = {
    archiveViewType: {
        buttonText: 'DataView.Measurement.Restore',
        buttonIcon: <IconRestore />,
        multipleSuccessMessage: 'DataView.Measurement.Restore.MultipleSuccess',
        singleSuccessMessage: 'DataView.Measurement.Restore.Success',
        errorMessage: 'DataView.Restore.Failure',
        logBulkAction: (product) => analytics.logFileManagement(FileManagementCategory.restoreBulk, product),
        logNonEditable: (product) => analytics.logNonEditableMeasurement(MeasurementMenuCategory.restore, product),
        logFileManagementExclude: (product) =>
            analytics.logFileManagement(FileManagementCategory.excludeAndRestore, product),
        logCancel: (product) => analytics.logFileManagement(FileManagementCategory.restoreCancel, product),
    },
    activeViewType: {
        buttonText: 'App.Archive',
        buttonIcon: <IconArchive height={12} width={12} />,
        multipleSuccessMessage: 'DataView.Measurement.Archive.Success',
        errorMessage: 'DataView.Archive.Failure',
        logBulkAction: (product) => analytics.logFileManagement(FileManagementCategory.archiveBulk, product),
        logNonEditable: (product) => analytics.logNonEditableMeasurement(MeasurementMenuCategory.archive, product),
        logFileManagementExclude: (product) =>
            analytics.logFileManagement(FileManagementCategory.excludeAndArchive, product),
        logCancel: (product) => analytics.logFileManagement(FileManagementCategory.archiveCancel, product),
    },
};

interface ButtonArchiveRestoreProps {
    product: ProductCode;
    mIDs: string[];
    setSelectedKeys: (keys: string[]) => void;
    isArchiveViewType?: boolean;
    isTableButton?: boolean; // true if button is within the table
}

const ButtonArchiveRestore: React.FunctionComponent<ButtonArchiveRestoreProps> = (props) => {
    const { product, mIDs, setSelectedKeys, isArchiveViewType = true, isTableButton = false } = props;
    const measurements = useMeasurements();
    const formatMessage = useIntl().formatMessage;
    const { fetchMeasurementList, withUnsynced } = useDataViewContext();
    const { getInvalidMeasurementIDs } = useSelectedContractFeatures(measurements, mIDs);
    const invalidMeasurementIDs = getInvalidMeasurementIDs();
    const [invalidModal, setInvalidModal] = useState(false);
    const { userFolderIDs, folders } = useProductData(product);
    const { name: firstFileName, fID } = measurements[mIDs[0]].measurement;
    const componentInfo = isArchiveViewType
        ? ARCHIVE_RESTORE_INFO.archiveViewType
        : ARCHIVE_RESTORE_INFO.activeViewType;

    const showSuccessMessage = useCallback(
        (count: number) => {
            if (isArchiveViewType) {
                if (count > 1) {
                    message.success(formatMessage({ id: componentInfo.multipleSuccessMessage }, { count }));
                } else {
                    message.success(
                        formatMessage(
                            { id: componentInfo.singleSuccessMessage },
                            {
                                name: firstFileName,
                                folder:
                                    userFolderIDs?.includes(fID) && folders?.[fID]?.name
                                        ? folders[fID].name
                                        : formatMessage({ id: 'DataView.Folder.All' }),
                            }
                        )
                    );
                }
            } else {
                message.success(
                    formatMessage({ id: componentInfo.multipleSuccessMessage }, { name: firstFileName, count })
                );
            }
        },
        [
            componentInfo.multipleSuccessMessage,
            componentInfo.singleSuccessMessage,
            fID,
            firstFileName,
            folders,
            formatMessage,
            isArchiveViewType,
            userFolderIDs,
        ]
    );

    const archiveRestoreCallback = useCallback(
        async (ids: string[]) => {
            await archiveRestoreMeasurements({
                ids,
                product,
                isArchive: !isArchiveViewType,
                errorCallback: () =>
                    message.error(formatMessage({ id: componentInfo.errorMessage }, { count: ids.length })),
            });
            showSuccessMessage(ids.length);
            fetchMeasurementList(true);
            getMeasurementFolders({ product, withUnsynced, archived: isArchiveViewType });
            setSelectedKeys([]);
            componentInfo.logBulkAction(product);
        },
        [
            product,
            isArchiveViewType,
            fetchMeasurementList,
            withUnsynced,
            setSelectedKeys,
            componentInfo,
            formatMessage,
            showSuccessMessage,
        ]
    );

    const handleOpenModal = useCallback(() => {
        if (invalidMeasurementIDs.length) {
            componentInfo.logNonEditable(product);
            setInvalidModal(true);
        } else {
            archiveRestoreCallback(mIDs);
        }
    }, [archiveRestoreCallback, componentInfo, invalidMeasurementIDs.length, mIDs, product]);

    const handleExcludeAndArchive = useCallback(() => {
        const filteredIDs = mIDs.filter((id) => !invalidMeasurementIDs.includes(id));
        setSelectedKeys(filteredIDs);
        setInvalidModal(false);
        archiveRestoreCallback(filteredIDs);
        componentInfo.logFileManagementExclude(product);
    }, [archiveRestoreCallback, componentInfo, invalidMeasurementIDs, mIDs, product, setSelectedKeys]);

    const text = <FormattedMessage id={componentInfo.buttonText} />;

    return (
        <>
            <AnalyticsButton
                onClick={handleOpenModal}
                type={isTableButton ? 'link' : isArchiveViewType ? 'primary' : 'default'}
                className={styles.button}
                style={isTableButton ? { padding: 0 } : {}}
            >
                <Tooltip title={isTableButton ? text : undefined}>
                    <div className={isTableButton ? styles.delete_icon_only : styles.delete_icon}>
                        {componentInfo.buttonIcon}
                    </div>
                </Tooltip>
                {!isTableButton && text}
            </AnalyticsButton>
            <InvalidShareExportModal
                visible={invalidModal}
                onClose={() => {
                    setInvalidModal(false);
                    componentInfo.logCancel(product);
                }}
                invalidType={isArchiveViewType ? InvalidShareExportType.restore : InvalidShareExportType.archive}
                invalidMeasurementIDs={invalidMeasurementIDs}
                onExclude={handleExcludeAndArchive}
                selectedKeysCount={mIDs.length}
            />
        </>
    );
};

export default ButtonArchiveRestore;
