import React, { useCallback, useState } from 'react';
import { Button, message } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import StyledModal from '../../shared/StyledModal';
import FormattedMessage from '../../../localization/FormatMessage';
import styles from '../DataView.styl';
import {
    deleteArchivedFolders,
    DeleteFolderResponse,
    deleteFolders,
    getMeasurementFolders,
} from '../../../api/folderService';
import { useDataViewContext } from '../DataViewProvider';
import analytics from '../../../analytics/firebaseAnalytics';
import { FileManagementCategory, FolderManagementCategory } from '../../../analytics/analyticsConstants';
import { FormatIDs } from '../../../types';
import { useMeasurements, useProductFolders } from '../../../hooks/useProductData';
import MultipleFailureMessage from '../../shared/MultipleFailureMessage';
import { BluetoothFileType, Folder, ViewType } from '../../../types/measurement';
import { deleteCustomMaterial, permanentDeleteMeasurements } from '../../../api/measurementService';
import { ProductCode } from '../../../types/proceq';
import { useFormatMessage } from '../../../localization/useFormatMessage';
import { ReactQueryKeys } from '../../../queries/queryKeys';

export enum DeletedType {
    folder,
    file,
    archivedFolder,
}

const DELETED_TYPE_TEXT_MAP: Record<
    DeletedType,
    {
        title: FormatIDs;
        body: FormatIDs;
        deleteButton: FormatIDs;
        logDeleteEvent: (product: string) => void;
        logCancelEvent: (product: string) => void;
    }
> = {
    [DeletedType.folder]: {
        title: 'DataView.Folder.DeleteFolder',
        body: 'DataView.Folder.DeleteFolder.Info',
        deleteButton: 'App.Delete',
        logCancelEvent: (product) => analytics.logFolderManagement(FolderManagementCategory.deleteCancel, product),
        logDeleteEvent: (product) => analytics.logFolderManagement(FolderManagementCategory.delete, product),
    },
    [DeletedType.file]: {
        title: 'DataView.Measurement.DeleteMeasurement',
        body: 'DataView.Measurement.DeleteMeasurement.Info',
        deleteButton: 'DataView.Measurement.Delete.Permanently',
        logCancelEvent: (product) => analytics.logFileManagement(FileManagementCategory.permanentDeleteCancel, product),
        logDeleteEvent: (product) => analytics.logFileManagement(FileManagementCategory.permanentDelete, product),
    },
    [DeletedType.archivedFolder]: {
        title: 'DataView.Folder.Archived.DeleteFolder',
        body: 'DataView.Folder.Archived.DeleteFolder.Body',
        deleteButton: 'DataView.Measurement.Delete.Permanently',
        logCancelEvent: (product) =>
            analytics.logFolderManagement(FolderManagementCategory.archivedDeletePermanentlyCancel, product),
        logDeleteEvent: (product) =>
            analytics.logFolderManagement(FolderManagementCategory.archivedDeletePermanently, product),
    },
};

interface DeleteModalProps {
    visible: boolean;
    onCancel: () => void;
    deletedType: DeletedType;
    ids: string[];
    onDelete?: () => void;
    name?: string;
}

const handleFolderDeletionFailures = (response: DeleteFolderResponse, folders: { [p: string]: Folder } | undefined) => {
    if (response.failed.length > 0) {
        const failureReasons = response.failed.map((failure) => {
            return {
                ...failure,
                name: folders && folders[failure.id] ? folders[failure.id].name : failure.id,
            };
        });
        message.error(<MultipleFailureMessage title="DataView.Delete.Failure" failures={failureReasons} />);
    }
};

const DeleteConfirmationModal: React.FunctionComponent<DeleteModalProps> = (props) => {
    const { visible, onCancel, deletedType, ids, onDelete, name } = props;
    const {
        product,
        withUnsynced,
        fileType,
        fetchMeasurementList,
        fetchCustomMaterialList,
        viewType,
        setSelectedKeys,
    } = useDataViewContext();
    const [isLoading, setIsLoading] = useState(false);
    const { folders, archivedFolders } = useProductFolders(product);
    const measurements = useMeasurements();
    const isArchiveViewType = viewType === ViewType.Archived;
    const formatMessage = useFormatMessage();
    const queryClient = useQueryClient();

    const handleReset = useCallback(() => {
        onCancel();
        setIsLoading(false);
    }, [onCancel]);

    const deleteFiles = useCallback(
        async (product: ProductCode) => {
            const isCustomMaterial = fileType === BluetoothFileType.CustomMaterial;
            const response = isCustomMaterial
                ? await deleteCustomMaterial({ ids, product })
                : await permanentDeleteMeasurements({
                      ids,
                      product,
                  });
            if (response.failed.length > 0) {
                const failureReasons = response.failed.map((failure) => {
                    return {
                        ...failure,
                        name:
                            measurements && measurements[failure.id]
                                ? measurements[failure.id].measurement.name
                                : failure.id,
                    };
                });
                message.error(<MultipleFailureMessage title="DataView.Delete.Failure" failures={failureReasons} />);
            }
            handleReset();
            if (isCustomMaterial) {
                await Promise.allSettled([
                    fetchCustomMaterialList(true),
                    queryClient.invalidateQueries({
                        queryKey: ReactQueryKeys.customMaterialCount(product!, withUnsynced),
                    }),
                ]);
            } else if (fileType === BluetoothFileType.VerificationData) {
                await Promise.allSettled([
                    fetchMeasurementList(true),
                    queryClient.invalidateQueries({
                        queryKey: ReactQueryKeys.verificationDataCount(product!, withUnsynced),
                    }),
                ]);
            } else {
                await Promise.allSettled([
                    fetchMeasurementList(true),
                    getMeasurementFolders({ product, withUnsynced, archived: isArchiveViewType }),
                ]);
            }
            setSelectedKeys([]);
        },
        [
            queryClient,
            fetchCustomMaterialList,
            fetchMeasurementList,
            fileType,
            handleReset,
            ids,
            isArchiveViewType,
            measurements,
            withUnsynced,
            setSelectedKeys,
        ]
    );

    if (ids.length === 0) return null;

    const handleDelete = async (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsLoading(true);
        if (product) {
            if (deletedType === DeletedType.folder) {
                deleteFolders(ids).then((response) => {
                    getMeasurementFolders({ product, withUnsynced, archived: isArchiveViewType });
                    onDelete?.();
                    handleFolderDeletionFailures(response, folders);
                });
                handleReset();
            } else if (deletedType === DeletedType.archivedFolder) {
                deleteArchivedFolders(ids).then((response) => {
                    if (response.passed.length > 0) {
                        message.success(
                            formatMessage(
                                { id: 'DataView.Folder.Archived.DeleteFolder.Success' },
                                { name: archivedFolders?.[ids[0]]?.name ?? '' }
                            )
                        );
                        getMeasurementFolders({ product, withUnsynced, archived: isArchiveViewType });
                        onDelete?.();
                    }
                    handleFolderDeletionFailures(response, folders);
                });
                handleReset();
            } else {
                await deleteFiles(product);
            }
            DELETED_TYPE_TEXT_MAP[deletedType].logDeleteEvent(product);
        }
    };

    const handleClose = (e: React.MouseEvent) => {
        e.stopPropagation();
        onCancel();
        if (product) {
            DELETED_TYPE_TEXT_MAP[deletedType].logDeleteEvent(product);
        }
    };

    return (
        <StyledModal
            className={styles.delete_folder_modal}
            title={<FormattedMessage id={DELETED_TYPE_TEXT_MAP[deletedType].title} values={{ count: ids.length }} />}
            open={visible}
            onCancel={handleClose}
            footer={[
                <React.Fragment key="folderModalFooter">
                    <Button type="primary" ghost onClick={handleClose}>
                        <FormattedMessage id="App.Cancel" />
                    </Button>
                    <Button className={styles.create_button} type="primary" onClick={handleDelete} loading={isLoading}>
                        <FormattedMessage id={DELETED_TYPE_TEXT_MAP[deletedType].deleteButton} />
                    </Button>
                </React.Fragment>,
            ]}
        >
            <div className={styles.delete_folder_info}>
                <FormattedMessage id={DELETED_TYPE_TEXT_MAP[deletedType].body} values={{ name, count: ids.length }} />
            </div>
        </StyledModal>
    );
};

export default DeleteConfirmationModal;
