import React from 'react';
import styles from '../DataView.styl';
import { ReactComponent as IconFlagFilled } from '../../../images/iconFlagFilled.svg';
import { ReactComponent as IconFlagLinear } from '../../../images/iconFlagLinear.svg';
import {
    flagCustomMaterial,
    flagMeasurement,
    getCustomMaterialFullData,
    getMeasurementFullData,
} from '../../../api/measurementService';
import { CustomCurveFileType, MeasurementListItem, SystemFolderID } from '../../../types/measurement';
import { classNames } from '../../../utils/styleUtils';
import { CustomMaterialItem } from '../../../types/customMaterial';
import analytics from '../../../analytics/firebaseAnalytics';
import { FileManagementCategory } from '../../../analytics/analyticsConstants';
import { useDataViewContext } from '../DataViewProvider';
import { getMeasurementFolders } from '../../../api/folderService';
import { useMeasurement } from '../../../hooks/useProductData';
import { useSelectedContractFeatures } from '../../../hooks/useContract';
import { NonEditableMeasurements } from '../DataViewContext';
import Tooltip from '../../shared/Tooltip';
import { useFormatMessage } from '../../../localization/useFormatMessage';

export enum FlagColumnType {
    measurement,
    customMaterial,
}

interface FlagColumnPropsBasic {
    allowClick?: boolean;
}

interface MeasurementFlagColumnProps extends FlagColumnPropsBasic {
    measurement: MeasurementListItem;
    flagColumnType: FlagColumnType.measurement;
    customMaterialItem?: never;
}

interface CustomMaterialFlagColumnProps extends FlagColumnPropsBasic {
    customMaterialItem: CustomMaterialItem;
    flagColumnType: FlagColumnType.customMaterial;
    measurement?: never;
}

const FlagColumn: React.FunctionComponent<MeasurementFlagColumnProps | CustomMaterialFlagColumnProps> = (props) => {
    const { measurement, allowClick, customMaterialItem, flagColumnType } = props;
    const { flagged, id = '' } = measurement ?? customMaterialItem ?? {};
    const { product, withUnsynced, activeFolder, fetchMeasurementList, setNonEditableMeasurements } =
        useDataViewContext();
    const formatMessage = useFormatMessage();

    const measurementTest = useMeasurement(id);
    const { getInvalidMeasurementIDs, demoMeasurementIDs } = useSelectedContractFeatures({ [id]: measurementTest }, [
        id,
    ]);
    // note that verification data is considered a measurement and needs to be prevented too
    const nonEditableMeasurements: NonEditableMeasurements | undefined =
        flagColumnType === FlagColumnType.measurement &&
        !demoMeasurementIDs.includes(id) &&
        getInvalidMeasurementIDs().includes(id)
            ? { allInvalid: true, measurementIds: [id] }
            : undefined;

    const handleFlagClick = async (e: React.MouseEvent) => {
        e.stopPropagation();
        if (allowClick) {
            if (flagColumnType === FlagColumnType.measurement) {
                if (measurement?.type !== CustomCurveFileType.dgscc && nonEditableMeasurements) {
                    setNonEditableMeasurements(nonEditableMeasurements);
                    analytics.logNonEditableMeasurement(FileManagementCategory.flag, product ?? '');
                } else {
                    await flagMeasurement({ id, flagStatus: !flagged });
                    if (product) {
                        getMeasurementFolders({ product, withUnsynced });
                    }
                    if (activeFolder === SystemFolderID.Flagged) {
                        fetchMeasurementList(true);
                    } else {
                        getMeasurementFullData({ measurementID: measurement!.id });
                    }
                }
            } else {
                await flagCustomMaterial({ id, flagStatus: !flagged });
                getCustomMaterialFullData({ id });
            }
            analytics.logFileManagement(FileManagementCategory.flag, product ?? '');
        }
    };

    const flagClassName = classNames(styles.title_flag, allowClick && styles.title_flag_clickable);
    const IconFlag = flagged ? IconFlagFilled : IconFlagLinear;

    return (
        <div className={styles.title_wrapper}>
            <Tooltip
                title={allowClick ? formatMessage({ id: flagged ? 'DataView.Unflag' : 'DataView.Flag' }) : undefined}
            >
                <IconFlag className={flagClassName} onClick={handleFlagClick} />
            </Tooltip>
        </div>
    );
};

export default FlagColumn;
