import React from 'react';
import { Table } from 'antd';
import styles from '../DataViewers.styl';
import { MeasurementFullData } from '../../../../types/measurement';
import { DerivedProps } from '../SharedTypes';
import { getLabelFromGlobalDataKey, getTextValue } from '../../RegisteredInfo/helper';
import FormattedMessage from '../../../../localization/FormatMessage';
import { FormatIDs } from '../../../../types';
import { tableLocaleSetting } from '../../../shared/MyEmpty';
import { gprSoilProduct, ProductCode } from '../../../../types/proceq';
import { EquotipProbeInfo, GPRModule } from '../../../../types/probe';
import useLogEventsSeparator from './useLogEventsSeparator';

export const title = <FormattedMessage id="Proceq.UserProbeInfo" />;

export const useUserProperties = (
    data: MeasurementFullData | undefined,
    product: ProductCode,
    isFullList?: boolean
) => {
    const { probeLog } = useLogEventsSeparator(data?.logs || []);
    if (!data) return [];
    const productModel = data.measurement.productModel;
    const showProduct = getTextValue(['productmodel'])
        .map(({ value }: { value: string }) => value)
        .includes(productModel.toUpperCase());

    const isSoilExternalGPS = product === ProductCode.GPR_SOIL && !!data.measurement.content.gnssInfo?.thirdPartyDevice;
    const soilMAProbe = product === ProductCode.GPR_SOIL ? probeLog?.content.ma8000SerialNumber : undefined;
    const measurementContentInfo = data.measurement.content?.info;
    const gprModules: GPRModule[] | undefined = data.logs?.find((log) => log.type === 'probe')?.content?.modules;

    const properties: { label: FormatIDs; [key: string]: any; enable?: boolean }[] = [
        {
            label: 'Proceq.LogbookPropertyOperator',
            value: measurementContentInfo?.author ?? '',
        },
        {
            label: 'Proceq.FormEmailGroupID',
            value: measurementContentInfo?.email ?? '',
        },
        {
            label: 'Product',
            value: (
                <b>
                    {getLabelFromGlobalDataKey('productmodel', productModel?.toUpperCase())}
                    {(product === ProductCode.GPR_SOIL || product === ProductCode.GPR_MOUNTED) &&
                        gprModules &&
                        gprModules.map((module, index) => <div key={index}>{module.name}</div>)}
                    {soilMAProbe && (
                        <div>
                            {isSoilExternalGPS ? (
                                <FormattedMessage id="App.HTML.GPR_SOIL.Product.ExternalPositioningDevice" />
                            ) : (
                                gprSoilProduct
                            )}
                        </div>
                    )}
                </b>
            ),
            enable:
                showProduct &&
                (product === ProductCode.GPR ||
                    product === ProductCode.GPR_SOIL ||
                    product === ProductCode.GPR_MOUNTED ||
                    product === ProductCode.PROFOMETER ||
                    !!isFullList),
        },
        {
            label: data.productFamily?.toUpperCase() === 'FDL' ? 'Base Unit S/N' : 'Probe S/N',
            value:
                product === ProductCode.PIT_IE || product === ProductCode.PROFOMETER ? (
                    data.measurement.probeinfo.serialNumber
                ) : (
                    <>
                        <div>{measurementContentInfo?.probeSerialNumber ?? ''}</div>
                        {(product === ProductCode.GPR_SOIL || product === ProductCode.GPR_MOUNTED) &&
                            gprModules &&
                            gprModules.map((module, index) => <div key={index}>{module.serialNumber}</div>)}
                        {soilMAProbe && !isSoilExternalGPS && <div>{soilMAProbe}</div>}
                    </>
                ),
        },
        {
            label: 'Hardware Version',
            value:
                product === ProductCode.PIT_IE || product === ProductCode.PROFOMETER
                    ? data.measurement.probeinfo.hardwareRevision
                    : measurementContentInfo?.hardwareVersion ?? '',
            enable: !!isFullList,
        },
        {
            label: 'Firmware Version',
            value:
                product === ProductCode.PIT_IE || product === ProductCode.PROFOMETER
                    ? (data.measurement.probeinfo as EquotipProbeInfo).firmwareVersion
                    : measurementContentInfo?.firmwareVersion ?? '',
            enable: !!isFullList,
        },
        {
            label: 'Proceq.LogbookPropertyBTFirmwareVersion',
            value: (data.measurement.probeinfo as EquotipProbeInfo).btFirmwareVersion,
            enable: !!isFullList && product === ProductCode.PROFOMETER,
        },
        {
            label: 'Proceq.LogbookPropertyBTSerialNumber',
            value: (data.measurement.probeinfo as EquotipProbeInfo).btSerialNumber,
            enable: !!isFullList && product === ProductCode.PROFOMETER,
        },
        {
            label: 'App Version',
            value: measurementContentInfo?.version ?? '',
            enable: !!isFullList,
        },
        {
            label: 'Contract ID',
            value:
                product === ProductCode.PIT_IE || product === ProductCode.PROFOMETER
                    ? data.measurement.contractID
                    : measurementContentInfo?.contractID ?? '',
            enable: !!isFullList && product !== ProductCode.GPR_SOIL,
        },
        {
            label: 'MAC Address',
            value: measurementContentInfo?.macAddress ?? '',
            enable:
                !!isFullList &&
                product !== ProductCode.PIT_IE &&
                product !== ProductCode.GPR_SOIL &&
                product !== ProductCode.PROFOMETER,
        },
        {
            label: 'Calibration Date',
            value: measurementContentInfo?.calibrationDate ?? '',
            enable: data.productFamily?.toUpperCase() === 'FDL',
        },
    ];

    return properties
        .filter((item) => item.enable === undefined || item.enable, [])
        .map((row) => ({
            key: row.label,
            label: <FormattedMessage id={row.label} />,
            value: row.value,
        }));
};

export const User = ({
    data,
    product,
    showTitle,
}: { data: MeasurementFullData } & Pick<DerivedProps, 'product' | 'showTitle'>) => {
    const user = useUserProperties(data, product, true);

    return (
        <Table
            title={showTitle ? ((() => <span className={styles.main_header}>{title}</span>) as any) : null}
            className={styles.table}
            dataSource={user}
            pagination={false}
            showHeader={false}
            size="small"
            locale={tableLocaleSetting}
        >
            <Table.Column dataIndex="label" width={230} />
            <Table.Column dataIndex="value" />
        </Table>
    );
};

export default User;
