import { produce } from 'immer';
import { AnyAction, Reducer } from 'redux';

import { NotificationRecord } from '../types/notification';
import { SyncMeta } from '../types/api';
import actionTypes, { PayloadAction } from '../actions/actionTypes';
import { NotificationPayload, ReceiveNotificationPayload } from '../actions/notification';
import { normalizer } from '../utils/genericNormalizer';

export interface NotificationState {
    notificationIDs: string[];
    notifications: {
        [key: string]: NotificationRecord;
    };
    syncIDX?: number;
    meta?: SyncMeta;
    unreadCount: number;
}

export const initialState: NotificationState = {
    notificationIDs: [],
    notifications: {},
    unreadCount: 0,
};

export const makeNotificationReducer = (
    myInitialState: NotificationState = initialState
): Reducer<NotificationState, AnyAction> =>
    produce((draft = myInitialState, action) => {
        switch (action.type) {
            case actionTypes.RECEIVED_NOTIFICATIONS: {
                const { payload } = action as PayloadAction<ReceiveNotificationPayload>;
                const { notifications, meta, aggregation, sync } = payload;
                const { entityIds, entities } = normalizer(notifications);

                if (sync) {
                    // If value is from update, do not add to the list again
                    const filteredIDs = entityIds.filter((id) => !draft.notifications[id]);
                    filteredIDs.reverse();
                    draft.syncIDX = meta.idx;
                    draft.notificationIDs.unshift(...filteredIDs);
                } else {
                    draft.notificationIDs.push(...entityIds);
                    draft.meta = meta;
                    // If syncIDX is undefined, we will setup with the updated value of the top most notification
                    if (draft.syncIDX === undefined) {
                        draft.syncIDX = entities[entityIds[0]]?.updated ?? 0;
                    }
                }

                for (const id of entityIds) {
                    draft.notifications[id] = entities[id];
                }
                draft.unreadCount = aggregation.unreadGroupCounts.total;

                return draft;
            }

            case actionTypes.READ_NOTIFICATION: {
                const { payload } = action as PayloadAction<NotificationPayload>;
                const { notifications, aggregation } = payload;

                for (const notification of notifications) {
                    draft.notifications[notification.id] = notification;
                }
                draft.unreadCount = aggregation.unreadGroupCounts.total;

                return draft;
            }

            case actionTypes.READ_ALL_NOTIFICATIONS: {
                const { notificationIDs } = draft;
                for (const id of notificationIDs) {
                    if (draft.notifications[id]) {
                        draft.notifications[id].read = true;
                    }
                }
                draft.unreadCount = 0;
                return draft;
            }

            case actionTypes.UPDATE_ALL_NOTIFICATIONS: {
                const { payload } = action as PayloadAction<ReceiveNotificationPayload>;
                const { notifications, meta, aggregation } = payload;
                const { entityIds, entities } = normalizer(notifications);
                draft.notificationIDs = entityIds;
                draft.meta = meta;
                draft.syncIDX = entities[entityIds[0]]?.updated ?? 0;
                for (const id of entityIds) {
                    draft.notifications[id] = entities[id];
                }
                draft.unreadCount = aggregation.unreadGroupCounts.total;
                return draft;
            }

            case actionTypes.CLEAR_ALL_NOTIFICATIONS: {
                draft.notificationIDs = [];
                draft.notifications = {};
                draft.unreadCount = 0;
                return draft;
            }

            default: {
                return draft;
            }
        }
    }, myInitialState);

const notification = makeNotificationReducer();

export default notification;
