import React, { useCallback, useEffect, useState } from 'react';
import { routeUtils } from 'tds-common-fe';

import { message } from 'antd';
import Header from '../shared/Header';
import styles from './MeasurementImport.styl';
import ImportMessage from './ImportMessage';
import Spinner from '../shared/Spinner';
import FormattedMessage from '../../localization/FormatMessage';
import { FormatIDs } from '../../types';
import { iphoneSupportedProductFamilySet, ProductCode } from '../../types/proceq';
import { proceqURL } from '../../api/RegionalAPI';
import * as measurementService from '../../api/measurementService';
import { APIError } from '../../api/error';
import { getMeasurementImportPath, getProceqImportURL } from '../Routes/urls';
import * as mobileUtils from '../../utils/mobileUtils';
import { productAppStoreURL, SCREENING_EAGLE_APP_STORE_URL } from './constants';
import useMeasurementImportParams from './useMeasurementImportParams';
import { useFormatMessage } from '../../localization/useFormatMessage';
import { SHARED_LINK_CURVE_TYPE } from '../../types/measurement';

enum CheckingState {
    loading,
    invalidURL,
    expiredURL,
    downloadApp,
    timeout,
}

const stateData: {
    [key in CheckingState]?: {
        title: FormatIDs;
        message: FormatIDs;
        action: 'homepage' | 'app';
    };
} = {
    [CheckingState.invalidURL]: {
        title: 'Import.InvalidURL.Title',
        message: 'Import.InvalidURL.Message',
        action: 'homepage',
    },
    [CheckingState.expiredURL]: {
        title: 'Import.ExpiredURL.Title',
        message: 'Import.ExpiredURL.Message',
        action: 'homepage',
    },
    [CheckingState.downloadApp]: {
        title: 'Import.App.Title',
        message: 'Import.App.Message',
        action: 'app',
    },
    [CheckingState.timeout]: {
        title: 'Import.Timeout.Message',
        message: 'Import.Timeout.Title',
        action: 'homepage',
    },
};

const ImportRedirection: React.FunctionComponent = () => {
    const { k, d, t, n, source, appName, history, productCode, handleGotoHomepage } = useMeasurementImportParams();

    const [checkingState, setCheckingState] = useState<CheckingState>(
        k && d && n ? CheckingState.loading : CheckingState.invalidURL
    );

    const formatMessage = useFormatMessage();

    const handleViewInBrowser = useCallback(() => {
        const path = getMeasurementImportPath(productCode);
        const newPath = routeUtils.makeQueryPath(path, { k, d, n, t });
        history.replace(newPath);
    }, [productCode, k, d, n, t, history]);

    const handleDownloadApp = () => {
        const productURL = productAppStoreURL[productCode];
        window.open(productURL || SCREENING_EAGLE_APP_STORE_URL, '_blank');
    };

    useEffect(() => {
        if (source === 'workspace') {
            message.error(formatMessage({ id: 'Import.NoAppInstalledError' }, { appName }));
        }
    }, [appName, formatMessage, source]);

    const handleLaunchApp = () => {
        const appURLQuery = routeUtils.makeQueryPath(getProceqImportURL(productCode.toLowerCase()), {
            k,
            d,
            n,
            source: 'workspace',
        });
        window.open(appURLQuery, '_blank');
    };

    const shouldFetch = checkingState === CheckingState.loading;

    useEffect(() => {
        if (!k || !d || !shouldFetch) {
            return;
        }

        const checkShouldDownloadApp = (): boolean => {
            if (productCode === ProductCode.GPR_INSIGHTS || t === SHARED_LINK_CURVE_TYPE) return false;
            if (mobileUtils.isIPhone()) {
                return iphoneSupportedProductFamilySet.has(productCode);
            }
            return mobileUtils.isIPad();
        };

        // Initialise the regional URL
        proceqURL.init({ regionalDomain: d });

        // Fetch the data to see if URL is expired
        measurementService
            .checkSharedURLValidity(k)
            .then(() => {
                if (checkShouldDownloadApp()) {
                    // If the device is supported, we show message
                    setCheckingState(CheckingState.downloadApp);
                } else {
                    // Else we redirect the user directly to perform the actual import
                    handleViewInBrowser();
                }
            })
            .catch((error: APIError) => {
                // eslint-disable-next-line no-console
                console.error(error);
                // For expired URL, we should different message
                if (error.response?.data?.code === 'ExpiredURL') {
                    setCheckingState(CheckingState.expiredURL);
                } else if ((error.message ?? '').includes('timeout')) {
                    setCheckingState(CheckingState.timeout);
                } else {
                    setCheckingState(CheckingState.invalidURL);
                }
            });
    }, [k, d, handleViewInBrowser, productCode, shouldFetch, t]);

    const stateValue = stateData[checkingState];

    return (
        <div className={styles.container}>
            <Header hasLogo />
            <div className={styles.content_container}>
                {checkingState === CheckingState.loading && <Spinner />}
                {stateValue && (
                    <ImportMessage
                        title={<FormattedMessage id={stateValue.title} values={{ appName }} />}
                        message={<FormattedMessage id={stateValue.message} />}
                        primaryAction={
                            stateValue.action === 'app'
                                ? { title: 'Import.LaunchApp', action: handleLaunchApp }
                                : {
                                      title: 'Import.GotoHomepage',
                                      action: handleGotoHomepage,
                                      type: 'default',
                                      fillWidth: false,
                                  }
                        }
                        secondaryAction={
                            stateValue.action === 'app'
                                ? { title: 'Import.ViewInBrowser', action: handleViewInBrowser }
                                : undefined
                        }
                        footerAction={
                            stateValue.action === 'app'
                                ? {
                                      title: <FormattedMessage id="Import.FooterText" values={{ appName }} />,
                                      linkText: 'Import.DownloadApp',
                                      action: handleDownloadApp,
                                  }
                                : undefined
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default ImportRedirection;
