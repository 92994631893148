import React from 'react';
import Sidebar from '../../Sidebar';
import Header from '../Header';
import styles from './AppLayout.styl';
import colors from '../../../styles/colors.json';

interface AppLayoutProps {
    direction?: 'horizontal' | 'vertical';
}

const AppLayout: React.FunctionComponent<AppLayoutProps> = (props) => {
    const { children } = props;
    return (
        <div className={styles.root}>
            <Header hasLogo hasActions background={colors.gray200} />
            <div className={styles.body}>
                <Sidebar />
                {children}
            </div>
        </div>
    );
};

export default AppLayout;
