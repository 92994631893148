import React, { memo, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Spin } from 'antd';
import AppLayout from '../../shared/AppLayout';
import { useAppsStatus } from '../../../hooks/useConfig';
import styles from './ToolFrame.styl';
import { ProductCode } from '../../../types/proceq';
import { useProductContext } from '../ProductContextProvider';
import { productMap, ProductName } from '../../shared/AppIcon';
import FreeTrialModal, { LicenseActionState } from '../../License/FreeTrialModal';
import { getActiveApps } from '../../../api/appService';
import { getAppRoute } from '../../Routes/urls';

const ToolFrame: React.FunctionComponent = () => {
    const { appsStatus } = useAppsStatus();
    const history = useHistory();
    const params = useParams<{ productName: string }>();
    const productInfo = productMap[params.productName];
    const productCode = productInfo?.productCode ?? (params.productName as ProductCode);
    const productAppStatus = appsStatus[productCode];
    const [isActiveAppsUpdated, setIsActiveAppsUpdated] = useState(false);
    const [licenseAction, setLicenseAction] = useState<undefined | LicenseActionState>(undefined);
    const { setProduct } = useProductContext();

    useEffect(() => {
        getActiveApps().then(() => setIsActiveAppsUpdated(true));
    }, []);

    useEffect(() => {
        setProduct(productCode);
    }, [setProduct, productCode]);

    useEffect(() => {
        if (isActiveAppsUpdated && productAppStatus) {
            if (productAppStatus.active) {
                window.name = 'workspace';
                window.open(getAppRoute(ProductName.GPRInsights), 'insights');
                history.push('/');
            } else if (productAppStatus.eligibleFreeTrial) {
                setLicenseAction(LicenseActionState.activateFreeTrial);
            } else {
                setLicenseAction(LicenseActionState.upgrade);
            }
        }
    }, [history, isActiveAppsUpdated, productAppStatus]);

    return (
        <AppLayout>
            {!isActiveAppsUpdated && (
                <div className={styles.container_body}>
                    <Spin />
                </div>
            )}
            {isActiveAppsUpdated && licenseAction !== undefined && (
                <FreeTrialModal
                    productCode={productCode}
                    licenseActionState={licenseAction}
                    showFreeLicense={licenseAction !== LicenseActionState.upgrade}
                />
            )}
        </AppLayout>
    );
};

export default memo(ToolFrame);
