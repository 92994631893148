import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ProductCode } from '../../types/proceq';
import { DataViewContext, FilterConfig, NonEditableMeasurements, OrderConfig, ViewModeConfig } from './DataViewContext';
import { CustomCurveFileType, MeasurementListItem, SystemFolderID, ViewType } from '../../types/measurement';
import { clearMeasurementList } from '../../actions/measurement';
import { getMeasurementPath } from '../Routes/urls';
import { useProductContext } from './ProductContextProvider';
import { useCustomMaterialList, useMeasurementList, useMeasurementsPoll } from '../../queries/measurementQueries';
import getIsMeasurementUnsynced from './DataViewers/utils/getIsMeasurementUnsynced';

export const DEFAULT_PAGE_SIZE = 20;

const filterUnsyncedMeasurements = (data: MeasurementListItem[]) =>
    data.filter((measurement) => getIsMeasurementUnsynced(measurement)).map((measurement) => measurement.id);

export const DataViewProvider: React.FunctionComponent = (props) => {
    const dispatch = useDispatch();
    const { product, setProduct } = useProductContext();
    const [viewType, setViewType] = useState(ViewType.Active);
    const [modalViewerConfig, setModalViewerConfig] = useState<ViewModeConfig>({});
    const [activeFolder, setActiveFolder] = useState<string>(SystemFolderID.All);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [orderConfig, setOrderConfig] = useState<OrderConfig | undefined>();
    const [filterConfig, setFilterConfig] = useState<{ [key: string]: FilterConfig }>({});
    const [searchText, setSearchText] = useState('');
    const [fileType, setFileType] = useState('');
    const [productModel, setProductModel] = useState<string | undefined>(undefined);
    const [probeTypeId, setProbeTypeId] = useState<number | undefined>(undefined);
    const [withUnsynced, setWithUnsynced] = useState(false);
    const [showHaze, setShowHaze] = useState(true);
    const [nonEditableMeasurements, setNonEditableMeasurements] = useState<undefined | NonEditableMeasurements>(
        undefined
    );
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

    // used to poll unsynced GM measurements
    const [unsyncedMeasurementIds, setUnsyncedMeasurementIds] = useState<string[]>([]);

    const openMeasurementInNewTab = (measurementID: string) => {
        const path = getMeasurementPath(measurementID);
        window.open(path, '_blank');
    };

    const {
        refetch: refetchMeasurement,
        isFetching: isFetchingMeasurement,
        data: measurementData,
    } = useMeasurementList({
        activeFolder,
        product,
        viewType,
        filterConfig,
        orderConfig,
        productModel,
        probeTypeId,
        fileType,
        searchText,
        withUnsynced,
        page,
        pageSize,
    });

    // remove unsynced measurement ids used for poll every time product or page changes
    useEffect(() => {
        setUnsyncedMeasurementIds([]);
    }, [product, page]);

    const { data: polledMeasurements } = useMeasurementsPoll(
        {
            activeFolder,
            product,
            viewType,
            filterConfig,
            orderConfig,
            productModel,
            probeTypeId,
            fileType,
            searchText,
            withUnsynced,
            page,
            pageSize,
            mIDs: unsyncedMeasurementIds,
        },
        !isFetchingMeasurement && unsyncedMeasurementIds.length > 0 && product === ProductCode.GPR_MOUNTED
    );

    // get unsynced measurements to be used in poll. needs to be separated from polledMeasurements in case user refreshes data
    useEffect(() => {
        if (product === ProductCode.GPR_MOUNTED && measurementData?.data) {
            setUnsyncedMeasurementIds(filterUnsyncedMeasurements(measurementData.data));
        }
    }, [measurementData?.data, product]);

    // update unsynced measurements according to polled response
    useEffect(() => {
        if (product === ProductCode.GPR_MOUNTED && (polledMeasurements?.data ?? []).length > 0) {
            setUnsyncedMeasurementIds(filterUnsyncedMeasurements(polledMeasurements!.data));
        }
    }, [polledMeasurements, product]);

    const { refetch: refetchCustomMaterial, isFetching: isFetchingCustomMaterial } = useCustomMaterialList({
        pageSize,
        page,
        fileType,
        withUnsynced,
        orderConfig,
        product,
    });

    const fetchMeasurementList = useCallback(
        async (changePage: boolean = false) => {
            if (product) {
                const response = await refetchMeasurement();
                if (response.data?.data.length === 0 && changePage && page > 1) {
                    setPage((prevState) => prevState - 1);
                }
            }
        },
        [product, refetchMeasurement, page]
    );

    const fetchCustomMaterialList = useCallback(
        async (changePage: boolean = false) => {
            if (product) {
                const response = await refetchCustomMaterial();
                if (response.data?.data.length === 0 && changePage && page > 1) {
                    setPage((prevState) => prevState - 1);
                }
            }
        },
        [product, refetchCustomMaterial, page]
    );

    useEffect(() => {
        if (product) {
            dispatch(clearMeasurementList({ product, folderID: activeFolder }));
        }
    }, [dispatch, activeFolder, product, orderConfig, searchText]);

    const resetConfig = useCallback(() => {
        setPage(1);
        setFilterConfig({});
        setOrderConfig(undefined);
        setSearchText('');
        setProductModel(undefined);
        setProbeTypeId(undefined);
    }, []);

    const handleSetProduct = useCallback(
        (product: ProductCode, folderID?: string) => {
            setProduct(product);
            if (folderID === CustomCurveFileType.dgscc) {
                // custom curves fall under the folderID 'all'
                setActiveFolder(SystemFolderID.All);
            } else {
                setActiveFolder(folderID ?? SystemFolderID.All);
            }
            resetConfig();
            setFileType('');
        },
        [resetConfig, setProduct]
    );

    const handleSetActiveFolder = useCallback((folderID: string) => {
        setActiveFolder(folderID);
        setPage(1);
    }, []);

    return (
        <DataViewContext.Provider
            value={{
                fetchMeasurementList,
                fetchCustomMaterialList,
                isFetching: isFetchingMeasurement || isFetchingCustomMaterial,
                modalViewerConfig,
                setModalViewerConfig,
                openMeasurementInNewTab,
                product,
                setProduct: handleSetProduct,
                viewType,
                setViewType,
                activeFolder,
                setActiveFolder: handleSetActiveFolder,
                resetConfig,
                page,
                setPage,
                pageSize,
                setPageSize,
                orderConfig,
                setOrderConfig,
                filterConfig,
                setFilterConfig,
                searchText,
                setSearchText,
                fileType,
                setFileType,
                productModel,
                setProductModel,
                probeTypeId,
                setProbeTypeId,
                withUnsynced,
                setWithUnsynced,
                showHaze,
                setShowHaze,
                nonEditableMeasurements,
                setNonEditableMeasurements,
                selectedKeys,
                setSelectedKeys,
            }}
        >
            {props.children}
        </DataViewContext.Provider>
    );
};

export const useDataViewContext = () => {
    return useContext(DataViewContext);
};
