import React from 'react';
import { isFinite } from 'lodash';
import { FormatIDs } from '../types';
import ConvertedUnits from '../components/DataView/DataViewers/shared/ConvertedUnits';
import FormattedMessage from '../localization/FormatMessage';
import { ScanType } from '../types/proceq';
import { convertAndDisplay } from '../components/DataView/DataViewers/utils/conversionRates';

export interface RawSectionTableData {
    label: FormatIDs;
    unitId?: string;
    value: string | number;
    enable?: boolean;
    filterOn?: boolean;
    escape?: boolean;
}

export interface SectionTableData {
    label: React.ReactNode;
    key: string;
    value: string | number | React.ReactNode;
}

const convertValue = (value: string | number, unitId: string, scanType: ScanType, isMetric: boolean | string) =>
    isFinite(Number(value)) ? convertAndDisplay(unitId, scanType, isMetric).convertFunction(Number(value)) ?? '' : '-';

export const getConvertedSectionTableData = (
    rawData: RawSectionTableData[],
    scanType: ScanType,
    isMetric: boolean | string,
    valueConversion?: (row: RawSectionTableData) => string | number | React.ReactNode
): SectionTableData[] => {
    return rawData
        .filter((row) => row.enable !== false)
        .map((row) => ({
            key: row.label,
            label: row.unitId ? (
                <ConvertedUnits id={row.label} unitId={row.unitId} scanType={scanType} isMetric={isMetric} />
            ) : (
                <FormattedMessage id={row.label} />
            ),
            value: valueConversion
                ? valueConversion(row)
                : row.unitId && row.value
                  ? convertValue(row.value, row.unitId, scanType, isMetric)
                  : row.value,
        }));
};
