import { isEmpty } from 'lodash';
import React, { useCallback, useState } from 'react';
import FormattedMessage from '../../localization/FormatMessage';
import { ReactComponent as IconShare } from '../../images/iconShare.svg';
import styles from './ButtonShare.styl';
import { useSelectedContractFeatures } from '../../hooks/useContract';
import { ProductCode } from '../../types/proceq';
import { FeatureCode } from '../../types/contract';
import AnalyticsButton from '../AnalyticsComponents/Button';
import analytics from '../../analytics/firebaseAnalytics';
import { ShareLinkAction } from '../../analytics/analyticsConstants';
import { useMeasurements } from '../../hooks/useProductData';
import InvalidShareExportModal, { InvalidShareExportType } from './DataViewModal/InvalidShareExportModal';
import MeasurementCopyLink from './MeasurementCopyLink';

interface ButtonShareProps {
    product: ProductCode;
    productModel?: string;
    mIDs: string[];
    setSelectedKeys: (keys: string[]) => void;
    primaryStyle?: boolean;
    isCustomCurve?: boolean;
}

const ButtonShare: React.FunctionComponent<ButtonShareProps> = (props) => {
    const { product, mIDs, setSelectedKeys, productModel, primaryStyle = false, isCustomCurve } = props;
    const [invalidExportModal, setInvalidExportModal] = useState(false);
    const [modalVisibility, setModalVisibility] = useState(false);
    const measurements = useMeasurements();
    const { getInvalidMeasurementIDs } = useSelectedContractFeatures(measurements, mIDs);

    const invalidMeasurementIDs = getInvalidMeasurementIDs(FeatureCode.COMMON.SHARE_URL, productModel, product);

    const handleOpenModal = useCallback(() => {
        if (!isCustomCurve && invalidMeasurementIDs.length > 0) {
            setInvalidExportModal(true);
        } else {
            setModalVisibility(true);
        }
        analytics.logShareLinkEvent(ShareLinkAction.share, product);
    }, [invalidMeasurementIDs.length, isCustomCurve, product]);

    const handleExcludeAndShare = () => {
        setSelectedKeys(mIDs.filter((id) => !invalidMeasurementIDs.includes(id)));
        setInvalidExportModal(false);
        setModalVisibility(true);
        analytics.logShareLinkEvent(ShareLinkAction.excludeAndShare, product);
    };

    return (
        <>
            <AnalyticsButton
                disabled={isEmpty(mIDs) || modalVisibility}
                onClick={handleOpenModal}
                className={styles.button}
                type={primaryStyle ? 'primary' : 'default'}
            >
                <div className={styles.icon}>
                    <IconShare />
                </div>
                <FormattedMessage id="App.Share" />
            </AnalyticsButton>
            <MeasurementCopyLink
                visible={modalVisibility}
                onCancel={() => setModalVisibility(false)}
                mIDs={mIDs}
                product={product}
            />
            <InvalidShareExportModal
                visible={invalidExportModal}
                onClose={() => {
                    setInvalidExportModal(false);
                    analytics.logShareLinkEvent(ShareLinkAction.cancel, product);
                }}
                invalidType={InvalidShareExportType.share}
                invalidMeasurementIDs={invalidMeasurementIDs}
                onExclude={handleExcludeAndShare}
                selectedKeysCount={mIDs.length}
            />
        </>
    );
};

export default ButtonShare;
