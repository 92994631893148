import React from 'react';
import StyledModal from '../../../shared/StyledModal';
import FormattedMessage from '../../../../localization/FormatMessage';
import AnalyticsButton from '../../../AnalyticsComponents/Button';
import BackButton from '../../../shared/Buttons/BackButton';

interface UploadConfirmationProps {
    onBack: () => void;
    onUpload: () => void;
    visible: boolean;
}

const UploadConfirmation: React.FunctionComponent<UploadConfirmationProps> = (props) => {
    const { onBack, onUpload, visible } = props;
    return (
        <StyledModal
            footer={
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <BackButton onClick={onBack} buttonText="DataView.Upload.Validate.Back" />
                    <AnalyticsButton onClick={onUpload} type="primary">
                        <FormattedMessage id="DataView.Upload.Validate.Confirm" />
                    </AnalyticsButton>
                </div>
            }
            closable={false}
            open={visible}
            title={<FormattedMessage id="DataView.Upload.Validate.Title" />}
        >
            <span style={{ whiteSpace: 'pre-wrap' }}>
                <FormattedMessage id="DataView.Upload.Validate.Body" />
            </span>
        </StyledModal>
    );
};

export default UploadConfirmation;
