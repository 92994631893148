import React from 'react';
import colors from '../../styles/colors.json';

const BlankBackground: React.FunctionComponent = (props) => {
    return (
        <div
            style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundColor: colors.dark_background,
            }}
        >
            {props.children}
        </div>
    );
};

export default BlankBackground;
