import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import routeURLs from '../Routes/urls';
import FormattedMessage from '../../localization/FormatMessage';
import BlankBackground from '../shared/BlankBackground';
import styles from './PermissionDenied.styl';
import * as authUtils from '../../utils/authUtils';

const PermissionDenied: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    return (
        <BlankBackground>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <h1 className={styles.display2}>
                    <FormattedMessage id="error.PermissionDenied.Heading" />
                </h1>
                <p className={styles.body1}>
                    <FormattedMessage id="error.PermissionDenied.Paragraph" />
                </p>
                <div>
                    <Link to="/">
                        <FormattedMessage id="error.PageNotFound.Link" />
                    </Link>
                </div>
                <div>
                    <a
                        onClick={() => {
                            authUtils.cleanUpCredentials(dispatch);
                            history.push(routeURLs.AUTH);
                        }}
                    >
                        <FormattedMessage id="error.PermissionDenied.Link" />
                    </a>
                </div>
            </div>
        </BlankBackground>
    );
};

export default PermissionDenied;
