import React, { useLayoutEffect, useRef } from 'react';
import config from '../../config';
import { useFormatMessage } from '../../localization/useFormatMessage';

const NotificationProvider: React.FunctionComponent = ({ children }) => {
    const isInitialized = useRef(false);
    const formatMessage = useFormatMessage();

    useLayoutEffect(() => {
        const OneSignal = window.OneSignal;
        // isInitialized check is needed to prevent reinitialize form formatMessage changes
        if (!OneSignal || isInitialized.current) {
            return;
        }
        isInitialized.current = true;

        // Localhost got it's own ID, only works for http://localhost:3040
        const oneSignalAppID =
            config.NODE_ENV === 'development' ? '17a0a66d-da79-42d2-81af-fb4302000ac6' : config.ONE_SIGNAL_APP_ID;
        const oneSignalSafariID =
            config.NODE_ENV === 'development'
                ? 'web.onesignal.auto.276b1587-9df2-49f1-886b-c818bd2e0d3c'
                : config.ONE_SIGNAL_SAFARI_ID;

        OneSignal.push(() => {
            OneSignal.init({
                appId: oneSignalAppID,
                safari_web_id: oneSignalSafariID,
                allowLocalhostAsSecureOrigin: true,
                persistNotification: false,
                promptOptions: {
                    slidedown: {
                        enabled: true,
                        type: 'push',
                        autoPrompt: false,
                        actionMessage: formatMessage({ id: 'Notifications.Request.Message' }),
                        acceptButton: formatMessage({ id: 'Notifications.Request.Allow' }),
                        cancelButton: formatMessage({ id: 'Notifications.Request.NoThanks' }),
                    },
                },
            });
        });
    }, [formatMessage]);

    return <>{children}</>;
};

export default NotificationProvider;
