import React from 'react';
import { SnapshotEntry, SnapshotType } from './SnapshotTypes';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { FormatIDs } from '../../../../../types';
import { formatDateTimeDefault } from '../../../../../utils/dateUtils';
import styles from './SnapshotsViewer.styl';
import useProceqUsers from '../../../../../hooks/useProceqUsers';
import { renderUserInitials } from '../../LogEvent/LogEvent';
import AnalyticsButton from '../../../../AnalyticsComponents/Button';
import analytics from '../../../../../analytics/firebaseAnalytics';
import { SnapshotAction } from '../../../../../analytics/analyticsConstants';

const titleMap: { [key in SnapshotType]: FormatIDs } = {
    [SnapshotType.ExportedSnapshot]: 'DataView.Snapshot.Exported',
    [SnapshotType.SavedSnapshot]: 'DataView.Snapshot.Saved',
    [SnapshotType.LatestViewedSnapshot]: 'DataView.Snapshot.LastViewed',
};

const SnapshotInformation: React.FunctionComponent<{
    entry: SnapshotEntry;
    product?: string;
    allowDownload?: boolean;
}> = ({ entry, product, allowDownload = true }) => {
    const { type } = entry;
    const { users } = useProceqUsers();

    const date = entry.log?.clientCreated;
    const uID = entry.log?.uID;
    const initials = renderUserInitials(users[String(uID)]);

    const fileName = entry.imageName || `${entry.log?.content.attachmentName}.png`;
    const imageUrl = entry.imageURL;
    const title = <FormattedMessage id={titleMap[type]} />;

    return (
        <div className={styles.snapshot_information}>
            <div>
                <div className={styles.subtitle1}>{title}</div>
                <div className={styles.caption}>
                    {date && <span>{formatDateTimeDefault(date)}</span>}
                    {initials && (
                        <span>
                            {' • '}
                            <FormattedMessage id="DataView.Snapshot.ByUser" values={{ userName: initials }} />
                        </span>
                    )}
                </div>
            </div>
            {allowDownload && (
                <div className={styles.actions}>
                    <a
                        href={imageUrl}
                        download={fileName}
                        onClick={() => analytics.logDownloadSnapshot(SnapshotAction.detail, product)}
                    >
                        <AnalyticsButton>
                            <FormattedMessage id="App.Download" />
                        </AnalyticsButton>
                    </a>
                </div>
            )}
        </div>
    );
};

export default SnapshotInformation;
