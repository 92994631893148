import React from 'react';
import { Switch, Router } from 'react-router-dom';
import AnalyticsRoute from '../AnalyticsComponents/Route';
import AuthReceiver from '../Auth/AuthReceiver';
import AuthLogout from '../Auth/AuthLogout';
import routeURLs from './urls';
import AppRoute from './AppRoutes';
import { browserHistory } from '../../store';
import PermissionDenied from '../PermissionDenied';
import StandaloneMeasurementView from '../StandaloneMeasurementView/StandaloneMeasurementView';
import StandaloneMeasurementListing from '../StandaloneMeasurementView/StandaloneMeasurementListing';
import StandaloneMeasurementRoot from '../StandaloneMeasurementView/Root';
import ImportRedirection from '../MeasurementImport/ImportRedirection';
import HTMLViewer from '../DataView/HTMLViewer/HTMLViewer';
import ToolInsights from '../DataView/Tools/ToolInsights';
import UriRedirect from '../UriRedirect';

const Routes: React.FunctionComponent = () => {
    return (
        <Router history={browserHistory}>
            <Switch>
                <AnalyticsRoute path={routeURLs.AUTH} titleFormatIDs={[]} component={AuthReceiver} />
                <AnalyticsRoute path={routeURLs.LOGOUT} titleFormatIDs={[]} component={AuthLogout} />
                <AnalyticsRoute path={routeURLs.PERMISSION_DENIED} titleFormatIDs={[]} component={PermissionDenied} />
                <AnalyticsRoute exact path={routeURLs.STANDALONE_TOOL} titleFormatIDs={[]} component={ToolInsights} />
                <AnalyticsRoute
                    exact
                    path={routeURLs.STANDALONE_MEASUREMENT}
                    titleFormatIDs={[]}
                    component={StandaloneMeasurementRoot}
                />
                <AnalyticsRoute
                    exact
                    path={routeURLs.STANDALONE_MEASUREMENT_LISTING}
                    titleFormatIDs={[]}
                    component={StandaloneMeasurementListing}
                />
                <AnalyticsRoute
                    exact
                    path={routeURLs.STANDALONE_MEASUREMENT_DETAIL}
                    titleFormatIDs={[]}
                    component={StandaloneMeasurementView}
                />
                <AnalyticsRoute path={routeURLs.SHARED_URL} titleFormatIDs={[]} component={ImportRedirection} />
                <AnalyticsRoute path={routeURLs.EXPORT_HTML} titleFormatIDs={[]} component={HTMLViewer} />
                <AnalyticsRoute path={routeURLs.URI_REDIRECT} titleFormatIDs={[]} component={UriRedirect} />
                <AnalyticsRoute path="/" titleFormatIDs={[]} component={AppRoute} />
            </Switch>
        </Router>
    );
};

export default Routes;
