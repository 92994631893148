import React, { useCallback } from 'react';
import { get, isFinite } from 'lodash';
import MeasurementPresets, { title as titleMeasurementPresets } from './shared/MeasurementPresets';
import ImageProcessing, { title as titleImageProcessing } from './Profometer/ImageProcessing';
import { DerivedProps } from '../SharedTypes';
import { convertAndDisplay } from '../utils/conversionRates';
import { GPRFamilyReadingsProps, ReadingContainer, ReadingPanel } from './ReadingComponents';
import { ProductModel, ScanType } from '../../../../types/proceq';
import User, { title as UserTitle } from '../Common/User';
import Markers, { title as MarkersTitle } from './shared/Markers';
import { PROFOMETER_METRIC_UNITS } from '../../../../types/measurement';
import { title as scanDistanceTitle } from './GPR/ScanDistance';
import ScanDistance from './Profometer/ScanDistance';
import ScanDetails from './Profometer/ScanDetails';
import { getLineScanDetails } from './Profometer/scanDetailUtils';
import SavedSnapshots from './SavedSnapshots';
import Statistics from './Profometer/Statistics';

const ProfometerReadings: React.FunctionComponent<GPRFamilyReadingsProps> = (props) => {
    const { product, data, isStandalone } = props;
    const productModel = get(data, 'measurement.productModel')?.toUpperCase() as ProductModel;
    const scanType = get(data, 'measurement.type');
    const isMetric = PROFOMETER_METRIC_UNITS.has(data?.settings[0].content.preset.unit);

    const convertor: DerivedProps['convert'] = useCallback(
        (value, unitId) =>
            isFinite(Number(value))
                ? convertAndDisplay(unitId, scanType, isMetric).convertFunction(Number(value)) ?? ''
                : '-',
        [isMetric, scanType]
    );

    if (!data || !scanType) {
        return null;
    }

    const derivedProps: DerivedProps & { productModel: ProductModel } = {
        product,
        productModel,
        scanType,
        isMetric,
        convert: convertor,
        showTitle: isStandalone,
    };

    const hasLineScanDetails = ![ScanType.SpotScan, ScanType.Advanced, ScanType.Basic].includes(scanType);
    const lineScanDetails = hasLineScanDetails ? getLineScanDetails(data, scanType) : {};

    return (
        <ReadingContainer>
            <ReadingPanel key="user" header={UserTitle}>
                <User data={data} {...derivedProps} />
            </ReadingPanel>

            <ReadingPanel key="measurementPresets" header={titleMeasurementPresets}>
                <MeasurementPresets data={data} {...derivedProps} />
            </ReadingPanel>

            {scanType !== ScanType.SpotScan && (
                <ReadingPanel key="measurements" header={titleImageProcessing}>
                    <ImageProcessing data={data} {...derivedProps} />
                </ReadingPanel>
            )}

            {scanType !== ScanType.SpotScan && data.objects && (
                <ReadingPanel key="markers" header={MarkersTitle}>
                    <Markers data={data} {...derivedProps} />
                </ReadingPanel>
            )}

            {hasLineScanDetails && (
                <>
                    <ReadingPanel key="scanDistance" header={scanDistanceTitle(derivedProps)}>
                        <ScanDistance data={data} {...derivedProps} lineScanDetails={lineScanDetails} />
                    </ReadingPanel>
                    <ReadingPanel key="scanDetails" header={scanDistanceTitle(derivedProps)}>
                        <ScanDetails data={data} {...derivedProps} lineScanDetails={lineScanDetails} />
                    </ReadingPanel>
                </>
            )}

            {scanType === ScanType.Advanced && (data.measurement?.content?.statistics?.noOfReadings ?? 0) >= 20 && (
                <ReadingPanel key="statistics" header={MarkersTitle}>
                    <Statistics data={data} {...derivedProps} />
                </ReadingPanel>
            )}

            <SavedSnapshots data={data} {...derivedProps} showPropertiesTable={false} />
        </ReadingContainer>
    );
};

export default ProfometerReadings;
