import React from 'react';
import { routeUtils } from 'tds-common-fe';
import FormattedMessage from '../../../../localization/FormatMessage';
import styles from './DeleteAccount.styl';
import Button from '../../../AnalyticsComponents/Button';
import routeURLs from '../../../Routes/urls';
import { useCurrentUserEmail } from '../../../../hooks/useCurrentUser';
import analytics from '../../../../analytics/firebaseAnalytics';
import { AccountDeleteStatus } from '../../../../types/profile';
import { FormatIDs } from '../../../../types';

const getAccountDeletionStatusText = (
    accountDeleteStatus: AccountDeleteStatus
): {
    description: FormatIDs;
    buttonText: FormatIDs;
} => {
    switch (accountDeleteStatus) {
        case AccountDeleteStatus.doesNotExist:
            return {
                description: 'Profile.DeleteAccount.Text',
                buttonText: 'Profile.DeleteAccount.RequestToDelete',
            };
        case AccountDeleteStatus.pendingConfirmation:
            return {
                description: 'Profile.DeleteAccount.Pending.Text',
                buttonText: 'Profile.DeleteAccount.Pending.RequestSubmitted',
            };
        case AccountDeleteStatus.confirmed:
            return {
                description: 'Profile.DeleteAccount.Confirmed.Text',
                buttonText: 'Profile.DeleteAccount.Confirmed.PendingDeletion',
            };
        default:
            return {
                description: ' ',
                buttonText: ' ',
            };
    }
};

interface DeleteAccountParams {
    product?: string;
}

interface DeleteAccountProps {
    accountDeleteStatus: AccountDeleteStatus;
}

const DeleteAccount: React.FunctionComponent<DeleteAccountProps> = ({ accountDeleteStatus }) => {
    const { product = 'workspace' } = routeUtils.parseQuery<DeleteAccountParams>(location.search);
    const email = useCurrentUserEmail();
    const accountDeletionPath = routeUtils.makeQueryPath(routeURLs.EAGLE_ID_INITIATE_ACCOUNT_DELETION, {
        email,
        redirect_uri: location.href,
        product,
    });

    const { description, buttonText } = getAccountDeletionStatusText(accountDeleteStatus);

    return (
        <>
            <div className={accountDeleteStatus ? styles.disabled_container : ''}>
                <div className={styles.profile_delete_title}>
                    <FormattedMessage id="Profile.DeleteAccount" />
                </div>
                <div className={styles.subtitle1}>
                    <FormattedMessage id={description} />
                </div>
                <div className={styles.request_button}>
                    <Button
                        ghost
                        danger
                        href={accountDeletionPath}
                        disabled={!!accountDeleteStatus}
                        onClick={() => analytics.logDeleteAccount(product)}
                    >
                        <FormattedMessage id={buttonText} />
                    </Button>
                </div>
            </div>
        </>
    );
};

export default DeleteAccount;
