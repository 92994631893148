import React, { useCallback, useEffect, useRef, useState } from 'react';
import JSZip from 'jszip';
import { find } from 'lodash';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './SnapshotsViewer.styl';
import { getFile } from '../../../../../api/utilsService';
import Spinner from '../../../../shared/Spinner';
import { classNames } from '../../../../../utils/styleUtils';
import { ReactComponent as IconArrowDown } from '../../../../../images/iconArrowDown.svg';
import { MeasurementFullData } from '../../../../../types/measurement';
import PropertiesTable from '../PropertiesTable';
import FormattedMessage from '../../../../../localization/FormatMessage';
import SnapshotInformation from './SnapshotInformation';
import { SnapshotEntry, SnapshotType } from './SnapshotTypes';
import { getSharedProceqUsers } from '../../../../../api/userService';
import imagePlaceholderSrc from '../../../../../images/imagePlaceholder.svg';

interface SnapshotsViewerProps {
    measurement?: MeasurementFullData;
}

// due to bug in lib https://github.com/akiran/react-slick/issues/1195
const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }: any) => <span {...props}>{children}</span>;

const SnapshotsViewer: React.FunctionComponent<SnapshotsViewerProps> = (props) => {
    const { measurement } = props;
    const [entries, setEntries] = useState<{ [key: string]: SnapshotEntry }>({});
    const [entryIDs, setEntryIDs] = useState<string[]>([]);
    const [openedID, setOpenedID] = useState('');

    useEffect(() => {
        const snapshotLogs = measurement?.logs
            ?.filter((log) => log.type === SnapshotType.SavedSnapshot || log.type === SnapshotType.ExportedSnapshot)
            .sort((logA, logB) => logB.clientCreated - logA.clientCreated);
        const latestSnapshot = measurement?.attachments?.find(
            (attachment) => attachment.type === SnapshotType.LatestViewedSnapshot
        );

        const newEntries: { [key: string]: SnapshotEntry } = {};

        const entryIDs: string[] = [];
        if (latestSnapshot) {
            const aID = latestSnapshot.id;
            const mID = latestSnapshot.mID;
            newEntries[aID] = { mID, type: SnapshotType.LatestViewedSnapshot };
            entryIDs.push(aID);
        }
        if (snapshotLogs) {
            const userIDs = new Set<number>();
            for (const log of snapshotLogs) {
                const aID = log.content?.attachmentId;
                const mID = log.mID;
                if (!aID) {
                    continue;
                }
                userIDs.add(log.uID);
                newEntries[aID] = {
                    mID,
                    type: log.type as SnapshotType,
                    log,
                };
                entryIDs.push(aID);
            }
            if (userIDs.size > 0) {
                getSharedProceqUsers({ ids: Array.from(userIDs) });
            }
        }
        if (entryIDs.length) {
            setEntries((entries) => {
                for (const aID of entryIDs) {
                    newEntries[aID] = {
                        ...entries[aID],
                        ...newEntries[aID],
                    };
                }
                return {
                    ...entries,
                    ...newEntries,
                };
            });
            setEntryIDs(entryIDs);
            setOpenedID(entryIDs[0]);
        }

        return () => {
            setEntries({});
            setOpenedID('');
            setEntryIDs([]);
        };
    }, [measurement]);

    const fetchData = useCallback(async (mID: string, aID: string) => {
        let imageURL = '';
        let imageName = '';
        try {
            const arrayBuffer = await getFile({ mID, aID });
            const zip = await JSZip.loadAsync(arrayBuffer);
            const image = find(zip.files, (_file, fileName: string) => /\.(jpg|png)$/.test(fileName));

            if (image) {
                const blob = await image.async('blob');
                imageURL = window.URL.createObjectURL(blob);
                imageName = image.name;
            }
        } finally {
            setEntries((entries) => {
                return { ...entries, [aID]: { ...entries[aID], imageURL, imageName } };
            });
        }
    }, []);

    const onLazyLoad = useCallback(
        (slides: number[]) => {
            if (!entryIDs.length) {
                return;
            }
            for (const slide of slides) {
                const fixedIndex = (slide + entryIDs.length) % entryIDs.length;
                const aID = entryIDs[fixedIndex];
                const mID = entries[aID].mID;
                if (mID && aID && !entries[aID].imageName) {
                    fetchData(mID, aID);
                }
            }
        },
        [entries, entryIDs, fetchData]
    );

    const tempPos = useRef<{ x: number; y: number }>({ x: 0, y: 0 });

    const handlePointerDown = useCallback((event: React.PointerEvent) => {
        tempPos.current = { x: event.clientX, y: event.clientY };
    }, []);

    const handlePointerUp = useCallback((event: React.PointerEvent, aID: string) => {
        const xMovement = event.clientX - tempPos.current.x;
        const yMovement = event.clientY - tempPos.current.y;
        const tolerance = 10;
        if (xMovement ** 2 + yMovement ** 2 < tolerance ** 2) {
            setOpenedID(aID);
        }
    }, []);

    const currentSnapshotURL = entries[openedID]?.imageURL;

    if (!entryIDs.length) {
        return null;
    }

    return (
        <>
            <PropertiesTable title={<FormattedMessage id="Proceq.DashboardSnapshots" />} />
            <div className={styles.slider_wrapper}>
                <div className={styles.current_snapshot}>
                    {currentSnapshotURL === undefined ? (
                        <Spinner size="default" />
                    ) : (
                        <>
                            <img src={currentSnapshotURL || imagePlaceholderSrc} alt="" />
                            <SnapshotInformation
                                entry={entries[openedID]}
                                product={measurement?.productFamily}
                                allowDownload={!!currentSnapshotURL}
                            />
                        </>
                    )}
                </div>

                <Slider
                    className={styles.slider}
                    speed={500}
                    infinite={false}
                    slidesToShow={4}
                    slidesToScroll={4}
                    lazyLoad="ondemand"
                    onLazyLoad={onLazyLoad}
                    nextArrow={
                        <SlickButtonFix>
                            <IconArrowDown />
                        </SlickButtonFix>
                    }
                    prevArrow={
                        <SlickButtonFix>
                            <IconArrowDown />
                        </SlickButtonFix>
                    }
                >
                    {entryIDs.map((aID) => {
                        const imageURL = entries[aID]?.imageURL;
                        const isLoading = imageURL === undefined;
                        return (
                            <div
                                className={classNames(styles.slide, openedID === aID && styles.active_slide)}
                                key={aID}
                                style={{ maxWidth: 'calc(100% / 4)' }}
                                onPointerDown={handlePointerDown}
                                onPointerUp={(event) => handlePointerUp(event, aID)}
                            >
                                <div className={styles.image_wrapper}>
                                    {isLoading ? (
                                        <Spinner size="default" />
                                    ) : (
                                        <img src={imageURL || imagePlaceholderSrc} alt="" />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </Slider>
            </div>
        </>
    );
};

export default SnapshotsViewer;
