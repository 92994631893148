import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getMeasurementFullData } from '../../api/measurementService';
import { useMeasurements } from '../../hooks/useProductData';
import { ProductCode } from '../../types/proceq';
import Readings from '../DataView/DataViewers/Readings';
import Logbook from '../DataView/DataViewers/Common/Logbook';
import Spinner from '../shared/Spinner';
import ViewerWrapper from './ViewerWrapper';
import MeasurementViewerContext, { useMeasurementViewerContext } from './MeasurementViewerContext';
import { useProductContext } from '../DataView/ProductContextProvider';

const FullMeasurementViewerContent: React.FunctionComponent<{ measurementID: string }> = (props) => {
    const { measurementID } = props;

    const { isHTMLExport, isHTMLView } = useMeasurementViewerContext();
    const { setProduct } = useProductContext();

    const [isLoading, setIsLoading] = useState(!isHTMLExport && !isHTMLView);

    const loadData = useCallback(async () => {
        if (isHTMLExport || isHTMLView) {
            return;
        }

        setIsLoading(true);
        try {
            await getMeasurementFullData({ measurementID });
        } finally {
            setIsLoading(false);
        }
    }, [measurementID, isHTMLExport, isHTMLView]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const measurements = useMeasurements();
    const measurement = measurements?.[measurementID];
    const productCode = measurement?.productFamily?.toUpperCase() as ProductCode;

    useEffect(() => {
        setProduct(productCode);
    }, [productCode, setProduct]);

    const readings = useMemo(
        () => <Readings product={productCode} measurementID={measurementID} />,
        [measurementID, productCode]
    );

    const logbook = useMemo(
        () => <Logbook product={productCode} measurementID={measurementID} />,
        [measurementID, productCode]
    );

    if (isLoading) {
        return <Spinner size="default" />;
    }

    return (
        <ViewerWrapper productCode={productCode} title={measurement?.measurement.name}>
            {readings}
            <div className="data-view-panel-html" style={{ maxWidth: 500 }}>
                {logbook}
            </div>
        </ViewerWrapper>
    );
};

interface FullMeasurementViewerProps {
    measurementID: string;
    isHTMLExport?: boolean;
    isHTMLView?: boolean;
    countryCode?: string;
}

const FullMeasurementViewer: React.FunctionComponent<FullMeasurementViewerProps> = (props) => {
    const { isHTMLExport = false, isHTMLView = false, countryCode } = props;
    return (
        <MeasurementViewerContext.Provider
            value={{
                isStandalone: true,
                isHTMLExport,
                isHTMLView,
                countryCode,
                setViewerReady: () => {
                    window.viewerReady = true;
                },
            }}
        >
            <FullMeasurementViewerContent measurementID={props.measurementID} />
        </MeasurementViewerContext.Provider>
    );
};

export default FullMeasurementViewer;
