import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import analytics from '../../analytics/firebaseAnalytics';
import { FormatIDs } from '../../types';
import { useFormatMessage } from '../../localization/useFormatMessage';

interface AnalyticsRouteProps extends RouteProps {
    changeDocumentTitle?: boolean;
    titleFormatIDs?: FormatIDs[];
    computedMatch?: {
        params: { [K: string]: string };
    };
}

const AnalyticsRoute: React.FunctionComponent<AnalyticsRouteProps> = ({
    titleFormatIDs = [],
    changeDocumentTitle = false,
    ...props
}) => {
    const { exact } = props;
    const formatMessage = useFormatMessage();
    const pagePath = location.pathname + location.search;
    const pageName = titleFormatIDs.reduce(
        (acc, formatID, index) => `${acc}${index > 0 ? ' | ' : ''}${formatMessage({ id: formatID })}`,
        ''
    );
    const defaultTitle = formatMessage({ id: 'Title' });

    useEffect(() => {
        if (changeDocumentTitle) {
            document.title = `${pageName} - ${defaultTitle}`;
            return () => {
                document.title = defaultTitle;
            };
        }
        return () => {};
    }, [changeDocumentTitle, pageName, defaultTitle]);

    useEffect(() => {
        if (exact) {
            analytics.setCurrentScreen(pagePath);
        }
    }, [exact, pagePath]);

    return <Route {...props} />;
};

export default AnalyticsRoute;
