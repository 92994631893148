import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { noop, union, uniq } from 'lodash';
import { useSelector } from 'react-redux';
import MeasurementExportModal from './MeasurementExportModal';
import { ProductCode, ScanType } from '../../types/proceq';
import { ExportFormat, PDFExtendedFileType } from '../../api/utilsService';
import { BluetoothFileType } from '../../types/measurement';
import { RootState } from '../../reducers';
import { useProceqAppConfigs } from '../../hooks/useConfig';
import { useSelectedContractFeatures } from '../../hooks/useContract';
import { FeatureCode } from '../../types/contract';
import { MeasurementPage } from '../../analytics/analyticsConstants';
import { useMeasurements } from '../../hooks/useProductData';

interface ExportConfig {
    mIDs?: string[];
    product?: ProductCode;
    fileType?: string;
    showModal?: boolean;
    setSelectedKeys?: (keys: string[]) => void;
}

interface MeasurementExportContextState {
    exportConfig: ExportConfig;
    setExportConfig: React.Dispatch<React.SetStateAction<ExportConfig>>;
    exportOptions: { type: ExportFormat | PDFExtendedFileType; invalidIDs: string[]; active?: boolean }[];
    isVerification: boolean;
    isLongRunningJobEnable: boolean;
}

const MeasurementExportContext = createContext<MeasurementExportContextState>({
    exportConfig: {},
    setExportConfig: noop,
    exportOptions: [],
    isVerification: false,
    isLongRunningJobEnable: false,
});

export const useMeasurementExportContext = () => {
    return useContext(MeasurementExportContext);
};

interface MeasurementExportProviderProps {
    page: MeasurementPage;
}

const MeasurementExportProvider: React.FunctionComponent<MeasurementExportProviderProps> = (props) => {
    const { children, page } = props;

    const roleState = useSelector((state: RootState) => state.profile.roleState);
    const enableJsonDownload = roleState.isDev || roleState.isPO;

    const [exportConfig, setExportConfig] = useState<ExportConfig>({});
    const { mIDs, product, fileType } = exportConfig;

    const isVerification = fileType === BluetoothFileType.VerificationData;

    const proceqAppConfigs = useProceqAppConfigs();

    const [isLongRunningJobEnable, setIsLongRunningJobEnable] = useState(false);

    const measurements = useMeasurements();
    const { getInvalidMeasurementIDs, getInvalidMeasurementIDsMultiple, demoMeasurementIDs } =
        useSelectedContractFeatures(measurements, mIDs);

    useEffect(() => {
        if (product === ProductCode.GPR_SOIL) {
            const enable = proceqAppConfigs.GPR?.IS_LONG_RUNNING_JOB_ENABLE_COMMON === 'YES';
            setIsLongRunningJobEnable(enable);
        } else {
            setIsLongRunningJobEnable(false);
        }
    }, [proceqAppConfigs, product]);

    const showSchmidtSpecialReports = (mIDs && mIDs.length > 1) || false;

    const showSchmidtJGJExport = useMemo(() => {
        if (mIDs) {
            return !mIDs
                .map((key) => measurements[key])
                .some((measurement) => measurement.measurement.productModel.toUpperCase() === 'SSL');
        }
        return false;
    }, [measurements, mIDs]);

    const showPunditRawData = useMemo(() => {
        if (mIDs) {
            return !mIDs
                .map((key) => measurements[key])
                .some((measurement) => measurement.measurement.type === ScanType.GridScan);
        }
        return false;
    }, [measurements, mIDs]);

    const missingGPSDataIds = useMemo(() => {
        if (product === ProductCode.GPR_SOIL && mIDs) {
            return mIDs.filter((key) => {
                const gnssInfo = measurements[key].measurement.content?.gnssInfo;
                return (
                    gnssInfo === undefined ||
                    gnssInfo.coordinateSystem?.projectionType === 2 ||
                    (gnssInfo.coordinateSystem?.countrySpecificsettings?.coordinateSystem?.EPSG === undefined &&
                        gnssInfo.coordinateSystem?.utmSettings?.utmZone?.EPSG === undefined)
                );
            });
        }
        return [];
    }, [mIDs, measurements, product]);

    const allowPMDxfExport = useMemo(() => {
        if (product !== ProductCode.PROFOMETER) return undefined;
        if (mIDs) {
            return !mIDs
                .map((key) => measurements[key])
                .some((measurement) => measurement.measurement.type !== ScanType.AreaScan);
        }
        return false;
    }, [product, mIDs, measurements]);

    const exportOptions = useMemo(() => {
        const items: {
            type: ExportFormat | PDFExtendedFileType;
            invalidIDs: string[];
            active?: boolean;
        }[] = [];

        /**
         * WIFI family
         */

        switch (product) {
            case 'GPR':
                items.push(
                    {
                        type: ExportFormat.SEGY,
                        invalidIDs: getInvalidMeasurementIDsMultiple({
                            GPR: FeatureCode.GPR.SEGY,
                            GPR_PALM: FeatureCode.GPR_PALM.SEGY,
                            GPR_GP8100: FeatureCode.GPR_GP8100.SEGY,
                        }),
                    },
                    {
                        type: ExportFormat.HTML,
                        invalidIDs: getInvalidMeasurementIDs(FeatureCode.COMMON.HTML),
                    }
                );
                break;
            case 'GPR_MOUNTED':
                items.push({
                    type: ExportFormat.HTML,
                    invalidIDs: getInvalidMeasurementIDs(FeatureCode.COMMON.HTML),
                });
                break;
            case 'GPR_SOIL':
                items.push(
                    {
                        type: ExportFormat.SEGY,
                        invalidIDs: getInvalidMeasurementIDsMultiple({
                            GPR_SOIL: FeatureCode.GPR_SOIL.SEGY,
                            GS9000: FeatureCode.GS9000.SEGY,
                        }),
                    },
                    {
                        type: ExportFormat.DXF,
                        invalidIDs: getInvalidMeasurementIDsMultiple({
                            GPR_SOIL: FeatureCode.GPR_SOIL.DXF,
                            GS9000: FeatureCode.GS9000.DXF,
                        }),
                    },
                    {
                        type: ExportFormat.KML,
                        invalidIDs: uniq(
                            union(
                                missingGPSDataIds,
                                getInvalidMeasurementIDsMultiple({
                                    GPR_SOIL: FeatureCode.GPR_SOIL.KML,
                                    GS9000: FeatureCode.GS9000.KML,
                                })
                            )
                        ),
                    },
                    {
                        type: ExportFormat.HTML,
                        invalidIDs: getInvalidMeasurementIDs(FeatureCode.COMMON.HTML),
                    },
                    {
                        type: ExportFormat.SHP,
                        invalidIDs: uniq(
                            union(
                                missingGPSDataIds,
                                getInvalidMeasurementIDsMultiple({
                                    GPR_SOIL: FeatureCode.GPR_SOIL.SHP,
                                    GS9000: FeatureCode.GS9000.SHP,
                                })
                            )
                        ),
                    }
                );
                break;
            case 'PUNDIT':
                if (showPunditRawData) {
                    items.push({
                        type: ExportFormat.RAW,
                        invalidIDs: getInvalidMeasurementIDsMultiple({
                            PUNDIT: FeatureCode.PUNDIT.RAW,
                            PUNDIT_PD8050: FeatureCode.PUNDIT_PD8050.RAW,
                        }),
                    });
                }
                items.push({
                    type: ExportFormat.HTML,
                    invalidIDs: getInvalidMeasurementIDs(FeatureCode.COMMON.HTML),
                });
                break;
            case 'FDL':
                items.push(
                    {
                        type: ExportFormat.RAW,
                        invalidIDs: getInvalidMeasurementIDs(FeatureCode.FDL.RAW),
                    },
                    {
                        type: ExportFormat.HTML,
                        invalidIDs: getInvalidMeasurementIDs(FeatureCode.COMMON.HTML),
                    }
                );
                break;

            /**
             * Bluetooth family
             */

            case 'PROFOMETER': {
                items.push(
                    {
                        type: ExportFormat.HTML,
                        invalidIDs: getInvalidMeasurementIDs(FeatureCode.COMMON.HTML),
                    },
                    {
                        type: ExportFormat.CSV,
                        invalidIDs: getInvalidMeasurementIDs(FeatureCode.COMMON.CSV_EXPORT),
                    },
                    {
                        type: ExportFormat.DXF,
                        invalidIDs: getInvalidMeasurementIDs(FeatureCode.PROFOMETER.DXF),
                        active: allowPMDxfExport,
                    }
                );
                break;
            }

            case 'PIT_IE':
                items.push(
                    {
                        type: ExportFormat.HTML,
                        invalidIDs: getInvalidMeasurementIDs(FeatureCode.COMMON.HTML),
                    },
                    {
                        type: ExportFormat.CSV,
                        invalidIDs: getInvalidMeasurementIDs(),
                    }
                );
                break;

            case 'EQUOTIP':
                items.push(
                    {
                        type: ExportFormat.CSV,
                        invalidIDs: getInvalidMeasurementIDs(),
                    },
                    {
                        type: ExportFormat.PDF,
                        invalidIDs: getInvalidMeasurementIDs(),
                    }
                );

                break;

            // GLM and SCHMIDT are lifetime contract
            case 'GLM':
                items.push(
                    { type: ExportFormat.CSV, invalidIDs: demoMeasurementIDs },
                    { type: ExportFormat.PDF, invalidIDs: demoMeasurementIDs }
                );
                break;

            case 'SCHMIDT':
                items.push(
                    { type: ExportFormat.CSV, invalidIDs: demoMeasurementIDs },
                    { type: ExportFormat.PDF, invalidIDs: demoMeasurementIDs }
                );
                if (!isVerification) {
                    items.push(
                        {
                            type: PDFExtendedFileType.Uniformity,
                            invalidIDs: demoMeasurementIDs,
                            active: demoMeasurementIDs.length === 0 && showSchmidtSpecialReports,
                        },
                        {
                            type: PDFExtendedFileType.Europe,
                            invalidIDs: demoMeasurementIDs,
                            active: demoMeasurementIDs.length === 0 && showSchmidtSpecialReports,
                        },
                        {
                            type: PDFExtendedFileType.China,
                            invalidIDs: demoMeasurementIDs,
                            active:
                                demoMeasurementIDs.length === 0 && showSchmidtSpecialReports && showSchmidtJGJExport,
                        }
                    );
                }
                break;
        }

        if (enableJsonDownload) {
            items.push({ type: ExportFormat.JSON, invalidIDs: [], active: true });
        }

        return items;
    }, [
        product,
        enableJsonDownload,
        getInvalidMeasurementIDsMultiple,
        getInvalidMeasurementIDs,
        missingGPSDataIds,
        showPunditRawData,
        demoMeasurementIDs,
        isVerification,
        allowPMDxfExport,
        showSchmidtSpecialReports,
        showSchmidtJGJExport,
    ]);

    return (
        <MeasurementExportContext.Provider
            value={{
                exportConfig,
                setExportConfig,
                exportOptions,
                isVerification,
                isLongRunningJobEnable,
            }}
        >
            {children}
            <MeasurementExportModal page={page} />
        </MeasurementExportContext.Provider>
    );
};

export default MeasurementExportProvider;
