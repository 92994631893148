const config = {
    APP_VERSION: process.env.APP_VERSION,
    APP_ENV: process.env.APP_ENV,
    NODE_ENV: process.env.NODE_ENV,
    SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
    EAGLE_ID_DOMAIN: process.env.REACT_APP_EAGLE_ID_DOMAIN,
    EAGLE_ID_API_VERSION: process.env.REACT_APP_EAGLE_ID_API_VERSION,
    INSPECT_DOMAIN: process.env.REACT_APP_INSPECT_DOMAIN,
    EAGLE_CLOUD_DOMAIN: process.env.REACT_APP_EAGLE_CLOUD_DOMAIN,
    EAGLE_ID_CLIENT_ID: process.env.REACT_APP_EAGLE_ID_CLIENT_ID,
    PROCEQ_DOMAIN: process.env.REACT_APP_PROCEQ_DOMAIN,
    PROCEQ_MANAGEMENT_DOMAIN: process.env.REACT_APP_PROCEQ_MANAGEMENT_DOMAIN,
    INSIGHTS_DOMAIN_PATTERN: process.env.REACT_APP_INSIGHTS_DOMAIN_PATTERN,
    CHINA_DOMAINS: process.env.REACT_APP_CHINA_DOMAINS?.split(','),

    WORKSPACE_FIREBASE_API_KEY: process.env.REACT_APP_WORKSPACE_FIREBASE_API_KEY,
    WORKSPACE_FIREBASE_PROJECT_ID: process.env.REACT_APP_WORKSPACE_FIREBASE_PROJECT_ID,
    WORKSPACE_FIREBASE_SENDER_ID: process.env.REACT_APP_WORKSPACE_FIREBASE_SENDER_ID,
    WORKSPACE_FIREBASE_APP_ID: process.env.REACT_APP_WORKSPACE_FIREBASE_APP_ID,
    WORKSPACE_FIREBASE_MEASUREMENT_ID: process.env.REACT_APP_WORKSPACE_FIREBASE_MEASUREMENT_ID,

    ONE_SIGNAL_APP_ID: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
    ONE_SIGNAL_SAFARI_ID: process.env.REACT_APP_ONE_SIGNAL_SAFARI_ID,

    INSIGHTS_FIREBASE_API_KEY: process.env.REACT_APP_INSIGHTS_FIREBASE_API_KEY,
    INSIGHTS_FIREBASE_PROJECT_ID: process.env.REACT_APP_INSIGHTS_FIREBASE_PROJECT_ID,
    INSIGHTS_FIREBASE_SENDER_ID: process.env.REACT_APP_INSIGHTS_FIREBASE_SENDER_ID,
    INSIGHTS_FIREBASE_APP_ID: process.env.REACT_APP_INSIGHTS_FIREBASE_APP_ID,
    INSIGHTS_FIREBASE_MEASUREMENT_ID: process.env.REACT_APP_INSIGHTS_FIREBASE_MEASUREMENT_ID,

    GM_URL_SCHEME: process.env.REACT_APP_GM_URL_SCHEME,
};

export default config;
