import React, { useMemo } from 'react';
import styles from './StandaloneMeasurementView.styl';
import FormattedMessage from '../../localization/FormatMessage';
import config from '../../config';
import { getLabelFromGlobalDataKey } from '../DataView/RegisteredInfo/helper';
import { ProductCode } from '../../types/proceq';

interface ViewerWrapperProps {
    productCode: ProductCode;
    title?: string;
}

const ViewerWrapper: React.FunctionComponent<ViewerWrapperProps> = (props) => {
    const { productCode, title, children } = props;

    const appTitle = useMemo(() => getLabelFromGlobalDataKey('availproductsfull', productCode), [productCode]);

    return (
        <div className={styles.measurement_container}>
            <div className="data-view-html">
                <div className={styles.header}>
                    <h1>{title}</h1>
                    <div className={styles.export_info}>
                        <div>{appTitle}</div>
                        <div>
                            <FormattedMessage id="Export HTML Format Version" />
                            {':'}
                            {config.APP_VERSION}
                        </div>
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
};

export default ViewerWrapper;
