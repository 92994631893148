import React, { useEffect, useMemo, useState } from 'react';
import { Button, message } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import FormattedMessage from '../../../localization/FormatMessage';
import StyledModal from '../../shared/StyledModal';
import styles from './MoveModal.styl';
import { ReactComponent as PlusIcon } from '../../../images/iconPlus.svg';
import { useMeasurements, useProductData } from '../../../hooks/useProductData';
import { useDataViewContext } from '../DataViewProvider';
import { moveMeasurement } from '../../../api/measurementService';
import MultipleFailureMessage from '../../shared/MultipleFailureMessage';
import { getMeasurementFolders } from '../../../api/folderService';
import analytics from '../../../analytics/firebaseAnalytics';
import { FileManagementCategory } from '../../../analytics/analyticsConstants';

interface MoveModalProps {
    visible: boolean;
    onCancel: () => void;
    addNewFolder: () => void;
    measurementIDs: string[];
}

const MoveModal: React.FunctionComponent<MoveModalProps> = (props) => {
    const { visible, onCancel, addNewFolder, measurementIDs } = props;
    const [isLoading, setIsLoading] = useState(false);
    const { product, activeFolder, fetchMeasurementList, withUnsynced, setSelectedKeys } = useDataViewContext();
    const measurements = useMeasurements();
    const { folders = {}, userFolderIDs = [] } = useProductData(product);
    const [selectedFolderID, setSelectedFolderID] = useState<string | undefined>();

    const availableFolderIDs = useMemo(
        () =>
            userFolderIDs
                .filter((id) => id !== activeFolder)
                .sort((a, b) => folders[a].name.localeCompare(folders[b].name)),
        [activeFolder, folders, userFolderIDs]
    );

    const handleClose = () => {
        onCancel();
        analytics.logFileManagement(FileManagementCategory.moveCancel, product ?? '');
    };

    useEffect(() => {
        if (!visible) {
            setIsLoading(false);
        }
    }, [visible]);

    useEffect(() => {
        if (availableFolderIDs) {
            setSelectedFolderID(availableFolderIDs[0]);
        }
    }, [availableFolderIDs]);

    const handleMove = async () => {
        setIsLoading(true);
        if (product && selectedFolderID) {
            const itemsParam = measurementIDs.map((id) => {
                return { id, to: selectedFolderID, from: measurements[id].measurement.fID };
            });
            const response = await moveMeasurement({ product, items: itemsParam });
            if (response.failed.length > 0) {
                const failureReasons = response.failed.map((failure) => {
                    return {
                        ...failure,
                        name:
                            measurements && measurements[failure.id]
                                ? measurements[failure.id].measurement.name
                                : failure.id,
                    };
                });
                message.error(<MultipleFailureMessage title="DataView.Move.Failure" failures={failureReasons} />);
            }
            await Promise.allSettled([fetchMeasurementList(true), getMeasurementFolders({ product, withUnsynced })]);
        }
        setSelectedKeys([]);
        onCancel();
        analytics.logFileManagement(FileManagementCategory.move, product ?? '');
    };

    return (
        <StyledModal
            title={<FormattedMessage id="DataView.Measurement.Move" values={{ count: measurementIDs.length }} />}
            open={visible}
            onCancel={handleClose}
            footer={[
                <React.Fragment key="folderModalFooter">
                    <Button type="primary" ghost onClick={handleClose}>
                        <FormattedMessage id="App.Cancel" />
                    </Button>
                    <Button
                        className={styles.move_button}
                        type="primary"
                        onClick={handleMove}
                        disabled={selectedFolderID === undefined}
                        loading={isLoading}
                    >
                        <FormattedMessage id="App.Move" />
                    </Button>
                </React.Fragment>,
            ]}
        >
            <div>
                <div className={styles.folder_container}>
                    {availableFolderIDs.map((folderID) => (
                        <div
                            className={styles.folder_cell}
                            key={folderID}
                            onClick={() => setSelectedFolderID(folderID)}
                        >
                            <div className={styles.check_outline}>
                                {folderID === selectedFolderID && <CheckOutlined />}
                            </div>
                            <div className={styles.ellipses_text}>{folders[folderID].name}</div>
                        </div>
                    ))}
                </div>
                <Button
                    type="link"
                    onClick={() => {
                        addNewFolder();
                        analytics.logFileManagement(FileManagementCategory.moveAddNewFolder, product ?? '');
                    }}
                    className={styles.add_folder}
                >
                    <PlusIcon />
                    <FormattedMessage id="App.NewFolder" />
                </Button>
            </div>
        </StyledModal>
    );
};

export default MoveModal;
