import { get } from 'lodash';
import { MeasurementFullData } from '../../../../../types/measurement';
import { renderFolderName } from '../../utils/renderFolderName';
import { FormatMessageFunc } from '../../../../../types';
import { ProductModel, ScanType } from '../../../../../types/proceq';
import {
    getConvertedSectionTableData,
    RawSectionTableData,
    SectionTableData,
} from '../../../../../utils/tableDataUtils';

const GPR_PRODUCT_MODEL_SET: Set<ProductModel> = new Set([
    ProductModel.GPR,
    ProductModel.GPR_PALM,
    ProductModel.GPR_GP8100,
]);

export const getGPRMeasurementPresets = (
    data: MeasurementFullData | undefined,
    scanType: ScanType,
    isMetric: boolean,
    convert: (value: string | number, unitId: string) => string | number,
    formatMessage: FormatMessageFunc,
    isFullList?: boolean
): SectionTableData[] => {
    if (!data) {
        return [];
    }

    const creationLog = data?.logs?.find((l) => l.type === 'creation');
    const quality = data.settings[0].content.settings.preset.quality;

    const isLineScan = data.measurement.type === 'Line Scan';
    const isAreaScan = data.measurement.type === 'Area Scan';
    const productModel: ProductModel | undefined = data.measurement.productModel?.toUpperCase() as ProductModel;
    const signalLength = data.settings[0].content.settings.device.signalLength;
    const samplingRate = data.settings[0].content.settings.device.samplingRate / (1000 * 1000 * 1000);
    let timeWindow = 0;
    const gprFftSignalLengthNS = data.settings[0].content.settings?.process?.gprFftSignalLengthNS;
    if (
        !!gprFftSignalLengthNS &&
        productModel === ProductModel.GPR &&
        quality === 'Max Depth' &&
        data.settings[0]?.content?.settings?.useFrequencyDomain
    ) {
        timeWindow = gprFftSignalLengthNS;
    } else if (samplingRate !== 0 && GPR_PRODUCT_MODEL_SET.has(productModel)) {
        timeWindow = signalLength / samplingRate;
    } else if (quality === 'Max Depth') {
        timeWindow = 20;
    } else if (quality === 'Max Speed') {
        timeWindow = 15;
    }

    const presetsRaw: RawSectionTableData[] = [
        {
            label: 'Proceq.FileName',
            value: get(data, 'measurement.name'),
            enable: !!isFullList,
        },
        {
            label: 'Proceq.FileID',
            value: get(data, 'measurement.id'),
            enable: !!isFullList,
        },
        {
            label: 'Proceq.Folder',
            value: renderFolderName(data, formatMessage),
            enable: !!isFullList,
        },
        {
            label: 'Proceq.Mode',
            value: get(data, 'measurement.type'),
        },
        {
            label: 'Proceq.ScanDirection',
            value: get(data, 'settings.0.content.settings.preset.scanModeParameters.lineScanDirection'),
            enable: isLineScan && !!isFullList,
        },
        {
            label: 'App.HTML.GPR.MeasurementPresets.GridSizeX',
            unitId: 'GPR.CSV.Grid Size - X',
            value: get(creationLog, 'content.measurementInfo.grid.gridSizeWidth'),
            enable: isAreaScan && !!isFullList,
        },
        {
            label: 'App.HTML.GPR.MeasurementPresets.GridSizeY',
            unitId: 'GPR.CSV.Grid Size - Y',
            value: get(creationLog, 'content.measurementInfo.grid.gridSizeHeight'),
            enable: isAreaScan && !!isFullList,
        },
        {
            label: 'App.HTML.GPR.MeasurementPresets.GridSpacingX',
            unitId: 'GPR.CSV.Grid Spacing',
            value:
                get(creationLog, 'content.measurementInfo.grid._gridSpacingX') ||
                get(creationLog, 'content.measurementInfo.grid.gridSpacingX') ||
                get(creationLog, 'content.measurementInfo.grid.gridSpacing'),
            enable: isAreaScan && !!isFullList,
        },
        {
            label: 'App.HTML.GPR.MeasurementPresets.GridSpacingY',
            unitId: 'GPR.CSV.Grid Spacing',
            value:
                get(creationLog, 'content.measurementInfo.grid._gridSpacingY') ||
                get(creationLog, 'content.measurementInfo.grid.gridSpacingY') ||
                get(creationLog, 'content.measurementInfo.grid.gridSpacing'),
            enable: isAreaScan && !!isFullList,
        },
        {
            label: 'Units',
            value: get(data, 'settings.0.content.settings.display.unit'),
        },
        {
            label: 'Resolution',
            value: quality,
            enable: productModel === 'GPR',
        },
        {
            label: 'App.HTML.GPR.MeasurementPresets.RepetitionRate',
            unitId: 'GPR.CSV.Repetition Rate',
            value: get(creationLog, 'content.measurementInfo.repetitionRate'),
        },
        {
            label: 'App.HTML.GPR.MeasurementPresets.ScanLength',
            unitId: 'GPR.CSV.Scan length',
            value: signalLength,
            enable: !!isFullList,
        },
        {
            label: 'App.HTML.GPR.MeasurementPresets.TimeWindow',
            unitId: 'GPR.CSV.Time window',
            value: timeWindow,
            enable: !!isFullList,
        },
        {
            label: 'App.HTML.GPR.MeasurementPresets.SamplingRate',
            unitId: 'GPR.CSV.Sampling rate',
            value: samplingRate,
            enable: !!isFullList,
        },
        {
            label: 'App.HTML.GPR.MeasurementPresets.AntennaSpacing',
            unitId: 'GPR.CSV.Antenna spacing',
            value: get(data, 'settings.0.content.settings.device.antennaSpacing'),
            enable: !!isFullList,
        },
    ];

    return getConvertedSectionTableData(presetsRaw, scanType, isMetric);
};
