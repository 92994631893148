import React from 'react';
import { Button } from 'antd';
import FormattedMessage from '../../localization/FormatMessage';
import styles from './DataPrivacy.styl';
import { ReactComponent as EagleIDLogo } from '../../images/eagleWorkspaceVertical.svg';
import { DATA_PRIVACY_URL } from '../Profile/constants';

interface DataPrivacyProps {
    onClick: () => void;
}

const DataPrivacy: React.FunctionComponent<DataPrivacyProps> = (props) => {
    const { onClick } = props;
    return (
        <div className={styles.container}>
            <div className={styles.content_container}>
                {EagleIDLogo && <EagleIDLogo />}
                <div className={styles.content_wrapper}>
                    <div className={styles.body1}>
                        <FormattedMessage
                            id="DataPrivacy.Details"
                            values={{
                                dataPrivacy: (
                                    <a href={DATA_PRIVACY_URL} rel="noreferrer" target="_blank">
                                        <FormattedMessage id="DataPrivacy.DataPrivacy" />
                                    </a>
                                ),
                            }}
                        />
                    </div>
                    <Button type="primary" onClick={onClick} style={{ width: '100%', marginTop: '72px' }}>
                        <FormattedMessage id="DataPrivacy.Agree" />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default DataPrivacy;
