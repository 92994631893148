export enum CurrencyCode {
    CHF = 'CHF',
    USD = 'USD',
    EUR = 'EUR',
    SGD = 'SGD',
    RMB = 'RMB',
    RUB = 'RUB',
    AED = 'AED',
    BRL = 'BRL',
    GBP = 'GBP',
}

export interface LicensePrice {
    currency: string;
    currencyCode: CurrencyCode;
    monthlyPrice: number;
    yearlyPrice: number;
    yearlyDiscountedPrice?: number;
}

export interface LicenseTier {
    tier: string;
    name: string;
    description: string;
    features: LicenseFeature[];
    free: boolean;
    discount?: number;
    discountDescription?: string;
    pricing?: LicensePrice[];
    upgradable: boolean;
}

export interface LicenseFeature {
    code: string;
    description: string;
}
export interface UserLicenseInfo {
    duration: number;
    activationDate: number;
    expirationDate: number;
    active: boolean;
    userID: number;
    tier: LicenseTier;
    created: number;
    updated: number;
    product: LicenseProduct;
}

export enum LicenseProduct {
    inspect = 'INSPECT',
    gpr_insights = 'GPR_INSIGHTS',
}

export const LicenseProductNameMap = {
    [LicenseProduct.inspect]: 'Inspect',
    [LicenseProduct.gpr_insights]: 'GPR Insights',
};

export enum UserLicenseErrorCode {
    LicenseKeyInvalid = 'LicenseKeyInvalid',
    LicenseKeyAlreadyInUse = 'LicenseKeyAlreadyInUse',
    LicenseKeyActivationFailed = 'LicenseKeyActivationFailed',
    ItemNotFound = 'ItemNotFound',
}

export enum ActivateLicenseProduct {
    inspect = 'Inspect',
    gprInsights = 'GPRInsights',
}

export interface UserLicenseParams {
    product?: ActivateLicenseProduct;
    licenseKey?: string;
}

export const LicenseParamProductMap = {
    [ActivateLicenseProduct.inspect]: LicenseProduct.inspect,
    [ActivateLicenseProduct.gprInsights]: LicenseProduct.gpr_insights,
};
