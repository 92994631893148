import React, { useEffect } from 'react';
import { Dropdown, message } from 'antd';
import { useIntl } from 'react-intl';
import { FormatIDs } from '../../types';
import FormattedMessage from '../../localization/FormatMessage';
import styles from './DataView.styl';
import { useDataViewContext } from './DataViewProvider';
import { DataViewMode, NonEditableType } from './DataViewContext';
import { useRoleState } from '../../hooks/useCurrentUser';
import AnalyticsButton from '../AnalyticsComponents/Button';
import analytics from '../../analytics/firebaseAnalytics';
import { MeasurementMenuCategory } from '../../analytics/analyticsConstants';
import { BluetoothFileType, CustomCurveFileType, MeasurementListItem, SystemFolderID } from '../../types/measurement';
import { CustomMaterialItem } from '../../types/customMaterial';
import { getMeasurementFolders } from '../../api/folderService';
import { archiveRestoreMeasurements, deleteRestoreMeasurements } from '../../api/measurementService';
import MultipleFailureMessage from '../shared/MultipleFailureMessage';
import { AntdMenuItem } from '../../types/antd';
import { useMeasurement } from '../../hooks/useProductData';
import { useSelectedContractFeatures } from '../../hooks/useContract';
import { ProductFeature, useProductContext } from './ProductContextProvider';
import { FeatureCode } from '../../types/contract';
import { ViewType } from '../../types/measurement';
import { ReactComponent as LogbookIcon } from '../../images/iconLogbook.svg';
import { ReactComponent as EllipsesIcon } from '../../images/iconEllipses.svg';
import { useFormatMessage } from '../../localization/useFormatMessage';
import Tooltip from '../shared/Tooltip';
import useInvalidMeasurements from '../../hooks/useInvalidMeasurements';
import { useMeasurementModalContext } from './MeasurementModals/MeasurementModalProvider';

interface MeasurementActionMenuProps {
    measurement: MeasurementListItem | CustomMaterialItem;
    fileType: string;
    keysAllowed?: Set<string>;
    openRowDropdownID?: string;
    setOpenRowDropdownID?: (openDropdown: string | undefined) => void;
    setSelectedKeys?: React.Dispatch<React.SetStateAction<string[]>>;
}

const MeasurementActionMenu: React.FunctionComponent<MeasurementActionMenuProps> = (props) => {
    const { measurement, fileType, keysAllowed, openRowDropdownID, setOpenRowDropdownID, setSelectedKeys } = props;

    const roleState = useRoleState();
    const formatMessage = useIntl().formatMessage;
    const measurementID = measurement.id;
    const { isFeatureEnabled } = useProductContext();
    const isArchiveEnabled = isFeatureEnabled(ProductFeature.ARCHIVE_MEASUREMENTS);

    const {
        setShowDeleteConfirmationModal,
        setShowShareMeasurement,
        setShowCreateFolder,
        setShowMoveMeasurement,
        setMeasurementID,
        setMeasurementName,
    } = useMeasurementModalContext();

    const {
        setModalViewerConfig,
        fetchMeasurementList,
        activeFolder,
        viewType,
        product,
        withUnsynced,
        setNonEditableMeasurements,
        productModel,
    } = useDataViewContext();
    const isArchiveViewType = viewType === ViewType.Archived;

    const setMeasurementDetails = () => {
        setMeasurementID(measurementID);
        setMeasurementName(measurement.name);
    };

    useEffect(() => {
        return () => {
            setShowCreateFolder(false);
            setShowMoveMeasurement(false);
            setMeasurementID(undefined);
            setMeasurementName(undefined);
        };
    }, [setMeasurementID, setMeasurementName, setShowCreateFolder, setShowMoveMeasurement]);

    const measurementTest = useMeasurement(measurementID);
    const { getInvalidMeasurementIDs, demoMeasurementIDs } = useSelectedContractFeatures(
        { [measurementID]: measurementTest },
        [measurementID]
    );
    const { nonEditableMeasurements, nonEditableExcludeDemo } = useInvalidMeasurements({
        invalidMeasurementIDs: getInvalidMeasurementIDs(),
        demoMeasurementIDs,
        measurementID,
    });

    const invalidShareMeasurement = getInvalidMeasurementIDs(
        FeatureCode.COMMON.SHARE_URL,
        productModel,
        product
    ).includes(measurementID);

    const removeMeasurementFromSelectedKeys = () => {
        setSelectedKeys?.((prevState) => {
            if (prevState.includes(measurementID)) {
                return prevState.filter((key) => key !== measurementID);
            }
            return prevState;
        });
    };

    const menuItems: {
        key: string;
        label: FormatIDs;
        onClick?: () => void;
        enabled?: boolean;
        isDanger?: boolean;
    }[] = [
        {
            key: 'restore',
            label: 'DataView.Measurement.Restore',
            onClick: async () => {
                analytics.logMeasurementMenu(MeasurementMenuCategory.restore);
                if (nonEditableExcludeDemo) {
                    setNonEditableMeasurements(nonEditableMeasurements);
                    analytics.logNonEditableMeasurement(MeasurementMenuCategory.restore, product ?? '');
                } else if (product) {
                    const response = await deleteRestoreMeasurements({
                        ids: [measurementID],
                        product,
                        isDelete: false,
                    });
                    if (response.failed.length > 0) {
                        message.error(formatMessage({ id: 'DataView.Measurement.Restore.Failure' }));
                    } else {
                        message.success(
                            formatMessage(
                                { id: 'DataView.Measurement.Restore.Success' },
                                { name: measurement.name, folder: response.passed[0].additionalInfo }
                            )
                        );
                    }
                    fetchMeasurementList(true);
                    getMeasurementFolders({ product, withUnsynced, archived: isArchiveViewType });
                    removeMeasurementFromSelectedKeys();
                }
            },
            enabled: activeFolder === SystemFolderID.Trashed,
        },
        {
            key: 'share',
            label: 'App.Share',
            onClick: async () => {
                analytics.logMeasurementMenu(MeasurementMenuCategory.share);
                setShowShareMeasurement(true);
                setMeasurementDetails();
            },
            enabled:
                !(
                    measurement.isDemoFile ||
                    fileType === BluetoothFileType.CustomMaterial ||
                    fileType === BluetoothFileType.VerificationData ||
                    activeFolder === SystemFolderID.Trashed
                ) &&
                (fileType === CustomCurveFileType.dgscc || !invalidShareMeasurement) &&
                !!product,
        },
        {
            key: 'archive',
            label: 'App.Archive',
            onClick: async () => {
                analytics.logMeasurementMenu(MeasurementMenuCategory.archive);
                if (nonEditableExcludeDemo) {
                    setNonEditableMeasurements(nonEditableMeasurements);
                    analytics.logNonEditableMeasurement(MeasurementMenuCategory.archive, product ?? '');
                } else if (product) {
                    await archiveRestoreMeasurements({
                        ids: [measurementID],
                        product,
                        isArchive: true,
                        errorCallback: () =>
                            message.error(formatMessage({ id: 'DataView.Archive.Failure' }, { count: 1 })),
                    });
                    message.success(
                        formatMessage(
                            { id: 'DataView.Measurement.Archive.Success' },
                            { name: measurement.name, count: 1 }
                        )
                    );
                    fetchMeasurementList(true);
                    getMeasurementFolders({ product, withUnsynced });
                    removeMeasurementFromSelectedKeys();
                }
            },
            enabled:
                isArchiveEnabled &&
                !(
                    fileType === CustomCurveFileType.dgscc ||
                    measurement.isDemoFile ||
                    fileType === BluetoothFileType.CustomMaterial ||
                    fileType === BluetoothFileType.VerificationData ||
                    activeFolder === SystemFolderID.Trashed
                ),
        },
        {
            key: 'move',
            label: 'App.Move',
            onClick: () => {
                analytics.logMeasurementMenu(MeasurementMenuCategory.move);
                if (nonEditableMeasurements) {
                    setNonEditableMeasurements({ ...nonEditableMeasurements, nonEditableType: NonEditableType.move });
                    analytics.logNonEditableMeasurement(MeasurementMenuCategory.move, product ?? '');
                } else {
                    setShowMoveMeasurement(true);
                    setMeasurementDetails();
                }
            },
            enabled: !(
                fileType === CustomCurveFileType.dgscc ||
                fileType === BluetoothFileType.VerificationData ||
                fileType === BluetoothFileType.CustomMaterial ||
                measurement.isDemoFile ||
                activeFolder === SystemFolderID.Trashed
            ),
        },
        {
            key: 'attachments',
            label: 'Attachments',
            onClick: () => {
                analytics.logMeasurementMenu(MeasurementMenuCategory.attachments);
                setModalViewerConfig({ viewMode: DataViewMode.Attachments, measurementID });
            },
            enabled:
                roleState.isDev &&
                fileType !== BluetoothFileType.CustomMaterial &&
                activeFolder !== SystemFolderID.Trashed,
        },
        {
            key: 'json',
            label: 'JSON',
            onClick: () => {
                analytics.logMeasurementMenu(MeasurementMenuCategory.json);
                if (fileType === BluetoothFileType.CustomMaterial) {
                    setModalViewerConfig({ viewMode: DataViewMode.JSON, customMaterialID: measurementID });
                } else {
                    setModalViewerConfig({ viewMode: DataViewMode.JSON, measurementID });
                }
            },
            enabled: roleState.isDev,
        },
        {
            key: 'delete',
            label: 'App.Delete',
            isDanger: true,
            onClick: async () => {
                analytics.logMeasurementMenu(MeasurementMenuCategory.delete);
                if (nonEditableExcludeDemo) {
                    setNonEditableMeasurements(nonEditableMeasurements);
                    analytics.logNonEditableMeasurement(MeasurementMenuCategory.delete, product ?? '');
                } else if (product) {
                    const response = await deleteRestoreMeasurements({ ids: [measurementID], product, isDelete: true });
                    if (response.failed.length > 0) {
                        message.error(
                            <MultipleFailureMessage
                                title="DataView.Delete.Failure"
                                failures={[{ ...response.failed[0], name: measurement.name }]}
                            />
                        );
                    } else {
                        message.success(
                            formatMessage(
                                { id: 'DataView.Measurement.Delete.Success' },
                                { name: measurement.name, count: 1 }
                            )
                        );
                        removeMeasurementFromSelectedKeys();
                    }
                    fetchMeasurementList(true);
                    getMeasurementFolders({ product, withUnsynced });
                }
            },
            enabled: !(
                fileType === CustomCurveFileType.dgscc ||
                fileType === BluetoothFileType.CustomMaterial ||
                fileType === BluetoothFileType.VerificationData ||
                activeFolder === SystemFolderID.Trashed
            ),
        },
        {
            key: 'permanentDelete',
            label: 'DataView.Measurement.Delete.Permanently',
            isDanger: true,
            onClick: () => {
                analytics.logMeasurementMenu(MeasurementMenuCategory.deletePermanently);
                setShowDeleteConfirmationModal(true);
                setMeasurementDetails();
            },
            enabled:
                fileType === CustomCurveFileType.dgscc ||
                fileType === BluetoothFileType.VerificationData ||
                activeFolder === SystemFolderID.Trashed,
        },
    ];

    const filteredMenuItems = menuItems.filter((item) => {
        if (keysAllowed) {
            return keysAllowed.has(item.key) && item.enabled !== false;
        }
        return item.enabled !== false;
    });
    const showDropdown = measurementID && filteredMenuItems.length > 0;

    const dropdownMenuItems: AntdMenuItem[] = filteredMenuItems.reduce(
        (previousValue: AntdMenuItem[], currentValue) => {
            const isDangerItem = currentValue.isDanger;
            return [
                ...previousValue,
                ...(isDangerItem && previousValue.length > 0
                    ? [{ type: 'divider', className: styles.dropdown_divider } as AntdMenuItem]
                    : []),
                {
                    key: currentValue.key,
                    onClick: (e) => {
                        // stop propagation so that it doesn't trigger the table's 'on row click' event
                        e.domEvent.stopPropagation();
                        currentValue.onClick?.();
                    },
                    label: <FormattedMessage id={currentValue.label} />,
                    className: isDangerItem ? styles.danger_dropdown_item : undefined,
                } as AntdMenuItem,
            ];
        },
        []
    );

    return showDropdown ? (
        <>
            <Dropdown
                open={openRowDropdownID ? openRowDropdownID === measurementID : undefined}
                menu={{ items: dropdownMenuItems }}
                trigger={['click']}
                onOpenChange={() => setOpenRowDropdownID?.(undefined)}
            >
                <AnalyticsButton
                    type="link"
                    className={styles.action_icon}
                    onClick={(e) => {
                        e.stopPropagation();
                        analytics.logMeasurementMenu(MeasurementMenuCategory.more);
                    }}
                >
                    <Tooltip placement="top" title={formatMessage({ id: 'DataView.MoreActions' })}>
                        <EllipsesIcon />
                    </Tooltip>
                </AnalyticsButton>
            </Dropdown>
        </>
    ) : null;
};

export const LogbookButton: React.FunctionComponent<{ measurementID: string; onClick: Function }> = (props) => {
    const { measurementID, onClick } = props;
    const { setModalViewerConfig } = useDataViewContext();
    const formatMessage = useFormatMessage();

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setModalViewerConfig({ viewMode: DataViewMode.Logbook, measurementID });
        onClick();
    };
    return (
        <AnalyticsButton onClick={handleClick} type="link" className={styles.action_icon}>
            <Tooltip placement="top" title={formatMessage({ id: 'DataView.Modal.Title.Logbook' })}>
                <LogbookIcon />
            </Tooltip>
        </AnalyticsButton>
    );
};

export default MeasurementActionMenu;
