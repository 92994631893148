import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import routeURLs from '../Routes/urls';
import { useCurrentUserID } from '../../hooks/useCurrentUser';
import styles from './App.styl';
import { RootState } from '../../reducers';
import { proceqURL } from '../../api/RegionalAPI';
import * as landingUtils from '../../utils/landingUtils';
import { handleError } from '../../api/error';
import * as authUtils from '../../utils/authUtils';
import 'apple-mapkit-js';
import DataPrivacy from '../DataPrivacy';
import { getLocalItem, updateReadyUsers } from '../../utils/storageUtils';
import analytics from '../../analytics/firebaseAnalytics';
import useImportMeasurementDataPolling from '../DataView/Import/useImportMeasurementPolling';
import ImportMeasurementContext from '../DataView/Import/ImportMeasurementContext';
import { logDeviceType } from '../../api/analyticsEventService';
import ProductContextProvider from '../DataView/ProductContextProvider';
import { ProductCode } from '../../types/proceq';
import { useEagleIDProfile, useProceqUser } from '../../queries/userQueries';
import { useProbesList } from '../../queries/probeQueries';
import { useProceqAppConfig } from '../../queries/utilsQueries';
import { useActiveApps } from '../../queries/appQueries';
import { useNotifications } from '../../queries/notificationQueries';

const App: React.FunctionComponent = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const userID = useCurrentUserID();
    const [isReady, setIsReady] = useState(() => {
        if (!userID) return false;
        const localStorageReadyUsers = getLocalItem('readyUsers') ?? '';
        const userIDs = localStorageReadyUsers.split(',');
        return userIDs.includes(userID.toString());
    });

    const regionInfo = useSelector((state: RootState) => state.profile.regionInfo);

    useEffect(() => {
        if (isReady) {
            logDeviceType('app_launch');
        }
    }, [isReady]);

    const errorHandler = (error: any) => {
        // If received permission denied when loading profile,
        // this means that the user has been changed by SSO.
        // We will need to kill the current session and reauthenticate
        if (error.response?.status === 403) {
            authUtils.cleanUpCredentials(dispatch);
            history?.push(routeURLs.AUTH);
        } else {
            handleError(error);
        }
    };

    const { data } = useEagleIDProfile(userID, errorHandler);
    useProceqUser(userID, true);
    useProbesList(!!userID);
    useProceqAppConfig(ProductCode.GPR, !!userID);
    useActiveApps(!!userID);
    useNotifications({ sync: false, idx: 0 }, !!userID);

    useEffect(() => {
        if (data) {
            analytics.setUserProperty(data.data.email);
        }
    }, [data]);

    useLayoutEffect(() => {
        landingUtils.saveLandingPath();
    }, []);

    useLayoutEffect(() => {
        if (userID) {
            proceqURL.init({
                region: regionInfo.pqRegion,
                regionalDomain: regionInfo.pqRegionalDomain,
                managementDomain: regionInfo.pqManagementDomain,
            });

            // Root route will handle the landing.
            // We need to clean up the landing path if URL was keyed by user directly.
            if (history.location.pathname !== '/') {
                landingUtils.restoreLandingPath();
            }
        } else {
            history.push(routeURLs.AUTH);
        }
    }, [userID, history, regionInfo]);

    // Data polling to periodically check if import is completed
    const { viewImportMeasurementsHandler, setViewImportMeasurementsHandler } = useImportMeasurementDataPolling();

    const handleDataPrivacyClick = () => {
        if (!userID) return;
        updateReadyUsers(userID.toString());
        setIsReady(true);
    };

    return (
        <div className={styles.app_root}>
            {!userID ? null : isReady ? (
                <ProductContextProvider>
                    <ImportMeasurementContext.Provider
                        value={{
                            viewFilesHandler: viewImportMeasurementsHandler,
                            setViewFilesHandler: setViewImportMeasurementsHandler,
                        }}
                    >
                        {props.children}
                    </ImportMeasurementContext.Provider>
                </ProductContextProvider>
            ) : (
                <DataPrivacy onClick={handleDataPrivacyClick} />
            )}
        </div>
    );
};

export default App;
