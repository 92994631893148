import React from 'react';
import SectionTable from '../../shared/SectionTable';
import styles from '../../DataViewers.styl';
import { MeasurementFullData } from '../../../../../types/measurement';
import { DerivedProps } from '../../SharedTypes';
import { ScanType } from '../../../../../types/proceq';
import { SectionTableData } from '../../../../../utils/tableDataUtils';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { title as scanDistanceTitle } from '../GPR/ScanDistance';
import { LineScanDetails } from './scanDetailUtils';

const getScanData = (
    data: MeasurementFullData | undefined,
    scanType: ScanType,
    isMetric: boolean,
    convert: (value: string | number, unitId: string) => string | number,
    lineScanDetails: LineScanDetails
) => {
    if (!data) {
        return [];
    }
    const { lineScanStatus } = data.settings[0].content.areaScanStatus || {};

    const sourceData: SectionTableData[] = lineScanStatus.map((lineScan: any) => {
        const { aScanID } = lineScan;
        const sequenceNo = lineScanDetails[aScanID].sequenceNo;
        return {
            label: <FormattedMessage id="App.HTML.Profometer.ScanDistance.Line" values={{ lineNo: sequenceNo }} />,
            key: sequenceNo,
            value: convert(lineScan.scanDistance, 'PROFOMETER.CSV.Scan distance'),
        };
    });
    return sourceData;
};

const ScanDistance: React.FunctionComponent<
    { data: MeasurementFullData } & DerivedProps & { lineScanDetails: LineScanDetails }
> = (props) => {
    const { showTitle, data, scanType, convert, isMetric, lineScanDetails } = props;
    const dataSource = getScanData(data, scanType, isMetric, convert, lineScanDetails);

    return (
        <SectionTable
            showTitle={showTitle}
            className={styles.table}
            title={<span className={styles.sub_header}>{scanDistanceTitle(props)}</span>}
            dataSource={dataSource}
        />
    );
};

export default ScanDistance;
