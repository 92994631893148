import React from 'react';
import Marker from '@uiw/react-baidu-map-marker';
import Map from '@uiw/react-baidu-map-map';
import APILoader from '@uiw/react-baidu-map-api-loader';
import { StaticMapProps } from './index';
import { BAIDU_MAP_KEY } from '../../../../../constants';
import { useMeasurementViewerContext } from '../../../../StandaloneMeasurementView/MeasurementViewerContext';

const ChinaStaticMap: React.FunctionComponent<StaticMapProps> = (props) => {
    const { coordinates, width, height } = props;
    const emptyLocation = coordinates.lat === 0 && coordinates.lng === 0;
    const { setViewerReady } = useMeasurementViewerContext();

    if (emptyLocation) {
        setViewerReady();
        return null;
    }

    return (
        <APILoader akay={BAIDU_MAP_KEY}>
            <div style={{ width: width ?? '100%', height: height ?? '200px', maxWidth: '100%', pointerEvents: 'none' }}>
                <Map center={{ lng: coordinates.lng, lat: coordinates.lat }} zoom={16} onTilesLoaded={setViewerReady}>
                    <Marker position={{ lng: coordinates.lng, lat: coordinates.lat }} />
                </Map>
            </div>
        </APILoader>
    );
};

export default ChinaStaticMap;
