import { w3cwebsocket as W3CWebSocket } from 'websocket';
import * as Sentry from '@sentry/browser';
import { AppstreamAwsDetails } from '../../../api/appstreamService';
import { downloadHandler } from './s3Utilities';

export enum WebsocketCloseCodes {
    'CLOSE_NORMAL' = 1000,
}

enum MessageReceivedOp {
    UPLOAD_FILE = 'upload_file',
    DOWNLOAD_FILE = 'download_file',
    HANDSHAKE = 'handshake',
    SHARE_PROJECT = 'share_project',
    HIDE_LOADING = 'hideloading',
    ANALYTICS = 'analytics',
}

export enum MessageSendOp {
    HANDSHAKE_FINISHED = 'handshake_finished',
    DOWNLOAD_FINISHED = 'download_finished',
    DOWNLOAD_FAILED = 'download_failed',
    SHARE_FINISHED = 'share_finished',
    UPLOAD_PROGRESS = 'upload_progress',
}

type WebSocketFactoryProps = AppstreamAwsDetails & {
    showModal: () => void;
    reconnectSocket: () => void;
    resetHandshakeTimeout: () => void;
    appstreamErrorHandler: () => void;
    shareProjectCallback: (text: string) => void;
    hideLoadingVideo: () => void;
    logWebsocketAnalytics: (event: string, appVersion: string, params?: { [key: string]: any }) => void;
};

export const SOCKET_SEND_ACTION = 'sendmessage';

type GenerateMessageType = {
    action: string;
    to: string;
    message: {};
};

export const generateResponse = (obj: GenerateMessageType) => {
    return JSON.stringify({
        action: obj.action,
        to: obj.to,
        message: JSON.stringify(obj.message),
    });
};

const getJsonParse = (message: any) => {
    try {
        return JSON.parse(message as string);
    } catch {
        // eslint-disable-next-line no-console
        console.error('Error parsing websocket message: ', message);
    }
    return {};
};

export function websocketsFactory({
    websocketsUrl,
    s3,
    userId,
    eagleId,
    frontendBucket,
    showModal,
    reconnectSocket,
    appstreamErrorHandler,
    shareProjectCallback,
    hideLoadingVideo,
    logWebsocketAnalytics,
    resetHandshakeTimeout,
}: WebSocketFactoryProps) {
    const socket = new W3CWebSocket(websocketsUrl);
    socket.binaryType = 'arraybuffer';

    let intervalID: number;
    const sendToDetails = `${eagleId}_eaglepro`;

    socket.onopen = () => {
        intervalID = window.setInterval(() => {
            socket.send(JSON.stringify({ action: 'ping' }));
        }, 30000);
        socket.send(JSON.stringify({ action: 'checkapp' }));
    };

    socket.onclose = (e) => {
        // eslint-disable-next-line no-console
        console.log(`Socket is closed with code "${e.code}" and reason "${e.reason}" `, e);
        if (intervalID) {
            clearInterval(intervalID);
        }
        if (!(e.code in WebsocketCloseCodes)) {
            // eslint-disable-next-line no-console
            console.log('Socket reconnection will be attempted in 1 second');
            reconnectSocket();
        }
    };

    socket.onerror = (err) => {
        // eslint-disable-next-line no-console
        console.error('Socket encountered error: ', err.message, 'Closing socket');
        Sentry.captureException(err);
        socket.close();
    };

    socket.onmessage = (event) => {
        const messageObj = getJsonParse(event.data);

        if (!(messageObj.message === 'pong' || messageObj.from === userId)) {
            const message = getJsonParse(messageObj.message);
            switch (message.op) {
                case MessageReceivedOp.ANALYTICS:
                    if (message.event) {
                        const analyticsParams = getJsonParse(message.params ?? '');
                        logWebsocketAnalytics(message.event, message.app_version ?? '', analyticsParams);
                    }
                    break;
                case MessageReceivedOp.UPLOAD_FILE:
                    showModal();
                    break;
                case MessageReceivedOp.HIDE_LOADING:
                    hideLoadingVideo();
                    break;
                case MessageReceivedOp.HANDSHAKE: {
                    resetHandshakeTimeout();
                    socket.send(
                        generateResponse({
                            action: SOCKET_SEND_ACTION,
                            to: sendToDetails,
                            message: { op: MessageSendOp.HANDSHAKE_FINISHED },
                        })
                    );
                    break;
                }
                case MessageReceivedOp.SHARE_PROJECT: {
                    shareProjectCallback(message.text);
                    socket.send(
                        generateResponse({
                            action: SOCKET_SEND_ACTION,
                            to: sendToDetails,
                            message: { op: MessageSendOp.SHARE_FINISHED },
                        })
                    );
                    break;
                }
                case MessageReceivedOp.DOWNLOAD_FILE:
                    downloadHandler({ s3, file: message.file, eagleId, frontendBucket, appstreamErrorHandler })
                        .then(() => {
                            socket.send(
                                generateResponse({
                                    action: SOCKET_SEND_ACTION,
                                    to: sendToDetails,
                                    message: { op: MessageSendOp.DOWNLOAD_FINISHED },
                                })
                            );
                        })
                        .catch((error) => {
                            const downloadError = JSON.stringify(error);
                            // eslint-disable-next-line no-console
                            console.log(`download file error: ${downloadError}`);
                            Sentry.captureException(`download file error: ${downloadError}`);
                            socket.send(
                                generateResponse({
                                    action: SOCKET_SEND_ACTION,
                                    to: sendToDetails,
                                    message: { op: MessageSendOp.DOWNLOAD_FAILED, message: error },
                                })
                            );
                        });
                    break;
                default:
                    // eslint-disable-next-line no-console
                    console.log('discarded message:', message);
            }
        }
    };

    return socket;
}
