import React, { useCallback, useEffect, useState } from 'react';
import { get, isEmpty, uniq } from 'lodash';
import GPRLogbook from './GPRLogbook';
import EquotipLogbook from './EquotipLogbook';
import Spinner from '../../../shared/Spinner';
import { useMeasurements } from '../../../../hooks/useProductData';
import { getMeasurementLogs } from '../../../../api/measurementService';
import { bluetoothProductFamilySet, ProductCode, ProductModel, wifiProductFamilySet } from '../../../../types/proceq';
import { getSharedProceqUsers } from '../../../../api/userService';
import { TimestampType, useMeasurementReloadLimiter } from '../../../../hooks/useLimiter';
import { BluetoothFileType, PROFOMETER_METRIC_UNITS } from '../../../../types/measurement';
import { useMeasurementViewerContext } from '../../../StandaloneMeasurementView/MeasurementViewerContext';
import { SingleMeasurementState } from '../../../../reducers/measurement';

interface LogbookProps {
    measurementID: string;
    product?: ProductCode;
}

const getIsMetric = (measurementData: SingleMeasurementState, product?: ProductCode) => {
    switch (product) {
        // pundit impact does not store unit therefore defaults to metric
        case ProductCode.PIT_IE:
            return true;
        case ProductCode.PROFOMETER:
            return PROFOMETER_METRIC_UNITS.has(measurementData.settings[0]?.content?.preset?.unit);
        case ProductCode.GPR:
        case ProductCode.GPR_SOIL:
            return get(measurementData, 'settings.0.content.settings.display.unit') === 'Metric';
        default:
            return get(measurementData, 'settings.0.content.display.unit') === 'Metric';
    }
};

export const Logbook: React.FunctionComponent<LogbookProps> = (props) => {
    const { measurementID, product } = props;
    const [loading, setLoading] = useState(false);

    const { isHTMLExport, isHTMLView } = useMeasurementViewerContext();

    const measurements = useMeasurements();
    const measurementData = measurements?.[measurementID];
    const version = measurementData?.measurement.content?.info?.version;

    const logs = measurementData?.logs;
    const isVerificationMode = get(measurementData, 'measurement.type') === BluetoothFileType.VerificationData;

    const fetchLogs = useCallback(async () => {
        if (product && measurementID && !isHTMLExport && !isHTMLView) {
            setLoading(true);
            try {
                await getMeasurementLogs({ measurementID });
            } finally {
                setLoading(false);
            }
        }
    }, [measurementID, product, isHTMLExport, isHTMLView]);

    const [highlight, setHighlight] = useState<Set<string>>(new Set());
    const toggleHighlight = useCallback((id: string, isHighlighted: boolean) => {
        setHighlight((prevSet) => {
            const newSet = new Set(prevSet);
            if (isHighlighted) {
                newSet.delete(id);
            } else {
                newSet.add(id);
            }
            return newSet;
        });
    }, []);

    useMeasurementReloadLimiter({
        measurementID,
        timestampType: TimestampType.Logs,
        callback: fetchLogs,
    });

    useEffect(() => {
        if (!isEmpty(logs) && product && !isHTMLExport) {
            const ids = uniq(logs.map((log) => log.uID));
            getSharedProceqUsers({ ids, product });
        }
    }, [logs, product, isHTMLExport]);

    const productModel = get(measurementData, 'measurement.productModel')?.toUpperCase() as ProductModel;
    const scanType = get(measurementData, 'measurement.type');

    const isMetric = getIsMetric(measurementData, product);

    if (loading) {
        return <Spinner size="default" />;
    }

    if (!product || !logs) {
        return null;
    }

    return (
        <>
            {wifiProductFamilySet.has(product) && (
                <GPRLogbook
                    logs={logs}
                    product={product}
                    productModel={productModel}
                    scanType={scanType}
                    isMetric={isMetric}
                    version={version}
                    highlight={highlight}
                    highlightHandler={setHighlight}
                    toggleHighlight={toggleHighlight}
                />
            )}
            {bluetoothProductFamilySet.has(product) && (
                <EquotipLogbook
                    logs={logs}
                    product={product}
                    productModel={productModel}
                    isVerificationMode={isVerificationMode}
                    scanType={scanType}
                    isMetric={isMetric}
                    highlight={highlight}
                    toggleHighlight={toggleHighlight}
                    measurementID={measurementID}
                />
            )}
        </>
    );
};

export default Logbook;
