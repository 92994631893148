import React, { useState } from 'react';
import FormattedMessage from '../../localization/FormatMessage';
import { ReactComponent as IconRefresh } from '../../images/iconRefresh.svg';
import { classNames } from '../../utils/styleUtils';
import styles from './ButtonRefresh.styl';
import AnalyticsButton from '../AnalyticsComponents/Button';

interface ButtonRefreshProps {
    disabled?: boolean;
    onClick?: () => Promise<any>;
}

const ButtonRefresh: React.FunctionComponent<ButtonRefreshProps> = (props) => {
    const { disabled, onClick } = props;

    const [isLoading, setIsLoading] = useState(false);

    const handleOnClick = () => {
        if (onClick) {
            setIsLoading(true);
            onClick().finally(() => {
                setIsLoading(false);
            });
        }
    };

    return (
        <AnalyticsButton disabled={disabled || isLoading} onClick={handleOnClick} className={styles.button}>
            <div className={classNames(styles.icon, isLoading && styles.active)}>
                <IconRefresh />
            </div>
            {props.children || <FormattedMessage id="App.Refresh" />}
        </AnalyticsButton>
    );
};

export default ButtonRefresh;
