import React from 'react';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { Table } from 'antd';
import {
    ExpandableConfig,
    SorterResult,
    TableCurrentDataSource,
    TablePaginationConfig,
} from 'antd/lib/table/interface';
import { ProductCode, supportedProductSet } from '../../types/proceq';
import { BluetoothFileType, CustomCurveFileType, MeasurementListItem } from '../../types/measurement';
import {
    columnsCustomCurve,
    columnsVerification,
    getClientCreatedColumn,
    getClientUpdatedColumn,
    getColumnsEquotip,
    getColumnsFDL,
    getColumnsGLM,
    getColumnsGPR,
    getColumnsGPRMounted,
    getColumnsGPRSoil,
    getColumnsPI,
    getColumnsProfometer,
    getColumnsPundit,
    getColumnsSchmidt,
    getFlagColumn,
    getNameColumn,
    QueryKey,
} from './ColumnConfig/columnConfigMeasurement';
import styles from './DataView.styl';
import FormattedMessage from '../../localization/FormatMessage';
import TableWrapper from '../TableWrapper';
import { archivedTableLocaleSetting, tableLocaleSetting } from '../shared/MyEmpty';

interface GetMeasurementColumnsParams {
    product: ProductCode | undefined;
    fileType: string;
    renderActionCell: (value: string, measurement: MeasurementListItem) => JSX.Element | null;
    isHTMLView: boolean;
    preventFlagClick: boolean;
    allowExpansion?: boolean;
    isExpanded?: (id: string) => boolean;
    onExpand?: (id: string, isExpanded: boolean) => void;
}

export const getMeasurementColumns = (params: GetMeasurementColumnsParams) => {
    const {
        product,
        fileType,
        renderActionCell,
        isHTMLView = false,
        preventFlagClick = false,
        allowExpansion,
        isExpanded,
        onExpand,
    } = params;
    const columns: ColumnsType<MeasurementListItem> = [];
    const isVerificationFile = fileType === BluetoothFileType.VerificationData;

    columns.push(getFlagColumn(!(isHTMLView || preventFlagClick)));
    columns.push(getNameColumn({ isHTMLView, allowExpansion, isExpanded, onExpand }));
    switch (product) {
        case ProductCode.GPR:
            columns.push(...getColumnsGPR(isHTMLView));
            break;
        case ProductCode.GPR_MOUNTED:
            columns.push(...getColumnsGPRMounted(isHTMLView));
            break;
        case ProductCode.GPR_SOIL:
            columns.push(...getColumnsGPRSoil(isHTMLView));
            break;
        case ProductCode.EQUOTIP:
            columns.push(...(isVerificationFile ? columnsVerification : getColumnsEquotip(isHTMLView)));
            break;
        case ProductCode.PUNDIT:
            columns.push(...getColumnsPundit(isHTMLView));
            break;
        case ProductCode.SCHMIDT:
            columns.push(...(isVerificationFile ? columnsVerification : getColumnsSchmidt(isHTMLView)));
            break;
        case ProductCode.GLM:
            columns.push(...getColumnsGLM(isHTMLView));
            break;
        case ProductCode.FDL:
            columns.push(...(fileType === CustomCurveFileType.dgscc ? columnsCustomCurve : getColumnsFDL(isHTMLView)));
            break;
        case ProductCode.PIT_IE:
            columns.push(...getColumnsPI(isHTMLView));
            break;
        case ProductCode.PROFOMETER:
            columns.push(...getColumnsProfometer(isHTMLView));
            break;
        default:
            columns.push(getClientCreatedColumn(isHTMLView), getClientUpdatedColumn(isHTMLView));
    }

    const actionColumn: ColumnType<MeasurementListItem> = {
        key: 'action',
        className: styles.action_cell,
        title: <FormattedMessage id="App.Actions" />,
        dataIndex: 'id',
        width: 180,
        fixed: 'right',
        render: renderActionCell,
    };

    if (product && supportedProductSet.has(product)) {
        columns.push(actionColumn);
    }

    const columnsWidth = columns.reduce((sum, col) => {
        const colWidth = Number(col.width);
        return sum + (!isNaN(colWidth) ? colWidth : 0);
    }, 0);

    return { columns, columnsWidth };
};

interface MeasurementTableProps {
    columns: ColumnsType<MeasurementListItem>;
    columnsWidth: number;
    tableKey: string;
    dataSource: MeasurementListItem[];
    rowSelection: { selectedRowKeys: string[]; onChange: (values: React.Key[]) => void };
    expandable?: ExpandableConfig<MeasurementListItem>;
    onChange?: (
        pagination: TablePaginationConfig,
        filters: Record<string, (string | number | boolean)[] | null>,
        sorter: SorterResult<MeasurementListItem> | SorterResult<MeasurementListItem>[],
        extra: TableCurrentDataSource<MeasurementListItem>
    ) => void;
    loading: boolean;
    pagination: TablePaginationConfig | false;
    isArchiveViewType?: boolean;
    onRow?: any;
}

const MeasurementTable: React.FunctionComponent<MeasurementTableProps> = (props) => {
    const {
        columns,
        columnsWidth,
        tableKey,
        dataSource,
        rowSelection,
        expandable,
        onChange,
        loading,
        pagination,
        isArchiveViewType,
        onRow,
    } = props;
    const hasFlagColumn = columns[0].key === QueryKey.Flagged;

    return (
        <TableWrapper>
            {(tableHeight, tableWidth) => {
                // Make the center scrollable if insufficient space
                // And name column stretchable if got space
                const minNameWidth = 250;
                if (tableWidth && columnsWidth + minNameWidth > tableWidth) {
                    columns[hasFlagColumn ? 1 : 0].width = minNameWidth;
                } else {
                    columns[hasFlagColumn ? 1 : 0].width = undefined;
                }

                return (
                    <Table
                        key={tableKey}
                        columns={columns}
                        className={'scroll-table'}
                        rowKey="key"
                        dataSource={dataSource}
                        rowSelection={{
                            ...rowSelection,
                            getCheckboxProps: (record) => ({
                                disabled: !record.id || !!record.isImport || !!record.isCreate,
                            }),
                        }}
                        onRow={onRow}
                        expandable={expandable}
                        pagination={pagination}
                        onChange={onChange}
                        scroll={{ y: (tableHeight ?? 0) - 55 }} // 55 is header row height
                        loading={loading}
                        locale={isArchiveViewType ? archivedTableLocaleSetting : tableLocaleSetting}
                        sticky
                    />
                );
            }}
        </TableWrapper>
    );
};

export default MeasurementTable;
