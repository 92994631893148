import { get } from 'lodash';
import { renderFolderName } from '../../utils/renderFolderName';
import {
    ASTM_IMPERIAL_DIAMETER_REBAR_SIZE,
    MeasurementFullData,
    ProfometerUnit,
} from '../../../../../types/measurement';
import { FormatIDs, FormatMessageFunc } from '../../../../../types';
import { ScanType } from '../../../../../types/proceq';
import { getConvertedSectionTableData, RawSectionTableData } from '../../../../../utils/tableDataUtils';
import { CORROSION_SCAN_TYPES, PROBE_TYPE_TEXT, ProbeType } from '../../../../../types/profometer';

enum MeasuringRange {
    off = 'off',
    auto = 'auto',
    small = 'small',
    large = 'large',
}

const MEASURING_RANGE_TEXT: Record<MeasuringRange, FormatIDs> = {
    [MeasuringRange.off]: 'App.HTML.Profometer.MeasurementSettings.MeasuringRange.Off',
    [MeasuringRange.auto]: 'App.HTML.Profometer.MeasurementSettings.MeasuringRange.Auto',
    [MeasuringRange.small]: 'App.HTML.Profometer.MeasurementSettings.MeasuringRange.Standard',
    [MeasuringRange.large]: 'App.HTML.Profometer.MeasurementSettings.MeasuringRange.Deep',
};

const CORROSION_PRESET_UNIT_TEXT: Record<ProfometerUnit, FormatIDs> = {
    [ProfometerUnit.metric]: 'Proceq.Settings.Unit.Metric',
    [ProfometerUnit.metricJapanese]: 'Proceq.Settings.Unit.Metric',
    [ProfometerUnit.imperial]: 'Proceq.Settings.Unit.Imperial',
    [ProfometerUnit.imperialDiameter]: 'Proceq.Settings.Unit.Imperial',
};

const PRESET_UNIT_TEXT: Record<ProfometerUnit, FormatIDs> = {
    [ProfometerUnit.metric]: 'Proceq.Settings.Unit.Metric',
    [ProfometerUnit.metricJapanese]: 'App.HTML.Profometer.MeasurementSettings.Unit.MetricJapanese',
    [ProfometerUnit.imperial]: 'App.HTML.Profometer.MeasurementSettings.Unit.Imperial',
    [ProfometerUnit.imperialDiameter]: 'App.HTML.Profometer.MeasurementSettings.Unit.ImperialDiameter',
};

export const getUnitFormatID = (unit: ProfometerUnit, scanType?: ScanType) =>
    scanType && CORROSION_SCAN_TYPES.has(scanType) ? CORROSION_PRESET_UNIT_TEXT[unit] : PRESET_UNIT_TEXT[unit];

enum MeasuringPattern {
    uniDirectional = 'uniDirectional',
    zigZag = 'zigZag',
}

const MEASURING_PATTERN_TEXT: Record<MeasuringPattern, FormatIDs> = {
    [MeasuringPattern.uniDirectional]: 'App.HTML.Profometer.MeasurementSettings.MeasuringPattern.Unidirectional',
    [MeasuringPattern.zigZag]: 'App.HTML.Profometer.MeasurementSettings.MeasuringPattern.Zigzag',
};

export const getProfometerValue = (convert: (value: string | number, unitId: string) => string | number) => {
    return (row: RawSectionTableData) => {
        // skip conversion as all rebar diameters will be shown either in mm or bar size
        if (row.unitId && row.unitId === 'Profometer.Measurement.RebarDiameter') {
            return row.value;
        }
        return row.unitId ? convert(row.value, row.unitId) : row.value;
    };
};

export const getProfometerMeasurementPresets = (
    data: MeasurementFullData | undefined,
    scanType: ScanType,
    isMetric: boolean,
    convert: (value: string | number, unitId: string) => string | number,
    formatMessage: FormatMessageFunc,
    isFullList?: boolean
) => {
    if (!data) {
        return [];
    }
    const rebarDiameter = data.settings[0].content.preset.rebarDiameter;
    const mode = data.measurement.type;
    const isCorrosion = CORROSION_SCAN_TYPES.has(scanType);
    const {
        gridSpacingX = 0,
        gridSpacingY = 0,
        numberOfHorizontalLines = 0,
        numberOfVerticalLines = 0,
    } = data.settings[0].content.corrosionScanStatus?.areaScanParameters ?? {};
    const presetsRaw: RawSectionTableData[] = [
        {
            label: 'Proceq.FileName',
            value: get(data, 'measurement.name'),
            enable: !!isFullList,
        },
        {
            label: 'Proceq.FileID',
            value: get(data, 'measurement.id'),
            enable: !!isFullList,
        },
        {
            label: 'Proceq.Folder',
            value: renderFolderName(data, formatMessage),
            enable: !!isFullList,
        },
        {
            label: 'App.HTML.Profometer.MeasurementSettings.MeasuringMode',
            value: isCorrosion
                ? `${formatMessage({ id: 'App.HTML.Profometer.MeasurementSettings.Corrosion' })} - ${mode}`
                : mode,
        },
        {
            label: 'App.HTML.Profometer.MeasurementSettings.MeasuringOrientation',
            value: formatMessage({
                id:
                    data.settings[0].content?.corrosionScanStatus?.areaScanParameters?.startDirection === 'X'
                        ? 'App.HTML.Profometer.MeasurementSettings.ScanDirection.Horizontal'
                        : 'App.HTML.Profometer.MeasurementSettings.ScanDirection.Vertical',
            }),
            enable: !!isFullList && isCorrosion,
        },
        {
            label: 'App.HTML.FDL.IndicationInfo.ProbeType',
            value: formatMessage({
                id: PROBE_TYPE_TEXT[data.settings[0].content?.preset?.corrosionProbeType as ProbeType] ?? ' ',
            }),
            enable: isCorrosion,
        },
        {
            label: 'App.HTML.Profometer.Logbook.HalfCellSolution',
            value: data.settings[0].content?.preset?.halfCellSolution,
            enable: isCorrosion,
        },
        {
            label: 'App.HTML.Profometer.Logbook.AutoSave',
            value: data.settings[0].content?.preset?.corrosionAutoSave
                ? formatMessage({ id: 'Proceq.On' })
                : formatMessage({ id: 'Proceq.Off' }),
            enable: isCorrosion && data.settings[0].content?.preset?.corrosionProbeType === ProbeType.ROD,
        },
        {
            label: 'App.HTML.Profometer.MeasurementSettings.MeasuringRange',
            value:
                data.settings[0].content.preset.measuringRange &&
                data.settings[0].content.preset.measuringRange in MeasuringRange
                    ? formatMessage({
                          id: MEASURING_RANGE_TEXT[data.settings[0].content.preset.measuringRange as MeasuringRange],
                      })
                    : '',
            enable: !isCorrosion,
        },
        {
            label: 'Units',
            value:
                data.settings[0].content.preset.unit && data.settings[0].content.preset.unit in ProfometerUnit
                    ? formatMessage({
                          id: getUnitFormatID(data.settings[0].content.preset.unit as ProfometerUnit, scanType),
                      })
                    : '',
        },
        {
            label: 'App.HTML.Profometer.Logbook.ThresholdStandard',
            value: data.settings[0].content?.preset?.thresholdStandard,
            enable: scanType === ScanType.Basic,
        },
        {
            label: 'App.HTML.Profometer.MeasurementSettings.CoverOffset',
            unitId: 'Profometer.Presets.CoverOffset',
            value: data.settings[0].content.preset.coverOffset,
            enable: !isCorrosion,
        },
        {
            label: 'App.HTML.Profometer.MeasurementSettings.NRC',
            value: data.settings[0].content.imageProcessing?.nrc
                ? formatMessage({ id: 'Proceq.On' })
                : formatMessage({ id: 'Proceq.Off' }),
            enable: !!isFullList && scanType !== ScanType.SpotScan && !isCorrosion,
        },
        {
            label: isMetric
                ? 'App.HTML.Profometer.MeasurementSettings.RebarDiameterUnits'
                : 'App.HTML.Profometer.MeasurementSettings.RebarDiameter',
            unitId: 'Profometer.Measurement.RebarDiameter',
            value: !isMetric && rebarDiameter ? ASTM_IMPERIAL_DIAMETER_REBAR_SIZE[rebarDiameter] : rebarDiameter,
            enable: !!isFullList && scanType === ScanType.SpotScan && !isCorrosion,
        },
        {
            label: 'App.HTML.Profometer.MeasurementSettings.MeasuringPattern',
            value:
                data.settings[0].content.preset.measuringPattern &&
                data.settings[0].content.preset.measuringPattern in MeasuringPattern
                    ? formatMessage({
                          id: MEASURING_PATTERN_TEXT[
                              data.settings[0].content.preset.measuringPattern as MeasuringPattern
                          ],
                      })
                    : '',
            enable: !!isFullList && scanType === ScanType.AreaScan && !isCorrosion,
        },
        {
            label: 'App.HTML.Profometer.MeasurementSettings.GridSetup',
            value: formatMessage({
                id: data.settings[0].content?.corrosionScanStatus?.areaScanParameters?.isFlexible
                    ? 'App.HTML.Profometer.MeasurementSettings.GridSetup.Dynamic'
                    : 'App.HTML.Profometer.MeasurementSettings.GridSetup.Fixed',
            }),
            enable: isCorrosion,
        },
        {
            label: 'App.HTML.Profometer.MeasurementSettings.GridSetup.GridDimensionX',
            value: numberOfHorizontalLines * gridSpacingX,
            unitId: 'Profometer.Measurement.GridDimension',
            enable: isCorrosion && !!isFullList,
        },
        {
            label: 'App.HTML.Profometer.MeasurementSettings.GridSetup.GridDimensionY',
            value: numberOfVerticalLines * gridSpacingY,
            unitId: 'Profometer.Measurement.GridDimension',
            enable: isCorrosion && !!isFullList,
        },
        {
            label: 'App.HTML.Profometer.MeasurementSettings.GridSetup.CellDimensionX',
            value: gridSpacingX,
            unitId: 'Profometer.Measurement.GridDimension',
            enable: isCorrosion && !!isFullList,
        },
        {
            label: 'App.HTML.Profometer.MeasurementSettings.GridSetup.CellDimensionY',
            value: gridSpacingY,
            unitId: 'Profometer.Measurement.GridDimension',
            enable: isCorrosion && !!isFullList,
        },
    ];

    return getConvertedSectionTableData(presetsRaw, scanType, isMetric, getProfometerValue(convert));
};
