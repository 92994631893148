import { routeUtils } from 'tds-common-fe';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { UserLicenseParams } from '../../../types/license';
import routeURLs from '../../Routes/urls';

const LicenseRedirection: React.FunctionComponent = () => {
    const query = routeUtils.parseQuery<UserLicenseParams>(location.search);
    const { product, licenseKey } = query;
    const history = useHistory();

    useEffect(() => {
        const path = routeUtils.makeQueryPath(routeURLs.PROFILE_SUBSCRIPTION, {
            product,
            licenseKey: licenseKey?.replaceAll(/\s/g, ''),
        });
        history.push(path);
    }, [history, licenseKey, product]);

    return null;
};

export default LicenseRedirection;
