import React from 'react';
import { FormatMessageFunc } from '../../../../../types';
import { ProductCode } from '../../../../../types/proceq';
import { getProfometerMeasurementPresets } from '../Profometer/getMeasurementPresets';
import { getGPRSoilMeasurementPresets } from '../GPRSoil/getMeasurementPresets';
import { getPunditMeasurementPresets } from '../Pundit/getMeasurementPresets';
import { getGPRMeasurementPresets } from '../GPR/getMeasurementPresets';
import { getPunditImpactMeasurementPresets } from '../PunditImpact/getMeasurementPresets';
import { useFormatMessage } from '../../../../../localization/useFormatMessage';
import SectionTable from '../../shared/SectionTable';
import styles from '../../DataViewers.styl';
import { DerivedProps } from '../../SharedTypes';
import { MeasurementFullData } from '../../../../../types/measurement';
import FormattedMessage from '../../../../../localization/FormatMessage';

interface MeasurementPresetsProps extends DerivedProps {
    data: MeasurementFullData;
}

export const title = (
    <>
        <FormattedMessage id={'Proceq.Settings'} />
        {' ­­» '}
        <FormattedMessage id={'Proceq.Settings.MeasurementPresets'} />
    </>
);

const getSectionTableData = (props: MeasurementPresetsProps, formatMessage: FormatMessageFunc) => {
    const { data, scanType, isMetric, convert, product } = props;
    switch (product) {
        case ProductCode.GPR:
            return getGPRMeasurementPresets(data, scanType, isMetric, convert, formatMessage, true);
        case ProductCode.PROFOMETER:
            return getProfometerMeasurementPresets(data, scanType, isMetric, convert, formatMessage, true);
        case ProductCode.GPR_MOUNTED:
        case ProductCode.GPR_SOIL:
            return getGPRSoilMeasurementPresets(data, scanType, isMetric, convert, formatMessage, true);
        case ProductCode.PUNDIT:
            return getPunditMeasurementPresets(data, scanType, isMetric, convert, formatMessage, true);
        case ProductCode.PIT_IE:
            return getPunditImpactMeasurementPresets(data, scanType, isMetric, convert, formatMessage, true);
        default:
            return [];
    }
};

export const MeasurementPresets: React.FunctionComponent<MeasurementPresetsProps> = (props) => {
    const { showTitle } = props;
    const formatMessage = useFormatMessage();

    const presets = getSectionTableData(props, formatMessage);

    return (
        <SectionTable
            showTitle={showTitle}
            className={[styles.table, 'table-measurement-presets'].join(' ')}
            title={<span className={styles.sub_header}>{title}</span>}
            dataSource={presets}
        />
    );
};

export default MeasurementPresets;
