import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Table } from 'antd';
import { useHistory } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import AppIcon, { productCodeToIconMap, ProductName } from '../shared/AppIcon';
import FormattedMessage from '../../localization/FormatMessage';
import { useMeasurements, useRecentMeasurements } from '../../hooks/useProductData';
import { getRecentMeasurements } from '../../api/measurementService';
import { getDataViewRoute } from '../Routes/urls';
import {
    ProductCode,
    ProductModel,
    productModelToFamilyMap,
    productsWithLocalizedExports,
    supportedProductSet,
} from '../../types/proceq';
import DashboardBlock from './DashboardBlock';
import styles from './RecentDataBlock.styl';
import Spinner from '../shared/Spinner';
import { ReactComponent as NoDataImage } from '../../images/noData.svg';
import MeasurementExportProvider from '../MeasurementExport/MeasurementExportProvider';
import ButtonExportWifi from '../MeasurementExport/ButtonExportWifi';
import ButtonExportBluetooth from '../MeasurementExport/ButtonExportBluetooth';
import { useContractRetriever } from '../../hooks/useContract';
import {
    getClientCreatedColumn,
    getClientUpdatedColumn,
    getNameColumn,
} from '../DataView/ColumnConfig/columnConfigMeasurement';
import { BluetoothFileType, MeasurementListItem, SystemFolderID } from '../../types/measurement';
import analytics from '../../analytics/firebaseAnalytics';
import { MeasurementPage } from '../../analytics/analyticsConstants';
import UnsyncedButtons from '../DataView/LaunchApp/UnsyncedButtons';
import getIsMeasurementUnsynced from '../DataView/DataViewers/utils/getIsMeasurementUnsynced';

type Record = MeasurementListItem & { productName?: string; productCode: ProductCode; key: string };

const nameColumn = getNameColumn({ isHTMLView: false, isRecentData: true });
const clientCreatedColumn = getClientCreatedColumn(false);
const clientUpdatedColumn = getClientUpdatedColumn(false);

const columnConfig: ColumnsType<Record> = [
    {
        dataIndex: 'productName',
        width: 72,
        render: (value: ProductName) => {
            return (
                <div className={styles.icon_wrapper}>
                    <AppIcon size={40} product={value} />
                </div>
            );
        },
    },
    {
        title: nameColumn.title,
        dataIndex: nameColumn.dataIndex,
        key: nameColumn.key,
        render: nameColumn.render,
    },
    {
        dataIndex: clientCreatedColumn.dataIndex,
        key: clientCreatedColumn.key,
        title: clientCreatedColumn.title,
        width: 170,
        render: clientCreatedColumn.render,
    },
    {
        dataIndex: clientUpdatedColumn.dataIndex,
        key: clientUpdatedColumn.key,
        title: clientUpdatedColumn.title,
        width: 170,
        render: clientUpdatedColumn.render,
    },
    {
        width: 120,
        render: (record: Record) => {
            const id = record.id;
            const hasLocalizedExports = productsWithLocalizedExports.has(record.productCode);
            const isProductSupported = supportedProductSet.has(record.productCode);
            const shouldShowButton = record && isProductSupported && id;
            const isUnsynced = getIsMeasurementUnsynced(record);

            return (
                <div className={styles.item_action} onClick={(event) => event.stopPropagation()}>
                    {shouldShowButton &&
                        (isUnsynced ? (
                            <UnsyncedButtons
                                measurement={record}
                                product={record.productCode}
                                buttonStyles={{ padding: '8px' }}
                                isRecentFiles
                            />
                        ) : hasLocalizedExports ? (
                            <ButtonExportBluetooth
                                type="link"
                                className={styles.button_export}
                                product={record.productCode}
                                mIDs={[id]}
                                fileType={record.type}
                                analyticsPage={MeasurementPage.homepage}
                            />
                        ) : (
                            <ButtonExportWifi
                                type="link"
                                className={styles.button_export}
                                product={record.productCode}
                                mIDs={[id]}
                                analyticsPage={MeasurementPage.homepage}
                            />
                        ))}
                </div>
            );
        },
    },
];

const RecentData: React.FunctionComponent = () => {
    const measurementIDs = useRecentMeasurements();
    const measurements = useMeasurements();
    const history = useHistory();

    const dataSource: Record[] = useMemo(() => {
        return measurementIDs.map((id) => {
            const measurement = measurements[id]?.measurement;
            const settings = measurements[id]?.settings;

            const productModel = measurement?.productModel?.toUpperCase() as ProductModel;
            const productCode = productModelToFamilyMap[productModel];
            const productName = productCode ? productCodeToIconMap[productCode] : undefined;

            return {
                key: measurement?.id,
                ...measurement,
                settings,
                productCode,
                productName,
            };
        });
    }, [measurements, measurementIDs]);

    const [isLoading, setIsLoading] = useState(true);

    const handleGetRecentData = useCallback(async () => {
        setIsLoading(true);
        try {
            await getRecentMeasurements();
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        handleGetRecentData();
    }, [handleGetRecentData]);

    const listContainer = useRef<HTMLDivElement>(null);

    useContractRetriever(measurementIDs);

    // for <= 6 rows, show dropdown options directly without causing scroll
    const overflowStyle = measurementIDs.length <= 6 ? { overflow: 'visible' } : undefined;

    return (
        <DashboardBlock title={<FormattedMessage id="Dashboard.RecentFiles.Title" />} {...overflowStyle}>
            <MeasurementExportProvider page={MeasurementPage.homepage}>
                <div className={styles.recent_list} ref={listContainer} style={overflowStyle}>
                    {measurementIDs.length > 0 ? (
                        <Table
                            className={styles.table}
                            columns={columnConfig}
                            dataSource={dataSource}
                            pagination={false}
                            size="small"
                            scroll={{ y: 56 * 6 }} // 6 rows
                            onRow={(record) => ({
                                onClick: () => {
                                    const { productCode, productName, id, type } = record;
                                    analytics.logMeasurementView(MeasurementPage.homepage, productCode);
                                    const route = getDataViewRoute({
                                        productName: productName || productCode,
                                        folderID:
                                            type === BluetoothFileType.VerificationData ? type : SystemFolderID.All,
                                    });
                                    const isProductSupported = supportedProductSet.has(productCode);
                                    history.push(route, isProductSupported ? { measurementID: id } : undefined);
                                },
                            })}
                        />
                    ) : (
                        <EmptyPlaceholder isLoading={isLoading} />
                    )}
                </div>
            </MeasurementExportProvider>
        </DashboardBlock>
    );
};

const EmptyPlaceholder: React.FunctionComponent<{ isLoading: boolean }> = (props) => {
    const { isLoading } = props;
    return (
        <div className={styles.empty_state}>
            {isLoading ? (
                <Spinner size="default" />
            ) : (
                <>
                    <NoDataImage style={{ marginBottom: 14 }} />
                    <h3 className={styles.subtitle1}>
                        <FormattedMessage id="Dashboard.RecentFiles.EmptyState.Title" />
                    </h3>
                    <p className={styles.caption}>
                        <FormattedMessage id="Dashboard.RecentFiles.EmptyState.Description" />
                    </p>
                </>
            )}
        </div>
    );
};

export default RecentData;
