import React, { useState } from 'react';
import { Button } from 'antd';
import StyledModal from '../../shared/StyledModal';
import FormattedMessage from '../../../localization/FormatMessage';
import styles from '../DataView.styl';
import { archiveRestoreFolder, getMeasurementFolders } from '../../../api/folderService';
import { useDataViewContext } from '../DataViewProvider';
import analytics from '../../../analytics/firebaseAnalytics';
import { FolderManagementCategory } from '../../../analytics/analyticsConstants';

interface ArchiveModalProps {
    visible: boolean;
    onCancel: () => void;
    id?: string;
    onArchive?: () => void;
    name?: string;
}

const ArchiveConfirmationModal: React.FunctionComponent<ArchiveModalProps> = (props) => {
    const { visible, onCancel, id, onArchive, name } = props;
    const { product, withUnsynced } = useDataViewContext();
    const [isLoading, setIsLoading] = useState(false);

    if (!id) return null;

    const handleArchive = async () => {
        setIsLoading(true);
        if (product) {
            archiveRestoreFolder({ id, product, isArchive: true }).then(() => {
                getMeasurementFolders({ product, withUnsynced });
                if (onArchive) {
                    onArchive();
                }
            });
            analytics.logFolderManagement(FolderManagementCategory.archive, product);
        }
        onCancel();
        setIsLoading(false);
    };

    const handleClose = () => {
        onCancel();
        if (product) {
            analytics.logFolderManagement(FolderManagementCategory.archiveCancel, product);
        }
    };

    return (
        <StyledModal
            className={styles.delete_folder_modal}
            title={<FormattedMessage id={'DataView.Folder.ArchiveFolder'} />}
            open={visible}
            onCancel={handleClose}
            footer={[
                <React.Fragment key="folderModalFooter">
                    <Button type="primary" ghost onClick={handleClose}>
                        <FormattedMessage id="App.Cancel" />
                    </Button>
                    <Button className={styles.create_button} type="primary" onClick={handleArchive} loading={isLoading}>
                        <FormattedMessage id="App.Archive" />
                    </Button>
                </React.Fragment>,
            ]}
        >
            <div className={styles.delete_folder_info}>
                <FormattedMessage id={'DataView.Folder.ArchiveFolder.Info'} values={{ name }} />
            </div>
        </StyledModal>
    );
};

export default ArchiveConfirmationModal;
