import React from 'react';
import styles from './Dashboard.styl';

interface DashboardBlockProps {
    title?: React.ReactNode;
    height?: number;
    actions?: React.ReactNode;
    overflow?: string;
}

const DashboardBlock: React.FunctionComponent<DashboardBlockProps> = (props) => {
    const { title, height, actions, overflow } = props;
    return (
        <div
            className={styles.dashboard_block}
            style={{
                maxHeight: height,
                minHeight: height,
                overflow,
            }}
        >
            <div className={styles.dashboard_block_header}>
                {title && <h3 className={styles.subtitle1}>{title}</h3>}
                <div>{actions}</div>
            </div>
            {props.children}
        </div>
    );
};

export default DashboardBlock;
