import { useQuery } from '@tanstack/react-query';
import * as probeService from '../api/probeService';
import { ReactQueryKeys } from './queryKeys';

export const useProbesList = (enabled?: boolean) =>
    useQuery({
        queryKey: ReactQueryKeys.probeList,
        queryFn: probeService.getProbesList,
        enabled,
        refetchOnWindowFocus: false,
    });
