import React from 'react';
import StyledModal from '../shared/StyledModal';
import FormattedMessage from '../../localization/FormatMessage';
import { useDataViewContext } from './DataViewProvider';
import AnalyticsButton from '../AnalyticsComponents/Button';
import { listMeasurementNames } from './DataViewers/utils/listMeasurementNames';
import { useMeasurements } from '../../hooks/useProductData';
import { useFormatMessage } from '../../localization/useFormatMessage';
import { NonEditableType } from './DataViewContext';
import { FormatIDs } from '../../types';

const NON_EDITABLE_MODAL_TEXT: Record<
    NonEditableType,
    { title: FormatIDs; allInvalid: FormatIDs; someInvalid: FormatIDs }
> = {
    [NonEditableType.move]: {
        title: 'DataView.Move.Invalid.Title',
        allInvalid: 'DataView.Move.Invalid.All',
        someInvalid: 'DataView.Move.Invalid.BodyHeader',
    },
    [NonEditableType.edit]: {
        title: 'DataView.NonEditable.Title',
        allInvalid: 'DataView.NonEditable.AllInvalid',
        someInvalid: 'DataView.NonEditable.SomeInvalid',
    },
    [NonEditableType.editFolder]: {
        title: 'DataView.NonEditable.Folder.Title',
        allInvalid: 'DataView.NonEditable.Folder.Body',
        someInvalid: ' ',
    },
    [NonEditableType.archiveFolder]: {
        title: 'DataView.NonArchivable.Folder.Title',
        allInvalid: 'DataView.NonArchivable.Folder.Body',
        someInvalid: ' ',
    },
    [NonEditableType.restoreFolder]: {
        title: 'DataView.NonRestorable.Folder.Title',
        allInvalid: 'DataView.NonRestorable.Folder.Body',
        someInvalid: ' ',
    },
};

const NonEditableModal: React.FunctionComponent = () => {
    const { nonEditableMeasurements, setNonEditableMeasurements } = useDataViewContext();
    const measurements = useMeasurements();
    const formatMessage = useFormatMessage();
    const handleClose = () => {
        setNonEditableMeasurements(undefined);
    };

    const {
        allInvalid = true,
        measurementIds = [],
        nonEditableType = NonEditableType.edit,
    } = nonEditableMeasurements ?? {};

    return (
        <StyledModal
            open={!!nonEditableMeasurements}
            title={<FormattedMessage id={NON_EDITABLE_MODAL_TEXT[nonEditableType].title} />}
            footer={
                <AnalyticsButton type="primary" onClick={handleClose}>
                    <FormattedMessage id="App.OK" />
                </AnalyticsButton>
            }
            destroyOnClose
            onCancel={handleClose}
        >
            {allInvalid ? (
                <FormattedMessage id={NON_EDITABLE_MODAL_TEXT[nonEditableType].allInvalid} />
            ) : (
                <>
                    <FormattedMessage id={NON_EDITABLE_MODAL_TEXT[nonEditableType].someInvalid} />
                    <ul style={{ marginTop: '14px' }}>
                        {listMeasurementNames(measurementIds, measurements, formatMessage).map((name) => (
                            <li key={name}>{name}</li>
                        ))}
                    </ul>
                </>
            )}
        </StyledModal>
    );
};

export default NonEditableModal;
