export const PASSWORD_MAX_LENGTH = 72;
export const DEFAULT_DATE_FORMAT = 'DD MMM YYYY';
export const POLICY_URL = 'https://www.proceq.com/fileadmin/user_upload/images/Proceq_S.A._Data_Privacy_Policy.pdf';
export const TNC_URL =
    'https://www.proceq.com/fileadmin/user_upload/images/General_Terms_and_Conditions_of_Software_Subscription_Proceq_SA.pdf';

export const INSPECT_TRIAL_URL = 'https://success.screeningeagle.com/inspect_demo';
export const SUPPORT_CONTACT_URL = 'https://www.screeningeagle.com/en/contact-us';
export const DATA_PRIVACY_URL = 'https://www.screeningeagle.com/data-privacy-policy';

export const CMS_NEWS_URL_PREFIX = 'https://www.screeningeagle.com/en/about-us/news/';
