import React from 'react';
import { message, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { isMaliciousFile } from 'tds-common-fe/src/lib/utils/fileUtils';
import StyledModal from './StyledModal';
import FormattedMessage from '../../localization/FormatMessage';
import { ReactComponent as UploadIcon } from '../../images/iconUpload.svg';
import styles from './CustomCurveUploadModal.styl';
import AnalyticsButton from '../AnalyticsComponents/Button';
import { useFormatMessage } from '../../localization/useFormatMessage';

const { Dragger } = Upload;

interface UploadModalProps {
    visible: boolean;
    onClose: () => void;
    supportedFormats: string[];
    onDrop: (file: RcFile, fileExt: string) => void;
    handlePreview: () => void;
    buttonDisabled: boolean;
    buttonLoading?: boolean;
}

const CustomCurveUploadModal: React.FunctionComponent<UploadModalProps> = (props) => {
    const { visible, onClose, supportedFormats, onDrop, handlePreview, buttonDisabled, buttonLoading } = props;
    const supportedFormatsString = `.${supportedFormats.join(', .')}`;
    const formatMessage = useFormatMessage();

    return (
        <StyledModal
            width={856}
            forceRender
            onCancel={onClose}
            onOk={handlePreview}
            open={visible}
            title={<FormattedMessage id="DataView.Upload.UploadCustomCurves" />}
            footer={
                <AnalyticsButton
                    loading={buttonLoading}
                    disabled={buttonDisabled}
                    type="primary"
                    onClick={handlePreview}
                >
                    <FormattedMessage id="DataView.Upload.PreviewCurve" />
                </AnalyticsButton>
            }
            bodyStyle={{ overflow: 'auto' }}
        >
            <Dragger
                beforeUpload={(file, fileList) => {
                    if (file.uid === fileList[fileList.length - 1].uid) {
                        fileList.forEach((file) => {
                            const fileExt = file.name.split('.').pop();
                            if (fileExt && supportedFormats.includes(fileExt) && !isMaliciousFile(file)) {
                                onDrop(file, fileExt);
                            } else {
                                message.error(formatMessage({ id: 'DataView.Upload.Error' }, { fileName: file.name }));
                            }
                        });
                        const hasMaliciousFile = fileList.some((file) => isMaliciousFile(file));
                        if (hasMaliciousFile) {
                            message.error(formatMessage({ id: 'DataView.Upload.Error.MaliciousFile' }), 5);
                        }
                    }
                    return false;
                }}
                multiple={false}
                accept={supportedFormatsString}
                showUploadList={false}
            >
                <div className={styles.dragger_container}>
                    <UploadIcon className={styles.icon} />
                    <p>
                        <FormattedMessage
                            id="DataView.Upload.DragFileHere"
                            values={{
                                browse: (
                                    <span className={styles.browse_text}>
                                        <FormattedMessage id="DataView.Upload.Browse" />
                                    </span>
                                ),
                            }}
                        />
                    </p>
                    <p className={styles.caption}>
                        <FormattedMessage id="DataView.Upload.SupportedFormat" />
                        {supportedFormatsString}
                    </p>
                    <br />
                    <p className={styles.guideline_text}>
                        <FormattedMessage
                            id="DataView.Upload.Guidelines"
                            values={{
                                csv: <b>.csv</b>,
                                yaml: <b>.yaml</b>,
                            }}
                        />
                    </p>
                </div>
            </Dragger>
            {props.children}
        </StyledModal>
    );
};

export default CustomCurveUploadModal;
