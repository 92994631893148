import { ProductCode } from '../types/proceq';
import { CustomMaterialListParams, MeasurementListParams } from './measurementQueries';

const PROFILE_KEYS = {
    profile: ['profile'],
    userProfile: (userID: number) => [...PROFILE_KEYS.profile, userID],
    proceqUser: (userID: number, isCurrentUser?: boolean) => [
        ...PROFILE_KEYS.profile,
        'proceqUser',
        userID,
        { isCurrentUser },
    ],
    accountDeletion: () => [...PROFILE_KEYS.profile, 'accountDeletion'],
};

const PRODUCT_KEYS = {
    product: ['product'],
    appProduct: (productCode: ProductCode) => [...PRODUCT_KEYS.product, productCode],
    appProductConfig: (productCode: ProductCode) => [...PRODUCT_KEYS.appProduct(productCode), 'config'],
    appProductBinaries: (productCode: ProductCode) => [...PRODUCT_KEYS.appProduct(productCode), 'binaries'],
};

const NOTIFICATION_KEYS = {
    notifications: ['notifications'],
    notificationsFilter: (filter: any) => [...NOTIFICATION_KEYS.notifications, filter],
};

const LICENSE_KEYS = {
    license: ['license'],
};

const MEASUREMENT_KEYS = {
    measurement: ['measurement'],
    measurementList: (params: MeasurementListParams) => [
        ...MEASUREMENT_KEYS.measurement,
        params.product,
        'list',
        params,
    ],
    customMaterialList: (params: CustomMaterialListParams) => [
        ...MEASUREMENT_KEYS.measurement,
        params.product,
        'customMaterialList',
        params,
    ],
    customMaterialCount: (product: ProductCode, withUnsynced: boolean) => [
        ...MEASUREMENT_KEYS.measurement,
        product,
        'customMaterialCount',
        withUnsynced,
    ],
    verificationDataCount: (product: ProductCode, withUnsynced: boolean) => [
        ...MEASUREMENT_KEYS.measurement,
        product,
        'verificationDataCount',
        withUnsynced,
    ],
};

export const ReactQueryKeys = {
    ...PROFILE_KEYS,
    ...PRODUCT_KEYS,
    ...NOTIFICATION_KEYS,
    ...LICENSE_KEYS,
    ...MEASUREMENT_KEYS,

    // probe
    probeList: ['probeList'],

    // others
    activeApps: ['activeApps'],
    cmsNews: ['cmsNews'],
    backendVersion: ['backendVersion'],
};
