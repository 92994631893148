import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import 'antd/dist/antd.dark.less';
import { PersistGate } from 'redux-persist/integration/react';

import './polyfill';
import './index.global.styl';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import reportWebVitals from './reportWebVitals';
import LocalizedIntlProvider from './localization/LocalizedIntlProvider';
import Routes from './components/Routes';
import { store, storagePersistor } from './store';
import config from './config';
import { retrieveConsumerInfo } from './api/headerUtils';
import analytics from './analytics/firebaseAnalytics';
import NotificationProvider from './components/Notification/NotificationProvider';

retrieveConsumerInfo();
analytics.initialize();

if (config.NODE_ENV === 'production') {
    Sentry.init({
        dsn: config.SENTRY_DSN,
        environment: config.APP_ENV,
        ignoreErrors: [
            'ResizeObserver loop limit exceeded',
            'ResizeObserver loop completed with undelivered notifications.',
            'Request aborted',
            'Request failed with status code 400',
            'timeout of 30000ms exceeded',
            'Non-Error exception captured with keys: currentTarget, isTrusted, target, type',
        ],
        release: config.APP_VERSION,
    });
}

const queryClient = new QueryClient({ defaultOptions: { queries: { retry: false } } });

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate persistor={storagePersistor}>
                <LocalizedIntlProvider>
                    <QueryClientProvider client={queryClient}>
                        <NotificationProvider>
                            <Routes />
                        </NotificationProvider>
                        <ReactQueryDevtools initialIsOpen={false} />
                    </QueryClientProvider>
                </LocalizedIntlProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
