import React, { useCallback } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import FormattedMessage from '../../localization/FormatMessage';
import AnalyticsRoute from '../AnalyticsComponents/Route';
import routeURLs from '../Routes/urls';
import Header from '../shared/Header';
import MenuList from '../shared/MenuList';
import ProbeList from '../Probes/ProbeList';
import styles from './Profile.styl';
import UserProfile from './UserProfile';
import UserLicense from './UserLicense';
import analytics from '../../analytics/firebaseAnalytics';
import { PersonalProfileMenu } from '../../analytics/analyticsConstants';
import Security from './Security';

const menuItems = [
    {
        id: routeURLs.PROFILE_PERSONAL,
        title: <FormattedMessage id="Profile.Personal" />,
        analyticsCategory: PersonalProfileMenu.personalProfile,
    },
    {
        id: routeURLs.PROFILE_SECURITY,
        title: <FormattedMessage id="Profile.PasswordSecurity" />,
        analyticsCategory: PersonalProfileMenu.passwordAndSecurity,
    },
    {
        id: routeURLs.PROFILE_PROBES,
        title: <FormattedMessage id="App.Probes" />,
        analyticsCategory: PersonalProfileMenu.probes,
    },
    {
        id: routeURLs.PROFILE_SUBSCRIPTION,
        title: <FormattedMessage id="Profile.MySubscriptions" />,
        pageTitle: <FormattedMessage id="Profile.Subscriptions" />,
        analyticsCategory: PersonalProfileMenu.mySubscriptions,
    },
];

const Profile: React.FunctionComponent = () => {
    const history = useHistory();
    const { pathname } = history.location;

    const contentMargin = { marginTop: 59 };

    const menuItem = menuItems.find((item) => item.id === pathname);

    const onSelect = useCallback(
        (key: string) => {
            history.push(key);
            analytics.logSwitchProfileMenu(menuItem?.analyticsCategory);
        },
        [history, menuItem]
    );

    return (
        <div className={styles.page_root}>
            <div className={styles.menu_column}>
                <Header hasLogo isSidePanel />
                <div className={styles.menu_list_container}>
                    <MenuList menuItems={menuItems} activeItem={pathname} onSelect={onSelect} />
                </div>
            </div>

            <div className={styles.profile_column}>
                <Header />
                <div className={styles.profile_content} style={contentMargin}>
                    <h1 className={styles.display2}>{(menuItem?.pageTitle || menuItem?.title) ?? ''}</h1>
                    <Switch>
                        <AnalyticsRoute exact path={routeURLs.PROFILE_PERSONAL}>
                            <UserProfile />
                        </AnalyticsRoute>
                        <AnalyticsRoute exact path={routeURLs.PROFILE_SECURITY}>
                            <Security />
                        </AnalyticsRoute>
                        <AnalyticsRoute exact path={routeURLs.PROFILE_PROBES}>
                            <ProbeList />
                        </AnalyticsRoute>
                        <AnalyticsRoute exact path={routeURLs.PROFILE_SUBSCRIPTION}>
                            <UserLicense />
                        </AnalyticsRoute>
                        <Route>
                            <Redirect to={routeURLs.PROFILE_PERSONAL} />
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    );
};

export default Profile;
