import React from 'react';
import { RcFile } from 'antd/lib/upload';
import styles from './ButtonUpload.styl';
import UploadedFileCard from './UploadedFileCard';

interface UploadedFileListProps {
    csvFile?: RcFile;
    onDeleteCsv: () => void;
}

const UploadedFileList: React.FunctionComponent<UploadedFileListProps> = (props) => {
    const { csvFile, onDeleteCsv } = props;

    return csvFile ? (
        <div className={styles.file_listing_container}>
            <UploadedFileCard file={csvFile} onDelete={onDeleteCsv} />
        </div>
    ) : null;
};

export default UploadedFileList;
