import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import StyledModal from '../../shared/StyledModal';
import FormattedMessage from '../../../localization/FormatMessage';
import { FormatIDs } from '../../../types';
import { useMeasurements } from '../../../hooks/useProductData';
import { useFormatMessage } from '../../../localization/useFormatMessage';
import { listMeasurementNames } from '../DataViewers/utils/listMeasurementNames';

export enum InvalidShareExportType {
    share,
    export,
    move,
    archive,
    restore,
}

interface InvalidShareExportModalProps {
    visible: boolean;
    onClose: () => void;
    invalidType: InvalidShareExportType;
    invalidMeasurementIDs: string[];
    onExclude: () => void;
    selectedKeysCount: number;
}

const InvalidShareExportTypeMap: Record<
    InvalidShareExportType,
    { title: FormatIDs; bodyHeader: FormatIDs; bodyEnd: FormatIDs; allInvalid: FormatIDs; button: FormatIDs }
> = {
    [InvalidShareExportType.export]: {
        title: 'DataView.Export.Invalid.Title',
        bodyHeader: 'DataView.Export.Invalid.BodyHeader',
        bodyEnd: 'DataView.Export.Invalid.BodyEnd',
        allInvalid: 'DataView.Export.Invalid.All',
        button: 'DataView.Export.Invalid.Button',
    },
    [InvalidShareExportType.share]: {
        title: 'DataView.Share.Invalid.Title',
        bodyHeader: 'DataView.Share.Invalid.BodyHeader',
        bodyEnd: 'DataView.Share.Invalid.BodyEnd',
        allInvalid: 'DataView.Share.Invalid.All',
        button: 'DataView.Share.Invalid.Button',
    },
    [InvalidShareExportType.move]: {
        title: 'DataView.Move.Invalid.Title',
        bodyHeader: 'DataView.Move.Invalid.BodyHeader',
        bodyEnd: 'DataView.Move.Invalid.BodyEnd',
        allInvalid: 'DataView.Move.Invalid.All',
        button: 'DataView.Move.Invalid.Button',
    },
    [InvalidShareExportType.archive]: {
        title: 'DataView.Archive.Invalid.Title',
        bodyHeader: 'DataView.Archive.Invalid.SomeInvalid',
        bodyEnd: 'DataView.Archive.Invalid.BodyEnd',
        allInvalid: 'DataView.Archive.Invalid.All',
        button: 'DataView.Archive.Invalid.Button',
    },
    [InvalidShareExportType.restore]: {
        title: 'DataView.Restore.Invalid.Title',
        bodyHeader: 'DataView.Restore.Invalid.SomeInvalid',
        bodyEnd: 'DataView.Restore.Invalid.BodyEnd',
        allInvalid: 'DataView.Restore.Invalid.All',
        button: 'DataView.Restore.Invalid.Button',
    },
};

const InvalidShareExportModal: React.FunctionComponent<InvalidShareExportModalProps> = (props) => {
    const { visible, onClose, invalidType, invalidMeasurementIDs, onExclude, selectedKeysCount } = props;
    const [isLoading, setIsLoading] = useState(false);
    const measurements = useMeasurements();
    const formatMessage = useFormatMessage();
    const allInvalid = selectedKeysCount === invalidMeasurementIDs.length;

    useEffect(() => {
        if (!visible) {
            setIsLoading(false);
        }
    }, [visible]);

    return (
        <StyledModal
            title={<FormattedMessage id={InvalidShareExportTypeMap[invalidType].title} />}
            open={visible}
            onCancel={onClose}
            footer={[
                allInvalid ? (
                    <Button key="footerButtons" type="primary" onClick={onClose}>
                        <FormattedMessage id="App.OK" />
                    </Button>
                ) : (
                    <div key="footerButtons">
                        <Button type="primary" ghost onClick={onClose}>
                            <FormattedMessage id="App.Cancel" />
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                setIsLoading(true);
                                onExclude();
                            }}
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            <FormattedMessage id={InvalidShareExportTypeMap[invalidType].button} />
                        </Button>
                    </div>
                ),
            ]}
        >
            {allInvalid ? (
                <FormattedMessage id={InvalidShareExportTypeMap[invalidType].allInvalid} />
            ) : (
                <>
                    <FormattedMessage id={InvalidShareExportTypeMap[invalidType].bodyHeader} />
                    <ul style={{ marginTop: '14px' }}>
                        {listMeasurementNames(invalidMeasurementIDs, measurements, formatMessage).map((name) => (
                            <li key={name}>{name}</li>
                        ))}
                    </ul>
                    <FormattedMessage id={InvalidShareExportTypeMap[invalidType].bodyEnd} />
                </>
            )}
        </StyledModal>
    );
};

export default InvalidShareExportModal;
