import { NonEditableMeasurements } from '../components/DataView/DataViewContext';
import { BluetoothFileType } from '../types/measurement';
import { useDataViewContext } from '../components/DataView/DataViewProvider';

interface UseInvalidMeasurementsParams {
    invalidMeasurementIDs: string[];
    demoMeasurementIDs?: string[];
    measurementID: string;
}

const useInvalidMeasurements = (params: UseInvalidMeasurementsParams) => {
    const { invalidMeasurementIDs, measurementID, demoMeasurementIDs = [] } = params;
    const { fileType } = useDataViewContext();
    const isInvalidMeasurement = invalidMeasurementIDs.includes(measurementID);
    const nonEditableMeasurements: NonEditableMeasurements | undefined =
        fileType !== BluetoothFileType.CustomMaterial && isInvalidMeasurement
            ? { allInvalid: true, measurementIds: [measurementID] }
            : undefined;

    // demo is treated like valid contract, except for some actions like move
    const nonEditableExcludeDemo = nonEditableMeasurements && !demoMeasurementIDs.includes(measurementID);

    return { isInvalidMeasurement, nonEditableMeasurements, nonEditableExcludeDemo };
};

export default useInvalidMeasurements;
