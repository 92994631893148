import { Button, Input, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import FormattedMessage from '../../../localization/FormatMessage';
import styles from '../DataView.styl';
import StyledModal from '../../shared/StyledModal';
import { createFolder, getMeasurementFolders, renameFolder } from '../../../api/folderService';
import { useDataViewContext } from '../DataViewProvider';
import { FormatIDs } from '../../../types';
import analytics from '../../../analytics/firebaseAnalytics';
import { FileManagementCategory, FolderManagementCategory } from '../../../analytics/analyticsConstants';
import {
    getCustomMaterialFullData,
    getMeasurementFullData,
    renameCustomMaterial,
    renameMeasurement,
} from '../../../api/measurementService';
import { BluetoothFileType, RESOURCE_ALREADY_EXISTS_ERROR } from '../../../types/measurement';
import { isNameASCIIPrintable } from '../../../utils/generalUtils';
import { handleError } from '../../../api/error';

export enum CreateRenameModalAction {
    newFolder,
    renameFolder,
    renameMeasurement,
}

const FOLDER_MODAL_ACTION_MAP: Record<
    CreateRenameModalAction,
    {
        title: FormatIDs;
        button: FormatIDs;
        placeholder: FormatIDs;
        logEvent: (product: string) => void;
        logCancelEvent: (product: string) => void;
    }
> = {
    [CreateRenameModalAction.newFolder]: {
        title: 'DataView.Folder.CreateNewFolder',
        button: 'DataView.Folder.CreateNewFolder.Create',
        placeholder: 'DataView.Folder.CreateNewFolder.Placeholder',
        logEvent: (product) => {
            analytics.logFolderManagement(FolderManagementCategory.create, product);
        },
        logCancelEvent: (product) => {
            analytics.logFolderManagement(FolderManagementCategory.createCancel, product);
        },
    },
    [CreateRenameModalAction.renameFolder]: {
        title: 'DataView.Folder.RenameFolder',
        button: 'App.Confirm',
        placeholder: 'DataView.Folder.CreateNewFolder.Placeholder',
        logEvent: (product) => {
            analytics.logFolderManagement(FolderManagementCategory.rename, product);
        },
        logCancelEvent: (product) => {
            analytics.logFolderManagement(FolderManagementCategory.renameCancel, product);
        },
    },
    [CreateRenameModalAction.renameMeasurement]: {
        title: 'DataView.Folder.RenameMeasurement',
        button: 'App.Confirm',
        placeholder: 'DataView.Folder.RenameMeasurement.Placeholder',
        logEvent: (product) => {
            analytics.logFileManagement(FileManagementCategory.rename, product);
        },
        logCancelEvent: (product) => {
            analytics.logFileManagement(FileManagementCategory.renameCancel, product);
        },
    },
};

interface CreateRenameModalProps {
    action: CreateRenameModalAction;
    visible: boolean;
    onCancel: () => void;
    currentName?: string;
    id?: string;
    fileType?: string;
    asciiPrintableName?: boolean;
}

const CreateRenameModal: React.FunctionComponent<CreateRenameModalProps> = (props) => {
    const { action, visible, onCancel, currentName, id = '', fileType = '', asciiPrintableName = false } = props;
    const { product, withUnsynced } = useDataViewContext();
    const formatMessage = useIntl().formatMessage;
    const [isLoading, setIsLoading] = useState(false);
    const [inputName, setInputName] = useState<string | undefined>(currentName);

    useEffect(() => {
        setInputName(currentName);
    }, [currentName]);

    const handleClose = (e: React.MouseEvent) => {
        e.stopPropagation();
        onCancel();
        setInputName(currentName);
        if (product) {
            FOLDER_MODAL_ACTION_MAP[action].logCancelEvent(product);
        }
    };

    const handleConfirm = async () => {
        setIsLoading(true);
        if (product && inputName) {
            try {
                switch (action) {
                    case CreateRenameModalAction.newFolder:
                        await createFolder({ name: inputName, product });
                        await getMeasurementFolders({ product, withUnsynced });
                        onCancel();
                        setInputName(undefined);
                        break;
                    case CreateRenameModalAction.renameFolder:
                        await renameFolder({ name: inputName, id });
                        await getMeasurementFolders({ product, withUnsynced });
                        onCancel();
                        break;
                    case CreateRenameModalAction.renameMeasurement:
                        if (fileType === BluetoothFileType.CustomMaterial) {
                            await renameCustomMaterial({ name: inputName, id });
                            await getCustomMaterialFullData({ id });
                        } else {
                            await renameMeasurement({
                                name: inputName,
                                id,
                                errorCallback: (error) =>
                                    error.response?.data?.code === RESOURCE_ALREADY_EXISTS_ERROR
                                        ? message.error(
                                              formatMessage({ id: 'Import.Error.FileNameExists' }, { count: 1 })
                                          )
                                        : handleError(error),
                            });
                            await getMeasurementFullData({ measurementID: id });
                        }
                        onCancel();
                        break;
                }
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
            } finally {
                FOLDER_MODAL_ACTION_MAP[action].logEvent(product);
            }
        }
    };

    return (
        <StyledModal
            title={<FormattedMessage id={FOLDER_MODAL_ACTION_MAP[action].title} />}
            open={visible}
            onCancel={handleClose}
            footer={[
                <React.Fragment key="folderModalFooter">
                    <Button type="primary" ghost onClick={handleClose}>
                        <FormattedMessage id="App.Cancel" />
                    </Button>
                    <Button
                        className={styles.create_button}
                        type="primary"
                        onClick={handleConfirm}
                        disabled={inputName === undefined || inputName.length === 0}
                        loading={isLoading}
                    >
                        <FormattedMessage id={FOLDER_MODAL_ACTION_MAP[action].button} />
                    </Button>
                </React.Fragment>,
            ]}
        >
            <Input
                value={inputName}
                placeholder={formatMessage({ id: FOLDER_MODAL_ACTION_MAP[action].placeholder })}
                onChange={(e) => {
                    if (asciiPrintableName) {
                        if (!e.target.value || (e.target.value && isNameASCIIPrintable(e.target.value))) {
                            setInputName(e.target.value);
                        } else {
                            message.error(formatMessage({ id: 'DataView.Upload.Rename.Error' }));
                        }
                    } else {
                        setInputName(e.target.value);
                    }
                }}
                maxLength={255}
            />
        </StyledModal>
    );
};

export default CreateRenameModal;
