import React, { useCallback, useEffect, useState } from 'react';
import { Badge, message } from 'antd';
// @ts-ignore
import { Convertor } from 'encryption-utils';
import { ReactComponent as IconNotification } from '../../images/iconNotification.svg';
import IconButton from '../shared/Buttons/IconButton';
import colors from '../../styles/colors.json';
import NotificationDrawer from './NotificationDrawer';
import { useNotificationUnreadCount } from './useNotifcationData';
import { getLocalItem, saveLocalItem } from '../../utils/storageUtils';
import { useFormatMessage } from '../../localization/useFormatMessage';
import useCurrentUserID from '../../hooks/useCurrentUser';

const NotificationButton: React.FunctionComponent = () => {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [isPushNotificationEnabled, setIsPushNotificationEnabled] = useState(true);

    const formatMessage = useFormatMessage();
    const userID = useCurrentUserID();

    const toggleDrawer = useCallback(() => {
        setDrawerVisible((visible) => !visible);
    }, []);

    const unreadCount = useNotificationUnreadCount();

    const requestPushNotification = useCallback(
        (showErrorMessage: boolean) => {
            const OneSignal = window.OneSignal;
            if (!OneSignal) {
                return;
            }

            OneSignal.push(() => {
                OneSignal.getNotificationPermission().then((result: string) => {
                    if (result === 'denied') {
                        if (showErrorMessage) {
                            message.warning(formatMessage({ id: 'Notifications.DeniedMessage' }));
                        }
                        if (!getLocalItem('onesignal-notification-prompt')) {
                            // Not triggered from one signal, need to setup manually to prevent checking
                            const record = {
                                value: '"dismissed"',
                                timestamp: Date.now(),
                            };
                            saveLocalItem('onesignal-notification-prompt', JSON.stringify(record));
                        }
                    } else {
                        OneSignal.showSlidedownPrompt({ force: true });
                    }
                });
            });
        },
        [formatMessage]
    );

    useEffect(() => {
        const OneSignal = window.OneSignal;
        if (!OneSignal || !userID) {
            return () => {};
        }

        const setupTag = () => {
            OneSignal.push(() => {
                OneSignal.sendTag('userID', Convertor.bytesToBase64(Convertor.sha256(userID.toString())));
            });
        };

        const checkAndRequestForPushNotification = () => {
            OneSignal.push(() => {
                OneSignal.isPushNotificationsEnabled((isEnabled: boolean) => {
                    if (isEnabled) {
                        setupTag();
                        setIsPushNotificationEnabled(true);
                        return;
                    }

                    // We only want to show once
                    if (!getLocalItem('onesignal-notification-prompt')) {
                        requestPushNotification(false);
                    }

                    // Show the button
                    setIsPushNotificationEnabled(false);
                });
            });
        };

        // Check for permission for first time
        checkAndRequestForPushNotification();

        const handlePermissionChange = () => {
            // When actual permission changed, see if we successfully subscribed the user
            // This will update the state if success
            checkAndRequestForPushNotification();
        };

        // Listen to changes and check permission change
        OneSignal.push(() => {
            OneSignal.on('notificationPermissionChange', handlePermissionChange);
        });
        OneSignal.push(() => {
            OneSignal.on('popoverClosed', handlePermissionChange);
        });

        // Clean up listener
        return () => {
            OneSignal.push(() => {
                OneSignal.off('notificationPermissionChange', handlePermissionChange);
            });
            OneSignal.push(() => {
                OneSignal.off('popoverClosed', handlePermissionChange);
            });
        };
    }, [requestPushNotification, userID]);

    return (
        <>
            <IconButton style={{ width: 40, height: 40, marginRight: 16 }} onClick={toggleDrawer}>
                <Badge
                    count={unreadCount}
                    overflowCount={Number.MAX_VALUE}
                    style={{ backgroundColor: colors.red700, color: colors.gray200 }}
                >
                    <IconNotification height={24} width={22} />
                </Badge>
            </IconButton>
            <NotificationDrawer
                visible={drawerVisible}
                toggleDrawer={toggleDrawer}
                isPushNotificationEnabled={isPushNotificationEnabled}
                requestPushNotification={() => requestPushNotification(true)}
            />
        </>
    );
};

export default NotificationButton;
