import { routeUtils } from 'tds-common-fe';
import moment from 'moment';
import { thunkGet, thunkPost, get, thunkDelete } from './apiService';
import { getApiURL } from './ApiURL';
import { APIError, handleError } from './error';
import { ProductCode, RecentMeasurementProducts, SharedURLParams } from '../types/proceq';
import * as measurementActions from '../actions/measurement';
import { APIResponse, MeasurementAPIResponse } from '../types/api';
import {
    Attachment,
    ImportMeasurementData,
    ImportMeasurementStatus,
    MeasurementFullData,
    MeasurementListItem,
    SmoothenedCurvedInfo,
    SystemFolderID,
} from '../types/measurement';
import { Log } from '../types/logs';
import { CustomMaterialItem } from '../types/customMaterial';
import config from '../config';
import { CustomCurveProbeInfo } from '../types/customCurve';

export enum OrderDir {
    ASC = 'asc',
    DESC = 'desc',
    None = '',
}

export interface RuleConfig {
    op: '=' | '>' | '<' | '>=' | '<=';
    field: string;
    value: string;
}

export type FilterParams = {
    operator: 'AND';
    orderBy: string;
    orderDir?: OrderDir;
    rules?: RuleConfig[];
};

export interface GetMeasurementListParams {
    fileType?: string;
    folderID?: string;
    limit?: number;
    offset?: number;
    orderBy?: string;
    orderDir?: OrderDir;
    product: ProductCode;
    search?: string;
    filter?: { [key: string]: FilterParams };
    withUnsynced?: boolean;
    productModel?: string;
    probeTypeId?: number;
    archived?: boolean;
    includeSize?: boolean;
    mIDs?: string[];
    isPolling?: boolean;
}

export const getMeasurementList = async (params: GetMeasurementListParams) => {
    type Response = MeasurementAPIResponse<MeasurementListItem[]>;
    const { isPolling, ...otherParams } = params;
    const url = getApiURL('MEASUREMENT_LIST');
    const { response, dispatch } = await thunkPost<Response>(
        params.includeSize ? routeUtils.makeQueryPath(url, { includeSize: params.includeSize.toString() }) : url,
        {
            errorHandler: handleError,
            params: {
                fileType: 'all',
                folderID: SystemFolderID.All,
                limit: 20,
                offset: 0,
                productFamily: params.product,
                ...otherParams,
            },
            injectToken: true,
        }
    );
    if (isPolling) {
        dispatch(
            measurementActions.receivedPolledMeasurements({
                product: params.product,
                measurements: response.data,
            })
        );
    } else {
        dispatch(
            measurementActions.receivedMeasurementList({
                product: params.product,
                measurements: response.data,
                folderID: params.folderID || SystemFolderID.All,
                totalCount: response.totalRecords,
                offset: response.offset,
                fileType: params.fileType,
                limit: params.limit,
                archived: params.archived,
            })
        );
    }
    return response;
};

export const getRecentMeasurements = async () => {
    type Response = MeasurementAPIResponse<MeasurementListItem[]>;
    const url = getApiURL('MEASUREMENT_LIST');
    const { response, dispatch } = await thunkPost<Response>(url, {
        errorHandler: handleError,
        params: {
            fileType: 'all',
            limit: 20,
            offset: 0,
            productFamilies: RecentMeasurementProducts,
            orderBy: 'clientUpdated',
            orderDir: OrderDir.DESC,
        },
        injectToken: true,
    });

    dispatch(
        measurementActions.receivedRecentMeasurements({
            measurements: response.data,
        })
    );
    return response;
};

export const getMeasurementFullData = async (params: { measurementID: string }) => {
    const { measurementID } = params;
    type Response = MeasurementFullData;
    const url = getApiURL('MEASUREMENT').replace('{measurementID}', measurementID);
    const { response, dispatch } = await thunkGet<Response>(url, {
        errorHandler: handleError,
        injectToken: true,
    });

    dispatch(
        measurementActions.receivedMeasurement({
            measurementID,
            measurement: response,
        })
    );

    return response;
};

export const getMeasurementLogs = async (params: { measurementID: string }) => {
    const { measurementID } = params;
    type Response = MeasurementAPIResponse<Log[]>;
    const url = getApiURL('MEASUREMENT_LOGS');
    const { response, dispatch } = await thunkGet<Response>(url, {
        errorHandler: handleError,
        injectToken: true,
        params: {
            mID: measurementID,
        },
    });

    dispatch(
        measurementActions.receivedMeasurementLogs({
            measurementID,
            logs: response.data,
        })
    );
};

export const getMeasurementAttachments = async (params: { product: ProductCode; measurementID: string }) => {
    const { product, measurementID } = params;
    type Response = MeasurementAPIResponse<Attachment[]>;
    const url = getApiURL('MEASUREMENT_ATTACHMENTS');
    const { response, dispatch } = await thunkGet<Response>(url, {
        errorHandler: handleError,
        injectToken: true,
        params: {
            product,
            mID: measurementID,
        },
    });

    dispatch(
        measurementActions.receivedMeasurementAttachments({
            measurementID,
            attachments: response.data,
        })
    );
};

export interface GetCustomMaterialListParams {
    product: ProductCode;
    type?: 'CUSTOM_MATERIAL';
    limit?: number;
    offset?: number;
    filter?: string;
    search?: string;
    orderBy?: string;
    orderDir?: OrderDir;
    withUnsynced?: boolean;
}

export const getCustomMaterialList = async (params: GetCustomMaterialListParams) => {
    type Response = MeasurementAPIResponse<CustomMaterialItem[]>;
    const url = getApiURL('CUSTOM_MATERIAL_LIST');
    const { response, dispatch } = await thunkGet<Response>(url, {
        errorHandler: handleError,
        params: {
            type: 'CUSTOM_MATERIAL',
            limit: 20,
            offset: 0,
            ...params,
        },
        injectToken: true,
    });

    dispatch(
        measurementActions.receivedCustomMaterialList({
            product: params.product,
            customMaterials: response.data,
            totalCount: response.totalRecords,
            offset: response.offset,
            limit: params.limit,
        })
    );
    return response;
};

export const getCustomMaterialFullData = async (params: { id: string }) => {
    const { id } = params;
    type Response = CustomMaterialItem;
    const url = getApiURL('CUSTOM_MATERIAL').replace('{id}', id);
    const { response, dispatch } = await thunkGet<Response>(url, {
        errorHandler: handleError,
        injectToken: true,
    });
    dispatch(measurementActions.receivedCustomMaterial({ id, customMaterial: response }));
};

export const checkSharedURLValidity = async (key: string) => {
    const url = getApiURL('GET_SHARED_MEASUREMENTS', { key });
    const response = await get<any>(
        url,
        {
            injectToken: false,
            params: {
                skipData: true,
            },
        },
        true
    );
    if (response.status !== 200) {
        throw new Error('Invalid Shared URL');
    }
};

interface APIResponseHTMLViewer<D> {
    data: D;
    token: string;
}

export const getExportMeasurementList = async (key: string, product: ProductCode) => {
    type Response = APIResponseHTMLViewer<MeasurementFullData[]>;
    const url = getApiURL('GET_SHARED_MEASUREMENTS', { key });
    const { response, dispatch } = await thunkGet<Response>(url, {
        injectToken: false,
        params: {
            product,
        },
    });

    dispatch(measurementActions.receivedHTMLExportMeasurements({ product, data: response.data }));
    return response;
};

interface ImportMeasurementResponse {
    productFamily: string;
    jobID: string;
    imports: Omit<ImportMeasurementData, 'timestamp'>[];
}

export const importMeasurements = async (
    product: ProductCode,
    params: SharedURLParams,
    errorCallback: (error: APIError) => void
) => {
    const url = getApiURL('IMPORT_MEASUREMENTS');
    const path = `${config.PROCEQ_DOMAIN}/m/${product.toLowerCase()}`;
    const sharedURL = routeUtils.makeQueryPath(path, { ...params });
    const { response, dispatch } = await thunkPost<ImportMeasurementResponse>(url, {
        errorHandler: errorCallback,
        params: {
            url: sharedURL,
            timeZoneOffset: moment().utcOffset(),
        },
        injectToken: true,
    });

    const { imports, jobID } = response;
    const timestamp = Date.now();
    const measurements: ImportMeasurementData[] = imports.map((val) => ({ ...val, timestamp }));
    dispatch(measurementActions.importMeasurements({ product, measurements, jobID }));
};

interface GetImportMeasurementsStatusParams {
    product: ProductCode;
    jobID: string;
    status?: ImportMeasurementStatus;
}

export const getImportMeasurementsStatus = async (params: GetImportMeasurementsStatusParams) => {
    const { product } = params;
    const url = getApiURL('GET_IMPORT_MEASUREMENTS_STATUS');
    const apiParams = { ...params, productFamily: product };
    const { response, dispatch } = await thunkGet<ImportMeasurementResponse>(url, {
        params: apiParams,
        injectToken: true,
    });

    const { imports } = response;
    dispatch(measurementActions.receivedImportMeasurementsStatus({ product, measurements: imports }));
};

interface RenameParams {
    id: string;
    name: string;
    errorCallback?: (error: APIError) => void;
}

export const renameMeasurement = async (params: RenameParams) => {
    const { id, name, errorCallback } = params;
    const url = getApiURL('RENAME_MEASUREMENT').replace('{measurementID}', id);
    await thunkPost<Response>(url, {
        errorHandler: errorCallback ?? handleError,
        params: { newName: name },
        injectToken: true,
    });
};

export const renameCustomMaterial = async (params: RenameParams) => {
    const { id, name } = params;
    const url = getApiURL('RENAME_CUSTOM_MATERIAL').replace('{id}', id);
    await thunkPost<Response>(url, {
        errorHandler: handleError,
        params: { newName: name },
        injectToken: true,
    });
};

interface FileManagementResponse {
    passed: { id: string; additionalInfo?: string }[];
    failed: { id: string; reason: string }[];
}

interface MoveMeasurementsParams {
    product: string;
    items: { id: string; to: string; from: string }[];
}

export const moveMeasurement = async (params: MoveMeasurementsParams) => {
    const url = getApiURL('MOVE_MEASUREMENT');
    const { response } = await thunkPost<FileManagementResponse>(url, {
        errorHandler: handleError,
        params: { ...params },
        injectToken: true,
    });
    return response;
};

interface FlagParams {
    id: string;
    flagStatus: boolean;
}

export const flagMeasurement = async (params: FlagParams) => {
    const { id, flagStatus } = params;
    const url = getApiURL('FLAG_MEASUREMENT')
        .replace('{measurementID}', id)
        .replace('{flagStatus}', flagStatus.toString());
    await thunkPost<Response>(url, {
        errorHandler: handleError,
        injectToken: true,
    });
};

export const flagCustomMaterial = async (params: FlagParams) => {
    const { id, flagStatus } = params;
    const url = getApiURL('FLAG_CUSTOM_MATERIAL').replace('{id}', id).replace('{flagStatus}', flagStatus.toString());
    await thunkPost<Response>(url, {
        errorHandler: handleError,
        injectToken: true,
    });
};

interface PermanentDeleteParams {
    ids: string[];
    product: ProductCode;
}

export const permanentDeleteMeasurements = async (params: PermanentDeleteParams) => {
    const url = getApiURL('PERMANENT_DELETE_MEASUREMENTS');
    const { response } = await thunkDelete<FileManagementResponse>(url, {
        errorHandler: handleError,
        params: { ...params },
        injectToken: true,
    });
    return response;
};

export const deleteCustomMaterial = async (params: PermanentDeleteParams) => {
    const url = getApiURL('DELETE_CUSTOM_MATERIAL');
    const { response } = await thunkDelete<FileManagementResponse>(url, {
        errorHandler: handleError,
        params: { ...params },
        injectToken: true,
    });
    return response;
};

interface DeleteRestoreMeasurementsParams {
    ids: string[];
    product: ProductCode;
    isDelete: boolean;
}

export const deleteRestoreMeasurements = async (params: DeleteRestoreMeasurementsParams) => {
    const { ids, product, isDelete } = params;
    const url = getApiURL('DELETE_RESTORE_MEASUREMENTS').replace('{isDelete}', isDelete.toString());
    const { response } = await thunkPost<FileManagementResponse>(url, {
        errorHandler: handleError,
        params: { ids, product },
        injectToken: true,
    });
    return response;
};

interface CheckMeasurementNameExistsParams {
    product: string;
    name: string;
    type: string;
}

export const checkMeasurementNameExists = async (params: CheckMeasurementNameExistsParams) => {
    const url = routeUtils.makeQueryPath(getApiURL('MEASUREMENT_NAME_EXISTS'), { ...params });
    return thunkGet<APIResponse<{ exists: boolean }>>(url, {
        errorHandler: handleError,
        injectToken: true,
    });
};

interface ArchiveRestoreMeasurementsParams {
    ids: string[];
    product: ProductCode;
    isArchive: boolean;
    errorCallback: () => void;
}

export const archiveRestoreMeasurements = async (params: ArchiveRestoreMeasurementsParams) => {
    const { ids, product, errorCallback, isArchive } = params;
    const { response } = await thunkPost<APIResponse<string[]>>(getApiURL('ARCHIVE_MEASUREMENTS'), {
        errorHandler: errorCallback,
        params: { ids, archived: isArchive, product },
        injectToken: true,
    });
    return response.data;
};

interface SmoothenDGSCurveParams extends CustomCurveProbeInfo {
    product: string;
    csv: string;
    onErrorCallback: () => void;
    label: string;
}

export const smoothenDGSCurve = async (params: SmoothenDGSCurveParams) => {
    const { product, onErrorCallback, ...requestParams } = params;
    const url = routeUtils.makeQueryPath(getApiURL('DGS_SMOOTHEN'), { product });
    const { response } = await thunkPost<APIResponse<SmoothenedCurvedInfo>>(url, {
        errorHandler: (error) => {
            onErrorCallback();
            handleError(error);
        },
        injectToken: true,
        params: { ...requestParams },
    });
    return response;
};

interface CreateMeasurementParams {
    name: string;
    type: string;
    productModel: string;
    productFamily: string;
    content: any;
    probeInfo: CustomCurveProbeInfo;
    readings: {
        type: string;
        attachment: {
            type: string;
            fID: string;
        };
        content?: any;
    }[];
    settings: any;
}

export const createMeasurement = async (params: CreateMeasurementParams) => {
    const url = getApiURL('CREATE_MEASUREMENT');
    return thunkPost<{ mID: string }>(url, {
        errorHandler: handleError,
        injectToken: true,
        params: { ...params },
    });
};
