import { getApiURL } from './ApiURL';
import { thunkGet, thunkPost } from './apiService';
import { APIResponse, SyncMeta } from '../types/api';
import { NotificationAggregation, NotificationRecord } from '../types/notification';
import * as notificationActions from '../actions/notification';

const PAGE_SIZE = 20;

export interface GetNotificationsParams {
    idx: number;
    sync: boolean;
    updateAllNotifications?: boolean;
}

export const getNotifications = async (params: GetNotificationsParams) => {
    type Response = APIResponse<NotificationRecord[], { sync: SyncMeta; aggregation: NotificationAggregation }>;
    const { idx, sync, updateAllNotifications = false } = params;

    const url = getApiURL('GET_NOTIFICATIONS');
    const { response, dispatch } = await thunkGet<Response>(url, {
        params: {
            idx,
            sort: sync ? 1 : -1,
            size: PAGE_SIZE,
            product: 'workspace',
        },
    });

    const { data: notifications, meta } = response;

    if (updateAllNotifications) {
        dispatch(
            notificationActions.updateAllNotifications({
                notifications,
                meta: meta.sync,
                aggregation: meta.aggregation,
                sync,
            })
        );
    } else {
        dispatch(
            notificationActions.receiveNotifications({
                notifications,
                meta: meta.sync,
                aggregation: meta.aggregation,
                sync,
            })
        );
    }
    return response;
};

export const markAsRead = async (notificationID: string) => {
    type Response = APIResponse<NotificationRecord[], undefined, NotificationAggregation>;

    const url = getApiURL('NOTIFICATION_MARK_AS_READ', { notificationID });
    const { response, dispatch } = await thunkPost<Response>(url);

    const { data: notifications, aggregation } = response;
    dispatch(
        notificationActions.readNotification({
            notifications,
            aggregation,
        })
    );
};

export const markAllAsRead = async () => {
    type Response = APIResponse<{ count: number }>;

    const url = getApiURL('NOTIFICATION_MARK_ALL_AS_READ');
    const { dispatch } = await thunkPost<Response>(url);

    dispatch(notificationActions.readAllNotifications());

    // update all notifications instead of using current idx to avoid getting old notifications that were recently updated
    await getNotifications({ sync: false, idx: 0, updateAllNotifications: true });
};

export const clearAllNotifications = async () => {
    type Response = APIResponse<{ count: number }>;

    const url = getApiURL('NOTIFICATION_CLEAR_ALL');
    const { dispatch } = await thunkPost<Response>(url);

    dispatch(notificationActions.clearAllNotifications());
};
