import React, { useEffect } from 'react';
import { Card, Tree } from 'antd';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import ViewerWrapper from './ViewerWrapper';
import FormattedMessage from '../../localization/FormatMessage';
import { useMeasurements, useProductData } from '../../hooks/useProductData';
import { ProductCode } from '../../types/proceq';

const { DirectoryTree } = Tree;

const StandaloneMeasurementListing: React.FunctionComponent = () => {
    const { productCode } = useParams<{ productCode: ProductCode }>();
    const { folderIDs = [], measurementIDs = {}, folders = {} } = useProductData(productCode);
    const measurements = useMeasurements();
    const formatMessage = useIntl().formatMessage;

    useEffect(() => {
        window.viewerReady = true;
    }, []);

    const treeData = folderIDs.map((folderID) => ({
        title: folders[folderID]?.name ?? formatMessage({ id: 'DataView.Folder.All' }),
        key: folderID,
        children: (measurementIDs[folderID] || []).map((measurementID) => ({
            title: (
                <a href={`${encodeURIComponent(measurements[measurementID].uniqueName || '')}/index.html`}>
                    {measurements[measurementID].uniqueName}
                </a>
            ),
            key: measurementID,
            isLeaf: true,
        })),
    }));

    return (
        <div style={{ display: 'flex', width: '100%' }}>
            <ViewerWrapper productCode={productCode}>
                <Card title={<FormattedMessage id="App.HTML.TableOfContents" />}>
                    <DirectoryTree defaultExpandAll treeData={treeData} selectable={false} />
                </Card>
            </ViewerWrapper>
        </div>
    );
};

export default StandaloneMeasurementListing;
