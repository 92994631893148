import React from 'react';
import { Dropdown } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { saveAs } from 'file-saver';
import StyledModal from '../../../shared/StyledModal';
import styles from './PreviewModal.styl';
import BackButton from '../../../shared/Buttons/BackButton';
import FormattedMessage from '../../../../localization/FormatMessage';
import AnalyticsButton from '../../../AnalyticsComponents/Button';
import { AntdMenuItem } from '../../../../types/antd';
import { ReactComponent as ArrowIconDown } from '../../../../images/iconArrowDown.svg';
import CustomCurveLabel from '../CustomCurveLabel';
import { SmoothenedCurvedInfo } from '../../../../types/measurement';
import { CustomCurveFormFieldNames, TRANSDUCER_TYPE_MAP, TransducerType } from '../../../../types/customCurve';
import analytics from '../../../../analytics/firebaseAnalytics';
import { UploadCustomCurveAction } from '../../../../analytics/analyticsConstants';
import { getBlobFromBase64Image } from '../../../../utils/fileUtils';

interface PreviewModalProps {
    visible: boolean;
    onClose: () => void;
    form: FormInstance<CustomCurveFormFieldNames>;
    smoothenedCurveInfo: SmoothenedCurvedInfo;
    onUpload: () => void;
    requiredFields: CustomCurveFormFieldNames[];
    product: string;
}

const PreviewModal: React.FunctionComponent<PreviewModalProps> = (props) => {
    const { visible, onClose, form, onUpload, smoothenedCurveInfo, requiredFields, product } = props;

    const menuItems: AntdMenuItem[] = [
        {
            key: 'colored',
            onClick: () => {
                getBlobFromBase64Image(smoothenedCurveInfo.colorPlotImg).then((blob) => saveAs(blob, 'colorPlot.png'));
                analytics.logUploadCustomCurve(UploadCustomCurveAction.previewDownloadColored, product);
            },
            label: <FormattedMessage id="DataView.Upload.Preview.DownloadCurve.Colored" />,
        },
        {
            key: 'blackAndWhite',
            onClick: () => {
                getBlobFromBase64Image(smoothenedCurveInfo.bwPlotImg).then((blob) => saveAs(blob, 'bwPlot.png'));
                analytics.logUploadCustomCurve(UploadCustomCurveAction.previewDownloadBlackAndWhite, product);
            },
            label: <FormattedMessage id="DataView.Upload.Preview.DownloadCurve.BlackAndWhite" />,
        },
    ];

    return (
        <StyledModal
            width={856}
            open={visible}
            title={<FormattedMessage id="DataView.Upload.Preview" />}
            closable={false}
            footer={
                <div className={styles.footer_container}>
                    <BackButton onClick={onClose} buttonText="App.Back" />
                    <div className={styles.right_footer_container}>
                        <Dropdown
                            menu={{ items: menuItems }}
                            placement="bottomLeft"
                            trigger={['click']}
                            getPopupContainer={(node) => node.parentElement?.parentElement ?? node}
                        >
                            <AnalyticsButton
                                className={styles.download_curve}
                                type="primary"
                                ghost
                                onClick={() =>
                                    analytics.logUploadCustomCurve(
                                        UploadCustomCurveAction.previewDownloadCurve,
                                        product
                                    )
                                }
                            >
                                <FormattedMessage id="DataView.Upload.Preview.DownloadCurve" />
                                <ArrowIconDown />
                            </AnalyticsButton>
                        </Dropdown>
                        <AnalyticsButton type="primary" onClick={onUpload}>
                            <FormattedMessage id="DataView.Upload.Preview.Upload" />
                        </AnalyticsButton>
                    </div>
                </div>
            }
        >
            <div className={styles.body}>
                <div>
                    {smoothenedCurveInfo?.colorPlotImg && (
                        <div className={styles.image_container}>
                            <img src={`data:image/png;base64,${smoothenedCurveInfo?.colorPlotImg}`} alt={'curve'} />
                        </div>
                    )}
                    <div className={styles.caption_header}>
                        <FormattedMessage id="DataView.Upload.Preview.ImageSubtitle" />
                    </div>
                </div>
                <div>
                    <div>
                        <FormattedMessage id="DataView.Upload.Preview.CurveProbeInfo" />
                    </div>
                    <div className={styles.form_fields}>
                        {requiredFields.map((field) =>
                            form.getFieldValue(field) !== undefined ? (
                                <div key={field}>
                                    <div className={styles.caption_header}>
                                        <CustomCurveLabel
                                            fieldName={field as CustomCurveFormFieldNames}
                                            includeUnits
                                            prefixTransducerFields
                                        />
                                    </div>
                                    <div>
                                        {field === CustomCurveFormFieldNames.transducerType ? (
                                            <FormattedMessage
                                                id={TRANSDUCER_TYPE_MAP[form.getFieldValue(field) as TransducerType]}
                                            />
                                        ) : (
                                            form.getFieldValue(field)
                                        )}
                                    </div>
                                </div>
                            ) : null
                        )}
                    </div>
                </div>
            </div>
        </StyledModal>
    );
};

export default PreviewModal;
