import { noop } from 'lodash';
import React, { createContext } from 'react';
import { FilterParams, OrderDir } from '../../api/measurementService';
import { BluetoothFileType, CustomCurveFileType, ViewType } from '../../types/measurement';
import { ProductCode } from '../../types/proceq';
import { stringEnumToArray } from '../../utils/generalUtils';

export enum DataViewMode {
    'Logbook',
    'JSON',
    'Attachments',
}

export const productDataCategoryRoutes = stringEnumToArray({ ...BluetoothFileType, ...CustomCurveFileType });

export interface ViewModeConfig {
    viewMode?: DataViewMode;
    measurementID?: string;
    customMaterialID?: string;
}

export interface OrderConfig {
    orderBy?: string;
    orderDir?: OrderDir;
}
export const defaultOrderConfig: OrderConfig = {
    orderBy: 'clientUpdated',
    orderDir: OrderDir.DESC,
};

export interface FilterConfig extends FilterParams {
    category: string;
}

export enum NonEditableType {
    move = 1,
    edit,
    editFolder,
    archiveFolder,
    restoreFolder,
}

export interface NonEditableMeasurements {
    allInvalid: boolean;
    measurementIds: string[];
    nonEditableType?: NonEditableType;
}

export interface DataViewContextState {
    fetchMeasurementList: (changePage?: boolean) => Promise<void>;
    fetchCustomMaterialList: (changePage?: boolean) => Promise<void>;
    isFetching: boolean;

    modalViewerConfig: ViewModeConfig;
    setModalViewerConfig: (config: ViewModeConfig) => void;
    openMeasurementInNewTab: (id: string) => void;

    product: ProductCode | undefined;
    setProduct: (productCode: ProductCode, folderID?: string) => void;

    viewType: ViewType;
    setViewType: (viewType: ViewType) => void;

    activeFolder: string;
    setActiveFolder: (folderID: string) => void;

    resetConfig: () => void;

    page: number;
    setPage: (page: number) => void;
    pageSize: number;
    setPageSize: (pageSize: number) => void;

    orderConfig: OrderConfig | undefined;
    setOrderConfig: (config: OrderConfig | undefined) => void;

    filterConfig: { [key: string]: FilterConfig };
    setFilterConfig: React.Dispatch<React.SetStateAction<{ [key: string]: FilterConfig }>>;

    searchText: string;
    setSearchText: (value: string) => void;

    fileType: string;
    setFileType: (value: string) => void;

    productModel: string | undefined;
    setProductModel: (value: string | undefined) => void;

    probeTypeId: number | undefined;
    setProbeTypeId: (value: number | undefined) => void;

    withUnsynced: boolean;
    setWithUnsynced: (value: ((prevState: boolean) => boolean) | boolean) => void;

    showHaze: boolean;
    setShowHaze: (value: ((prevState: boolean) => boolean) | boolean) => void;

    nonEditableMeasurements: undefined | NonEditableMeasurements;
    setNonEditableMeasurements: (value: undefined | NonEditableMeasurements) => void;

    selectedKeys: string[];
    setSelectedKeys: React.Dispatch<React.SetStateAction<string[]>>;
}

export const DataViewContext = createContext<DataViewContextState>({
    fetchMeasurementList: async () => {},
    fetchCustomMaterialList: async () => {},
    isFetching: false,

    modalViewerConfig: {},
    setModalViewerConfig: noop,
    openMeasurementInNewTab: noop,

    product: undefined,
    setProduct: noop,

    viewType: ViewType.Active,
    setViewType: noop,

    activeFolder: '',
    setActiveFolder: noop,

    page: 1,
    setPage: noop,
    pageSize: 20,
    setPageSize: noop,

    resetConfig: noop,

    orderConfig: undefined,
    setOrderConfig: noop,

    filterConfig: {},
    setFilterConfig: noop,

    searchText: '',
    setSearchText: noop,

    fileType: '',
    setFileType: noop,

    productModel: '',
    setProductModel: noop,

    probeTypeId: undefined,
    setProbeTypeId: noop,

    withUnsynced: false,
    setWithUnsynced: noop,

    showHaze: true,
    setShowHaze: noop,

    nonEditableMeasurements: undefined,
    setNonEditableMeasurements: noop,

    selectedKeys: [],
    setSelectedKeys: noop,
});
