import { Empty } from 'antd';
import React from 'react';
import { ReactComponent as NoDataImage } from '../../images/noData.svg';
import styles from './MyEmpty.styl';
import FormattedMessage from '../../localization/FormatMessage';
import { classNames } from '../../utils/styleUtils';

interface MyEmptyProps {
    isArchiveViewType?: boolean;
}

const MyEmpty: React.FunctionComponent<MyEmptyProps> = ({ isArchiveViewType }) => {
    const description = isArchiveViewType
        ? {
              description: (
                  <>
                      <div>
                          <FormattedMessage id="DataView.Measurement.Archive.NoData" />
                      </div>
                      <div style={{ fontSize: 12 }}>
                          <FormattedMessage id="DataView.Measurement.Archive.NoData.Subtitle" />
                      </div>
                  </>
              ),
          }
        : {};
    return (
        <Empty
            className={classNames(styles.my_empty, isArchiveViewType ? styles.archived : '')}
            imageStyle={{ height: 'auto' }}
            image={<NoDataImage className={styles.my_empty_image} />}
            {...description}
        />
    );
};

export const tableLocaleSetting = { emptyText: <MyEmpty /> };
export const archivedTableLocaleSetting = { emptyText: <MyEmpty isArchiveViewType /> };

export default MyEmpty;
