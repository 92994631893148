import { ScanType } from './proceq';
import { FormatIDs } from './reactIntl';

export enum ProbeType {
    ONE_WHEEL = '1 Wheel',
    FOUR_WHEELS = '4 Wheels',
    ROD = 'Rod',
}

export const PROBE_TYPE_TEXT: Record<ProbeType, FormatIDs> = {
    [ProbeType.ROD]: 'App.HTML.Profometer.Logbook.ProbeType.Rod',
    [ProbeType.ONE_WHEEL]: 'App.HTML.Profometer.Logbook.ProbeType.1Wheel',
    [ProbeType.FOUR_WHEELS]: 'App.HTML.Profometer.Logbook.ProbeType.4Wheels',
};

export const CORROSION_SCAN_TYPES = new Set([ScanType.Advanced, ScanType.Basic]);
