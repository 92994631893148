import React, { useState } from 'react';
import { Button } from 'antd';
import styles from './Notification.styl';
import FormattedMessage from '../../localization/FormatMessage';
import * as notificationService from '../../api/notificationService';

interface NotificationHeaderProps {
    date: string;
    showAction: boolean;
    unreadCount: number;
}

const NotificationHeader: React.FunctionComponent<NotificationHeaderProps> = (props) => {
    const { date, showAction, unreadCount } = props;
    const [isActionLoading, setIsActionLoading] = useState(false);

    const isReadAll = unreadCount > 0;

    const handleClick = () => {
        setIsActionLoading(true);
        if (isReadAll) {
            notificationService.markAllAsRead().finally(() => setIsActionLoading(false));
        } else {
            // If it succeed, then this header will be unmount.
            // Only need to handle error cases
            notificationService.clearAllNotifications().catch(() => setIsActionLoading(false));
        }
    };

    return (
        <div className={styles.header}>
            {date}
            {showAction && (
                <Button type="link" size="small" onClick={handleClick} loading={isActionLoading}>
                    <FormattedMessage id={isReadAll ? 'Notification.ReadAll' : 'Notification.ClearAll'} />
                </Button>
            )}
        </div>
    );
};

export default NotificationHeader;
