import React, { useState } from 'react';
import { message } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import Header from '../shared/Header';
import styles from './MeasurementImport.styl';
import ImportMessage from './ImportMessage';
import FormattedMessage from '../../localization/FormatMessage';
import useMeasurementImportParams from './useMeasurementImportParams';
import * as measurementService from '../../api/measurementService';
import { useFormatMessage } from '../../localization/useFormatMessage';
import { getDataViewRoute } from '../Routes/urls';
import {
    CustomCurveFileType,
    RESOURCE_ALREADY_EXISTS_ERROR,
    SHARED_LINK_CURVE_TYPE,
    SystemFolderID,
} from '../../types/measurement';
import { ProductCode } from '../../types/proceq';
import { handleError } from '../../api/error';

const MeasurementImport: React.FunctionComponent = () => {
    const { k, d, t, n, appName, history, productCode, handleGotoHomepage, productName } = useMeasurementImportParams();
    const formatMessage = useFormatMessage();

    const [isLoading, setIsLoading] = useState(false);
    const [isImportInProgress, setIsImportInProgress] = useState(false);
    const isInsights = productCode === ProductCode.GPR_INSIGHTS;
    const isDGSCurve = t === SHARED_LINK_CURVE_TYPE;

    const handleImport = () => {
        async function action() {
            try {
                if (!k || !d || !n) {
                    message.error(formatMessage({ id: 'Import.InvalidURL.Message' }));
                    return;
                }
                setIsLoading(true);

                // Wait for actions to perform
                await measurementService.importMeasurements(
                    productCode,
                    { k, d, n },
                    isDGSCurve
                        ? (error) => {
                              if (error.response?.data?.code === RESOURCE_ALREADY_EXISTS_ERROR) {
                                  history.push(
                                      getDataViewRoute({
                                          productName: productName || productCode,
                                          folderID: CustomCurveFileType.dgscc,
                                      }),
                                      { customCurveNameConflicts: error.response?.data?.errors?.name }
                                  );
                              } else {
                                  handleError(error);
                              }
                          }
                        : handleError
                );

                if (isInsights) {
                    setIsImportInProgress(true);
                } else if (isDGSCurve) {
                    history.push(
                        getDataViewRoute({
                            productName: productName || productCode,
                            folderID: CustomCurveFileType.dgscc,
                        })
                    );
                } else {
                    // Send to the measurement page
                    history.push(getDataViewRoute({ productName: productName || productCode }), {
                        folderID: SystemFolderID.Imported,
                    });
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
                setIsLoading(false);
            }
        }
        action();
    };

    const importTitle = isInsights ? (
        <FormattedMessage id="Import.Project.Title" values={{ count: n, appName }} />
    ) : (
        <FormattedMessage
            id={isDGSCurve ? 'Import.Measurement.Curve.Title' : 'Import.Measurement.Title'}
            values={{ count: n }}
        />
    );

    const importMessage = (
        <div style={{ textAlign: 'center' }}>
            {isInsights ? (
                <FormattedMessage id="Import.Project.Message" values={{ count: n, appName }} />
            ) : (
                <FormattedMessage
                    id={isDGSCurve ? 'Import.Measurement.Curve.Message' : 'Import.Measurement.Message'}
                    values={{ count: n, appName }}
                />
            )}
        </div>
    );

    return (
        <div className={styles.container}>
            <Header hasLogo />
            <div className={styles.content_container}>
                {isImportInProgress ? (
                    <ImportMessage
                        title={<FormattedMessage id="Import.Project.InProgress" />}
                        primaryAction={{
                            title: 'Import.GotoHomepage',
                            action: handleGotoHomepage,
                            type: 'default',
                            fillWidth: false,
                        }}
                    />
                ) : (
                    <ImportMessage
                        Icon={CloudUploadOutlined}
                        title={importTitle}
                        message={importMessage}
                        primaryAction={{
                            title: 'App.Import',
                            action: handleImport,
                            loading: isLoading,
                        }}
                        secondaryAction={{
                            title: 'Import.GotoHomepage',
                            action: handleGotoHomepage,
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default MeasurementImport;
