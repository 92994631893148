import React from 'react';
import { Button as AntdButton } from 'antd';
import { ButtonProps as AntdButtonProps } from 'antd/lib/button';
import analytics from '../../analytics/firebaseAnalytics';

interface AnalyticsButtonProps extends AntdButtonProps {
    logAnalytics?: boolean;
}

const AnalyticsButton: React.FunctionComponent<AnalyticsButtonProps> = ({ logAnalytics = false, ...props }) => {
    const onClicking = (event: React.PointerEvent<HTMLElement>) => {
        const buttonText = event?.currentTarget?.getAttribute('name') || event?.currentTarget?.textContent;
        if (logAnalytics && buttonText) analytics.logButtonClick(buttonText);
        if (props.onClick) props.onClick(event);
    };

    return (
        <AntdButton {...props} onClick={onClicking}>
            {props.children}
        </AntdButton>
    );
};

export default AnalyticsButton;
