import React, { useContext, useState } from 'react';
import { MeasurementModalContext } from './MeasurementModalContext';

const MeasurementModalProvider: React.FunctionComponent = (props) => {
    const [showRenameMeasurement, setShowRenameMeasurement] = useState(false);
    const [showCreateFolder, setShowCreateFolder] = useState(false);
    const [showMoveMeasurement, setShowMoveMeasurement] = useState(false);
    const [showShareMeasurement, setShowShareMeasurement] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [measurementName, setMeasurementName] = useState<string | undefined>();
    const [measurementID, setMeasurementID] = useState<string | undefined>();
    return (
        <MeasurementModalContext.Provider
            value={{
                showRenameMeasurement,
                setShowRenameMeasurement,
                showCreateFolder,
                setShowCreateFolder,
                showMoveMeasurement,
                setShowMoveMeasurement,
                showShareMeasurement,
                setShowShareMeasurement,
                showDeleteConfirmationModal,
                setShowDeleteConfirmationModal,
                measurementName,
                setMeasurementName,
                measurementID,
                setMeasurementID,
            }}
        >
            {props.children}
        </MeasurementModalContext.Provider>
    );
};

export default MeasurementModalProvider;

export const useMeasurementModalContext = () => {
    return useContext(MeasurementModalContext);
};
