export function getFileExtension(fileName: string) {
    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(fileName);
    if (ext && ext[1]) {
        return ext[1].toLowerCase();
    }
    throw new Error('The file type is undefined');
}

export function getFileNameWithExpectedExtensions(fileNames: string[], extensions: string[]) {
    for (const ext of extensions) {
        const fileName = fileNames.find((f) => getFileExtension(f) === ext);
        if (fileName) {
            return fileName;
        }
    }

    return null;
}

export function getFileNameFromContentDisposition(contentDisposition: string) {
    const fileNames = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition) || [''];
    return fileNames.length > 1 ? decodeURIComponent(fileNames[1].substring(7)) : '';
}

export const fileToDataBlob = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const dataBlob = (reader.result as string).split(',')[1];
            resolve(dataBlob);
        };
        reader.onerror = (error) => {
            reject(error);
        };
    });
};

export const fileToArrayBuffer = (file: File) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            resolve(e.target?.result);
        };
        reader.onerror = (e) => {
            reject(e);
        };
        reader.readAsArrayBuffer(file);
    });
};

export function limitImageSize({ height, width, limit }: { height: number; width: number; limit: number }) {
    if (height > limit && height >= width) {
        return {
            height: Math.round(limit),
            width: Math.round(width * (limit / height)),
        };
    }
    if (width > limit && width > height) {
        return {
            width: Math.round(limit),
            height: Math.round(height * (limit / width)),
        };
    }
    return { height, width };
}

export const fileToText = (file: File) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            resolve(e.target?.result);
        };
        reader.onerror = (e) => {
            reject(e);
        };
        reader.readAsText(file);
    });
};

export const getBlobFromBase64Image = (base64String: string) => {
    return new Promise<Blob>((resolve, reject) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const img = new Image();
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;

            ctx!.fillStyle = '#ffffff';
            ctx!.fillRect(0, 0, canvas.width, canvas.height);

            ctx!.drawImage(img, 0, 0);

            canvas.toBlob((blob) => {
                if (blob) {
                    resolve(blob);
                } else {
                    reject(new Error('Empty blob'));
                }
            });
        };

        img.onerror = () => {
            reject(new Error('Failed to load image.'));
        };

        img.src = `data:image/png;base64,${base64String}`;
    });
};

export const createCsvFromString = (csvString: string, fileName: string) => {
    const blob = new Blob([csvString], { type: 'text/csv' });
    return new File([blob], fileName, { type: 'text/csv' });
};

export const createYamlFromString = (yamlString: string, fileName: string) => {
    const blob = new Blob([yamlString], { type: 'text/yaml' });
    return new File([blob], fileName, { type: 'text/yaml' });
};
