import React, { useEffect } from 'react';
import MenuList from '../shared/MenuList';
import styles from './DataView.styl';
import FormattedMessage from '../../localization/FormatMessage';
import { IconCustomCurve } from './FolderIcons';
import { ProductCode } from '../../types/proceq';
import * as measurementService from '../../api/measurementService';
import { CustomCurveFileType } from '../../types/measurement';
import { defaultOrderConfig } from './DataViewContext';
import { useDataViewContext } from './DataViewProvider';
import { useProductData } from '../../hooks/useProductData';

export const fetchCustomCurveDataCount = (product: ProductCode, withUnsynced: boolean) => {
    return measurementService.getMeasurementList({
        product,
        fileType: CustomCurveFileType.dgscc,
        limit: 1, // limit 100 automatically returned if limit is set as 0
        orderBy: defaultOrderConfig.orderBy,
        orderDir: defaultOrderConfig.orderDir,
        withUnsynced,
    });
};

interface CustomCurveMenuListProps {
    activeItem?: string;
    onSelect?: (key: string) => void;
}

const CustomCurveMenuList: React.FunctionComponent<CustomCurveMenuListProps> = (props) => {
    const { activeItem, onSelect } = props;
    const { product, withUnsynced } = useDataViewContext();
    const { measurementCount } = useProductData(product);

    useEffect(() => {
        // if user is already viewing custom curve folder, no need to pull folder count
        if (product && activeItem !== CustomCurveFileType.dgscc) {
            fetchCustomCurveDataCount(product, withUnsynced);
        }
    }, [activeItem, product, withUnsynced]);

    const customCurveMenu = [
        {
            id: CustomCurveFileType.dgscc,
            title: (
                <div className={styles.menu_title_content}>
                    <IconCustomCurve />
                    <span>
                        <FormattedMessage id="DataView.Folder.CustomDGSCurve" />
                    </span>
                </div>
            ),
            count: measurementCount?.dgscc ?? 0,
        },
    ];

    return <MenuList menuItems={customCurveMenu} activeItem={activeItem} onSelect={onSelect} />;
};

export default CustomCurveMenuList;
