import { Avatar, Dropdown } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import FormattedMessage from '../../../localization/FormatMessage';
import { RootState } from '../../../reducers';
import { FormatIDs } from '../../../types';
import routeURLs from '../../Routes/urls';
import styles from './ProfileMenu.styl';
import InitialsPlaceholder from '../../Profile/UserProfile/InitialsPlaceholder';
import analytics from '../../../analytics/firebaseAnalytics';
import config from '../../../config';
import { useRoleState } from '../../../hooks/useCurrentUser';
import { AntdMenuItem } from '../../../types/antd';
import { useBackendVersionQuery } from '../../../queries/utilsQueries';

const menuItems: { titleID: FormatIDs; route: string }[] = [
    {
        titleID: 'Profile.Personal',
        route: routeURLs.PROFILE,
    },
    {
        titleID: 'App.Logout',
        route: routeURLs.LOGOUT,
    },
];

const ProfileMenu: React.FunctionComponent = () => {
    const userProfile = useSelector((state: RootState) => state.profile.userProfile);
    const { firstName, lastName, pictureURL } = userProfile;

    const name = [firstName, lastName].filter(Boolean).join(' ');
    const role = useRoleState();

    // only show in prod for internal users
    const showTag = config.APP_ENV === 'production' && role.isInternalUser;
    const { data: backendVersion } = useBackendVersionQuery();

    const dropdownItems: AntdMenuItem[] = menuItems.map((item) => ({
        key: item.route,
        label: (
            <div className={styles.profile_menu_item}>
                <Link to={item.route} onClick={() => analytics.logViewProfileMenu(item.titleID)}>
                    <strong>
                        <FormattedMessage id={item.titleID} />
                    </strong>
                </Link>
            </div>
        ),
    }));

    return (
        <Dropdown
            menu={{
                className: styles.dropdown_menu,
                items: [
                    ...dropdownItems,
                    ...(showTag
                        ? [
                              {
                                  key: 'envTag',
                                  label: (
                                      <div className={styles.env_tag}>
                                          <div>FE {config.APP_VERSION}</div>
                                          {backendVersion && <div>BE {backendVersion}</div>}
                                      </div>
                                  ),
                              },
                          ]
                        : []),
                ],
            }}
            trigger={['click']}
            // dropdownRender={() => menu}
            overlayStyle={{ paddingTop: 4 }}
            key={pictureURL}
            forceRender
        >
            {pictureURL ? (
                <Avatar size={40} src={pictureURL} alt={name} style={{ cursor: 'pointer' }} />
            ) : (
                <a>
                    <InitialsPlaceholder firstName={firstName} lastName={lastName} size={40} />
                </a>
            )}
        </Dropdown>
    );
};

export default ProfileMenu;
