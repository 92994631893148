import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AnalyticsRoute from '../AnalyticsComponents/Route';
import App from '../App';
import Dashboard from '../Dashboard';
import DataView from '../DataView';
import Profile from '../Profile';
import AppMeasurementView from '../StandaloneMeasurementView/AppMeasurementView';
import routeURLs from './urls';
import * as landingUtils from '../../utils/landingUtils';
import RootRedirection from './RootRedirection';
import MeasurementImport from '../MeasurementImport/MeasurementImport';
import Masquerade from '../Auth/Masquerade';
import ToolFrame from '../DataView/Tools/ToolFrame';
import ToolInsights from '../DataView/Tools/ToolInsights';
import LicenseRedirection from '../Profile/UserLicense/LicenseRedirection';

const AppRoute: React.FunctionComponent = () => {
    return (
        <App>
            <Switch>
                <AnalyticsRoute exact path={routeURLs.MASQUERADE} titleFormatIDs={[]} component={Masquerade} />
                <AnalyticsRoute exact path={routeURLs.DASHBOARD} titleFormatIDs={[]} component={Dashboard} />
                <AnalyticsRoute path={routeURLs.PROFILE} titleFormatIDs={[]} component={Profile} />
                <AnalyticsRoute exact path={routeURLs.DATA_PRODUCT} titleFormatIDs={[]} component={DataView} />
                <AnalyticsRoute exact path={routeURLs.MEASUREMENT} titleFormatIDs={[]} component={AppMeasurementView} />
                <AnalyticsRoute exact path={routeURLs.TOOL_PRODUCT} titleFormatIDs={[]} component={ToolFrame} />
                <AnalyticsRoute exact path={routeURLs.APP_PRODUCT} titleFormatIDs={[]} component={ToolInsights} />
                <AnalyticsRoute
                    exact
                    path={routeURLs.LICENSE_ACTIVATION}
                    titleFormatIDs={[]}
                    component={LicenseRedirection}
                />
                <AnalyticsRoute
                    exact
                    path={routeURLs.MEASUREMENT_IMPORT}
                    titleFormatIDs={[]}
                    component={MeasurementImport}
                />
                <Route exact path="/" component={RootRedirection} />
                <Route>
                    <UnknownRedirect />
                </Route>
            </Switch>
        </App>
    );
};

const UnknownRedirect: React.FunctionComponent = () => {
    // For unknown path, we need to clean up the landing path
    landingUtils.restoreLandingPath();
    return <Redirect to="/" />;
};

export default AppRoute;
