import React, { Fragment } from 'react';
import { Table } from 'antd';
import { MeasurementFullData } from '../../../../../types/measurement';
import { DerivedProps } from '../../SharedTypes';
import styles from '../../DataViewers.styl';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { useFormatMessage } from '../../../../../localization/useFormatMessage';
import { getRebarDetails, LineScanDetails } from './scanDetailUtils';
import SectionTable from '../../shared/SectionTable';
import { getConvertedSectionTableData, RawSectionTableData } from '../../../../../utils/tableDataUtils';
import { tableLocaleSetting } from '../../../../shared/MyEmpty';
import { ScanType } from '../../../../../types/proceq';

const ScanDetails: React.FunctionComponent<
    { data: MeasurementFullData } & DerivedProps & { lineScanDetails: LineScanDetails }
> = (props) => {
    const { showTitle, data, scanType, convert, isMetric, lineScanDetails } = props;
    const { lineScanStatus, areaScanParameters } = data.settings[0].content.areaScanStatus || {};
    const startDirection = areaScanParameters?.startDirection;
    const formatMessage = useFormatMessage();
    return (
        <>
            {lineScanStatus.map((lineScan: any) => {
                const { aScanID } = lineScan;
                const { reading, sequenceNo, lineContents } = lineScanDetails[aScanID];
                const scanPositionDimension = reading?.content?.scanPosition?.dimension;
                const areaScanTableData: RawSectionTableData[] =
                    scanType === ScanType.AreaScan
                        ? [
                              {
                                  label: 'App.HTML.Profometer.ScanDetails.ScanLayer',
                                  value: formatMessage(
                                      { id: 'App.HTML.Profometer.ScanDetails.ScanLayer.Value' },
                                      {
                                          direction: scanPositionDimension === startDirection ? 'X' : 'Y',
                                      }
                                  ),
                              },
                              {
                                  label: 'Proceq.ScanDirection',
                                  value: formatMessage({
                                      id:
                                          scanPositionDimension === 'X'
                                              ? 'App.HTML.Profometer.MeasurementSettings.ScanDirection.Horizontal'
                                              : 'App.HTML.Profometer.MeasurementSettings.ScanDirection.Vertical',
                                  }),
                              },
                          ]
                        : [];
                const rawSectionTableData: RawSectionTableData[] = [
                    ...areaScanTableData,
                    {
                        label: 'App.HTML.GPR.ScanDistance',
                        value: lineScan.scanDistance,
                        unitId: 'PROFOMETER.CSV.Scan distance',
                    },
                ];
                const sectionTableData = getConvertedSectionTableData(rawSectionTableData, scanType, isMetric);
                const { columns: rebarColumns, dataSource: rebarData } = getRebarDetails({
                    lineContents,
                    isMetric,
                    scanType,
                    convert,
                    lineScanStatus: lineScan,
                });

                return (
                    <Fragment key={lineScan.aScanID}>
                        <SectionTable
                            showTitle={showTitle}
                            className={styles.table}
                            title={
                                <span className={styles.sub_header}>
                                    <FormattedMessage
                                        id="App.HTML.Profometer.ScanDetails.ScanLine"
                                        values={{ lineNo: sequenceNo }}
                                    />
                                </span>
                            }
                            dataSource={sectionTableData}
                        />
                        {rebarData?.length ? (
                            <Table
                                columns={rebarColumns}
                                dataSource={rebarData}
                                className={styles.table}
                                pagination={false}
                                size="small"
                                bordered
                                locale={tableLocaleSetting}
                            />
                        ) : null}
                        <br />
                    </Fragment>
                );
            })}
        </>
    );
};

export default ScanDetails;
