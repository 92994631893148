import { Form, message } from 'antd';
import React, { useCallback, useRef, useState } from 'react';
import { FormItem, useFormCheckErrors } from 'tds-common-fe';
import { useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import styles from './Security.styl';
import FormattedMessage from '../../../localization/FormatMessage';
import AnalyticsButton from '../../AnalyticsComponents/Button';
import { FieldsKeys, FieldsType, rules } from '../UserProfile/personalProfileRules';
import AuthInput from '../FormElements/AuthInput';
import AuthPasswordLabel from '../FormElements/AuthPasswordLabel';
import { PASSWORD_MAX_LENGTH } from '../constants';
import { useFormatMessage } from '../../../localization/useFormatMessage';
import analytics from '../../../analytics/firebaseAnalytics';
import { PersonalProfileAction } from '../../../analytics/analyticsConstants';
import * as inputUtils from '../../../utils/inputUtils';
import { RootState } from '../../../reducers';
import * as userServices from '../../../api/userService';
import * as passwordUtils from '../../../utils/passwordUtils';

class Item extends FormItem<FieldsType> {}

const passwordFields: FieldsKeys[] = ['password', 'passwordConfirm', 'passwordOld'];

const EditPassword: React.FunctionComponent = () => {
    const formatMessage = useFormatMessage();
    const [form] = Form.useForm();
    const { resetFields } = form;
    const inputs = useRef<HTMLInputElement[]>([]);
    const [changePassword, setChangePassword] = useState(false);

    const userProfile = useSelector((state: RootState) => state.profile.userProfile);
    const { email = '', lastName = '', firstName = '' } = userProfile;

    const { checkErrors } = useFormCheckErrors(form, passwordFields);

    const handleChangePassword = () => {
        setChangePassword(true);
        analytics.logPersonalProfileEvent(PersonalProfileAction.changePassword);
    };

    const handleEndPasswordChange = useCallback(() => {
        setChangePassword(false);
        resetFields(passwordFields);
        analytics.logPersonalProfileEvent(PersonalProfileAction.cancelChangePassword);
    }, [resetFields]);

    const { mutate: updatePassword, isLoading: submitting } = useMutation({
        mutationFn: ({ values }: { values: { [Key in FieldsKeys]: string } }) =>
            userServices.changeUserPassword({
                passwordOld: values.passwordOld,
                password: values.password,
                passwordConfirm: values.passwordConfirm,
            }),
        onSuccess: () => {
            message.success(formatMessage({ id: 'Profile.SuccessMsg.ChangePassword' }));
            handleEndPasswordChange();
        },
    });

    const fields = {
        fakePassword: (
            <Item
                label={<FormattedMessage id="Profile.Password.Old.Placeholder" />}
                className={styles.password_form_item}
            >
                <AuthInput placeholder="••••••••••" inputs={inputs} disabled />
            </Item>
        ),
        password: (
            <Item
                label={<FormattedMessage id="Profile.Password.New.Title" />}
                className={styles.password_form_item}
                name="password"
                rules={[
                    {
                        required: true,
                        message: <FormattedMessage id="Login.Field.Password.Error.Empty" />,
                    },
                    passwordUtils.getStrongPasswordValidator<string>(
                        [email, firstName, lastName],
                        <FormattedMessage id="SignUp.Field.Password.Error.Weak" />,
                        false
                    ),
                    inputUtils.getMaxLengthValidator(
                        PASSWORD_MAX_LENGTH,
                        <FormattedMessage
                            id="SignUp.Field.Input.Error.MaxLength"
                            values={{ count: PASSWORD_MAX_LENGTH }}
                        />
                    ),
                ]}
            >
                <AuthInput
                    addonAfter={<AuthPasswordLabel />}
                    authInputType="password"
                    placeholder={formatMessage({ id: 'Profile.Password.New.Placeholder' })}
                    inputs={inputs}
                    disabled={submitting}
                    maxLength={PASSWORD_MAX_LENGTH}
                />
            </Item>
        ),
        passwordOld: (
            <Item
                label={<FormattedMessage id="Profile.Password.Old.Title" />}
                className={styles.password_form_item}
                name="passwordOld"
                rules={rules.passwordOld}
            >
                <AuthInput
                    authInputType="password"
                    placeholder={formatMessage({ id: 'Profile.Password.Old.Placeholder' })}
                    inputs={inputs}
                    disabled={submitting}
                    maxLength={PASSWORD_MAX_LENGTH}
                />
            </Item>
        ),
        passwordConfirm: (
            <Item
                label={<FormattedMessage id="Profile.Password.Confirm.Title" />}
                className={styles.password_form_item}
                name="passwordConfirm"
                dependencies={['password']}
                rules={rules.passwordConfirm}
            >
                <AuthInput
                    authInputType="password"
                    placeholder={formatMessage({ id: 'Profile.Password.Confirm.Placeholder' })}
                    inputs={inputs}
                    disabled={submitting}
                    maxLength={PASSWORD_MAX_LENGTH}
                />
            </Item>
        ),
    };

    const handleSubmit = useCallback(
        async (values: { [Key in FieldsKeys]: string }) => updatePassword({ values }),
        [updatePassword]
    );

    const buttonArea = (
        <div className={styles.password_button_area}>
            {changePassword ? (
                <>
                    <Item>
                        <AnalyticsButton onClick={handleEndPasswordChange} type="primary" ghost>
                            <FormattedMessage id="App.Cancel" />
                        </AnalyticsButton>
                    </Item>
                    <Item shouldUpdate>
                        {() => (
                            <AnalyticsButton
                                type="primary"
                                htmlType="submit"
                                disabled={submitting || checkErrors()}
                                onClick={() => analytics.logPersonalProfileEvent(PersonalProfileAction.done)}
                            >
                                <FormattedMessage id="Profile.Password.UpdatePassword" />
                            </AnalyticsButton>
                        )}
                    </Item>
                </>
            ) : (
                <Item>
                    <AnalyticsButton onClick={handleChangePassword} type="primary">
                        <FormattedMessage id="App.Edit" />
                    </AnalyticsButton>
                </Item>
            )}
        </div>
    );

    return (
        <>
            <div className={styles.title_container}>
                <FormattedMessage id="Profile.PasswordSecurity.ChangePassword" />
            </div>
            <div className={styles.password_container}>
                <Form
                    form={form}
                    layout="vertical"
                    className={styles.form}
                    onFinish={handleSubmit}
                    requiredMark={false}
                >
                    {changePassword ? (
                        <>
                            {fields.passwordOld}
                            {fields.password}
                            {fields.passwordConfirm}
                        </>
                    ) : (
                        fields.fakePassword
                    )}
                    {buttonArea}
                </Form>
            </div>
        </>
    );
};

export default EditPassword;
