import React, { useMemo } from 'react';
import { differenceBy } from 'lodash';
import { LicenseProduct, LicenseTier } from '../../../types/license';
import styles from './UserLicense.styl';
import PlanFeatureList from '../Plan/PlanFeatureList';
import FormattedMessage from '../../../localization/FormatMessage';
import { ProductCode } from '../../../types/proceq';
import { getLabelFromGlobalDataKey } from '../../DataView/RegisteredInfo/helper';

type TierFeaturesProps = {
    product: ProductCode | LicenseProduct;
    tiers: LicenseTier[];
    isFreeTrial: boolean;
    currentTier?: string;
    expirationDate: string;
};

const TierFeatures: React.FunctionComponent<TierFeaturesProps> = (props) => {
    const { product, tiers, isFreeTrial, currentTier, expirationDate } = props;
    const freeTier = useMemo(() => {
        if (!tiers) return undefined;
        return tiers.find((tier) => tier.free);
    }, [tiers]);
    const nonFreeTiers = useMemo(() => tiers.filter((tier) => !tier.free), [tiers]);
    const productName = getLabelFromGlobalDataKey('availproductsfull', product);

    const getUniqueFeatures = (position: number, tier: LicenseTier) => {
        let featuresIncluded = tier.features;
        if (nonFreeTiers[position - 1]) {
            featuresIncluded = differenceBy(tier.features, nonFreeTiers[position - 1].features, 'code');
        }
        return featuresIncluded;
    };

    const freeTrialTier =
        isFreeTrial && freeTier ? (
            <div className={styles.free_trial_container}>
                <div>
                    <div className={styles.tier_title}>{freeTier.name}</div>
                    <div className={styles.body2}>
                        <b>
                            <FormattedMessage id="License.Tier.FreeTrialAllFeatures" />
                        </b>
                    </div>
                    <div className={styles.body2}>
                        <FormattedMessage
                            id="License.Tier.FreeTrialKeepUsing"
                            values={{ productName, date: expirationDate }}
                        />
                    </div>
                </div>
            </div>
        ) : null;

    return (
        <div className={styles.tier_features_container}>
            {freeTrialTier}
            {nonFreeTiers.map((tier, index) => {
                return (
                    <div
                        className={
                            currentTier === tier.tier
                                ? styles.features_container_selected
                                : styles.features_container_normal
                        }
                        key={tier.name}
                    >
                        <div>
                            <div className={styles.tier_title}>{tier.name}</div>
                            <PlanFeatureList
                                className={styles.plan_list}
                                features={getUniqueFeatures(index, tier)}
                                columnCount={1}
                                includePreviousTier={nonFreeTiers[index - 1]?.name}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default TierFeatures;
