import React from 'react';
import DashboardBlock from './DashboardBlock';
import { ReactComponent as IconArrowRight } from '../../images/iconArrowRight.svg';
import { classNames } from '../../utils/styleUtils';
import styles from './ContentBlock.styl';
import analytics from '../../analytics/firebaseAnalytics';
import { DiscoveryCategory } from '../../analytics/analyticsConstants';
import FormattedMessage from '../../localization/FormatMessage';
import { CMS_NEWS_URL_PREFIX } from '../Profile/constants';
import { useCMSNews } from '../../queries/appQueries';

export interface ContentItem {
    title: string;
    imageURL: string;
    id: number;
}

export const ContentBlock: React.FunctionComponent<ContentItem> = (props) => {
    const { title, imageURL, id } = props;
    const contentURL = CMS_NEWS_URL_PREFIX + id;

    return (
        <DashboardBlock overflow="unset">
            <h3 className={classNames(styles.content_tag, styles.tag_news)}>
                <FormattedMessage id="Dashboard.News" />
            </h3>
            <div className={styles.content_wrapper}>
                <p className={styles.content_text}>{title}</p>
                <a
                    href={contentURL}
                    className={styles.dashboard_link}
                    rel="noreferrer"
                    target="_blank"
                    onClick={() => {
                        analytics.logViewDiscovery(DiscoveryCategory.news, contentURL);
                    }}
                >
                    <FormattedMessage id="Dashboard.LearnMore" />
                    <IconArrowRight style={{ marginLeft: 10 }} />
                </a>
            </div>
            <img className={styles.content_image} src={imageURL} alt="" />
        </DashboardBlock>
    );
};

const ContentBlocks: React.FunctionComponent = () => {
    const { data: allNews = [] } = useCMSNews();
    return (
        <>
            {allNews.map((content) => {
                return <ContentBlock key={content.id} {...content} />;
            })}
        </>
    );
};

export default ContentBlocks;
