import { Table } from 'antd';
import React from 'react';
import styles from '../../DataViewers.styl';
import { tableLocaleSetting } from '../../../../shared/MyEmpty';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { MeasurementFullData } from '../../../../../types/measurement';
import { DerivedProps } from '../../SharedTypes';
import { roundToNDigit } from '../../utils/conversionRates';

export const title = <FormattedMessage id="Proceq.ExportFieldStatistics" />;

const STATISTICS_COLUMNS = [
    {
        title: <FormattedMessage id="App.HTML.Profometer.Statistics.NumberOfReadings" />,
        dataIndex: ['noOfReadings'],
    },
    {
        title: <FormattedMessage id="App.HTML.Profometer.Statistics.Mean" />,
        dataIndex: ['mean'],
        render: (value: number) => roundToNDigit(value, 0),
    },
    {
        title: <FormattedMessage id="App.HTML.Profometer.Statistics.Median" />,
        dataIndex: ['median'],
        render: (value: number) => roundToNDigit(value, 0),
    },
    {
        title: <FormattedMessage id="App.HTML.Profometer.Statistics.StDev" />,
        dataIndex: ['stdDeviation'],
        render: (value: number) => roundToNDigit(value, 1),
    },
    {
        title: <FormattedMessage id="App.HTML.Profometer.Statistics.Min" />,
        dataIndex: ['min'],
        render: (value: number) => roundToNDigit(value, 0),
    },
    {
        title: <FormattedMessage id="App.HTML.Profometer.Statistics.Max" />,
        dataIndex: ['max'],
        render: (value: number) => roundToNDigit(value, 0),
    },
    {
        title: <FormattedMessage id="App.HTML.Profometer.Statistics.PassiveThreshold" />,
        dataIndex: ['passiveThreshold'],
        render: (value: number) => roundToNDigit(value, 0),
    },
    {
        title: <FormattedMessage id="App.HTML.Profometer.Statistics.CorrosionThreshold" />,
        dataIndex: ['corrosionThreshold'],
        render: (value: number) => roundToNDigit(value, 0),
    },
];

const Statistics: React.FunctionComponent<{ data: MeasurementFullData } & DerivedProps> = ({ data }) => {
    const statisticsData = [data.measurement?.content?.statistics];
    return (
        <Table
            title={() => (
                <span className={styles.main_header}>
                    <FormattedMessage id="Proceq.ExportFieldStatistics" />
                </span>
            )}
            className={styles.table}
            columns={STATISTICS_COLUMNS}
            rowKey="noOfReadings"
            dataSource={statisticsData}
            pagination={false}
            size="small"
            bordered
            locale={tableLocaleSetting}
        />
    );
};

export default Statistics;
