import React from 'react';
import styles from './IconButton.styl';
import { classNames } from '../../../utils/styleUtils';

interface IconButtonProps {
    onClick?: (e: React.MouseEvent) => void;
    style?: React.CSSProperties;
    className?: string;
    overlay?: boolean;
}

const IconButton: React.FunctionComponent<IconButtonProps> = (props) => {
    const { children, onClick, style, className, overlay = false } = props;

    return (
        <div
            tabIndex={0}
            role="button"
            onClick={onClick}
            style={style}
            className={classNames(styles.button, styles.center, className)}
        >
            {children}
            {overlay && <div className={styles.overlay} />}
        </div>
    );
};

export default IconButton;
