import React from 'react';
import AnalyticsButton from '../../AnalyticsComponents/Button';
import { ReactComponent as EditIcon } from '../../../images/iconEdit.svg';
import Tooltip from '../Tooltip';
import { useFormatMessage } from '../../../localization/useFormatMessage';
import { CustomCurveFileType, MeasurementListItem, SystemFolderID, ViewType } from '../../../types/measurement';
import { CustomMaterialItem } from '../../../types/customMaterial';
import analytics from '../../../analytics/firebaseAnalytics';
import { MeasurementMenuCategory } from '../../../analytics/analyticsConstants';
import { useMeasurement } from '../../../hooks/useProductData';
import { useSelectedContractFeatures } from '../../../hooks/useContract';
import { useDataViewContext } from '../../DataView/DataViewProvider';
import useInvalidMeasurements from '../../../hooks/useInvalidMeasurements';
import { useMeasurementModalContext } from '../../DataView/MeasurementModals/MeasurementModalProvider';

interface EditButtonProps {
    measurement: MeasurementListItem | CustomMaterialItem;
    hideButton?: boolean;
}

const EditButton: React.FunctionComponent<EditButtonProps> = ({ measurement, hideButton }) => {
    const formatMessage = useFormatMessage();
    const { setShowRenameMeasurement, setMeasurementID, setMeasurementName } = useMeasurementModalContext();
    const measurementID = measurement.id;
    const measurementTest = useMeasurement(measurementID);
    const { getInvalidMeasurementIDs, demoMeasurementIDs } = useSelectedContractFeatures(
        { [measurementID]: measurementTest },
        [measurementID]
    );
    const { nonEditableMeasurements, nonEditableExcludeDemo } = useInvalidMeasurements({
        invalidMeasurementIDs: getInvalidMeasurementIDs(),
        demoMeasurementIDs,
        measurementID,
    });

    const { product, setNonEditableMeasurements, fileType, activeFolder, viewType } = useDataViewContext();

    if (hideButton || activeFolder === SystemFolderID.Trashed || viewType === ViewType.Archived) return null;
    return (
        <AnalyticsButton
            onClick={(e) => {
                e.stopPropagation();
                analytics.logMeasurementMenu(MeasurementMenuCategory.rename);
                if (nonEditableExcludeDemo && fileType !== CustomCurveFileType.dgscc) {
                    setNonEditableMeasurements(nonEditableMeasurements);
                    analytics.logNonEditableMeasurement(MeasurementMenuCategory.rename, product ?? '');
                } else {
                    setShowRenameMeasurement(true);
                    setMeasurementName(measurement.name);
                    setMeasurementID(measurementID);
                }
            }}
            type="link"
            style={{ display: 'flex', padding: 0, height: 'auto' }}
        >
            <Tooltip placement="top" title={formatMessage({ id: 'DataView.Folder.RenameFolder.Rename' })}>
                <EditIcon />
            </Tooltip>
        </AnalyticsButton>
    );
};

export default EditButton;
