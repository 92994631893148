import React, { useCallback } from 'react';
import { message, Spin } from 'antd';
import copyToClipboard from 'copy-to-clipboard';
import FormattedMessage from '../../localization/FormatMessage';
import AnalyticsButton from '../AnalyticsComponents/Button';
import styles from './ButtonShare.styl';
import StyledModal from '../shared/StyledModal';
import { FormatIDs } from '../../types';
import analytics from '../../analytics/firebaseAnalytics';
import { ShareLinkAction } from '../../analytics/analyticsConstants';
import { ProductCode } from '../../types/proceq';
import { ellipsesText } from '../../utils/generalUtils';
import { ReactComponent as LinkIcon } from '../../images/iconLink.svg';

interface CopyLinkProps {
    modalTitle: FormatIDs;
    visible: boolean;
    onCancel: () => void;
    modalBody: React.ReactNode;
    modalFooter?: React.ReactNode;
    sharingURL: string;
    isLoading: boolean;
    product: ProductCode;
}

const CopyLink: React.FunctionComponent<CopyLinkProps> = (props) => {
    const { visible, onCancel, modalTitle, modalBody, modalFooter, sharingURL, isLoading, product } = props;

    const handleCopyLink = useCallback(() => {
        if (copyToClipboard(sharingURL)) {
            message.destroy();
            message.success('link is copied to clipboard', 1);
        }
        analytics.logShareLinkEvent(ShareLinkAction.copy, product);
    }, [sharingURL, product]);

    return (
        <StyledModal
            title={<FormattedMessage id={modalTitle} />}
            open={visible}
            onCancel={onCancel}
            footer={
                <div style={{ display: 'flex', gap: 8, justifyContent: 'flex-end' }}>
                    <AnalyticsButton ghost onClick={onCancel} type="primary">
                        <FormattedMessage id="App.Cancel" />
                    </AnalyticsButton>
                    <AnalyticsButton
                        style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                        type="primary"
                        onClick={handleCopyLink}
                        disabled={isLoading}
                    >
                        <LinkIcon />
                        <FormattedMessage id="Proceq.CopyLink" />
                    </AnalyticsButton>
                </div>
            }
        >
            <div className={styles.modal_content_wrapper}>
                {modalBody}
                <div className={styles.text_area}>{isLoading ? <Spin size="small" /> : ellipsesText(sharingURL)}</div>
                {modalFooter && <div className={styles.caption}>{modalFooter}</div>}
            </div>
        </StyledModal>
    );
};

export default CopyLink;
