import axios, { Method, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { storeDispatch } from '../store';
import { RootState } from '../reducers';
import { excludeExtraHeaders, getScreeningEagleHeaders } from './headerUtils';
import { PROCEQ_MANAGEMENT_API_URL } from './RegionalAPI';
import authInfo from './AuthInfo';

const DATA_METHODS = new Set(['PUT', 'POST', 'PATCH', 'DELETE']);

// Timeout after 2 min
const TIMEOUT = 2 * 60 * 1000;

const instance = axios.create({
    timeout: TIMEOUT,
    baseURL: PROCEQ_MANAGEMENT_API_URL,
});

export interface APIData {
    params?: { [key: string]: string | number | undefined | null | Object | string[] } | FormData;
    config?: AxiosRequestConfig;
    errorHandler?: (error: any) => void;
    injectToken?: boolean;
}

export type ThunkResponse<R> = { response: R; dispatch: Dispatch; getState: () => RootState };

export function thunkGet<R>(
    path: string,
    data: APIData = {},
    dispatch: Dispatch<any> = storeDispatch
): Promise<ThunkResponse<R>> {
    return new Promise((resolve, reject) => {
        dispatch(async (myDispatch: Dispatch, getState: () => RootState) => {
            try {
                const response = await get<R>(path, data);
                resolve({ response, getState, dispatch: myDispatch });
            } catch (e) {
                reject(e);
            }
        });
    });
}

export function thunkPost<R>(
    path: string,
    data: APIData = {},
    dispatch: Dispatch<any> = storeDispatch
): Promise<ThunkResponse<R>> {
    return new Promise((resolve, reject) => {
        dispatch(async (myDispatch: Dispatch, getState: () => RootState) => {
            try {
                const response = await post<R>(path, data);
                resolve({ response, getState, dispatch: myDispatch });
            } catch (e) {
                reject(e);
            }
        });
    });
}

export function thunkPut<R>(
    path: string,
    data: APIData = {},
    dispatch: Dispatch<any> = storeDispatch
): Promise<ThunkResponse<R>> {
    return new Promise((resolve, reject) => {
        dispatch(async (myDispatch: Dispatch, getState: () => RootState) => {
            try {
                const response = await put<R>(path, data);
                resolve({ response, getState, dispatch: myDispatch });
            } catch (e) {
                reject(e);
            }
        });
    });
}

export function thunkPatch<R>(
    path: string,
    data: APIData = {},
    dispatch: Dispatch<any> = storeDispatch
): Promise<ThunkResponse<R>> {
    return new Promise((resolve, reject) => {
        dispatch(async (myDispatch: Dispatch, getState: () => RootState) => {
            try {
                const response = await patch<R>(path, data);
                resolve({ response, getState, dispatch: myDispatch });
            } catch (e) {
                reject(e);
            }
        });
    });
}

export function thunkDelete<R>(
    path: string,
    data: APIData = {},
    dispatch: Dispatch<any> = storeDispatch
): Promise<ThunkResponse<R>> {
    return new Promise((resolve, reject) => {
        dispatch(async (myDispatch: Dispatch, getState: () => RootState) => {
            try {
                const response = await deleteRequest<R>(path, data);
                resolve({ response, getState, dispatch: myDispatch });
            } catch (e) {
                reject(e);
            }
        });
    });
}

export function get<R>(path: string, data: APIData, requireAxiosResponse?: false): Promise<R>;
export function get<R>(path: string, data: APIData, requireAxiosResponse: true): Promise<AxiosResponse<R>>;
export function get<R>(path: string, data: APIData = {}, requireAxiosResponse?: boolean) {
    const { params, config, errorHandler, injectToken } = data;
    return request<R>('GET', path, params, config, errorHandler, injectToken, requireAxiosResponse);
}

export function post<R>(path: string, data: APIData, requireAxiosResponse?: false): Promise<R>;
export function post<R>(path: string, data: APIData, requireAxiosResponse: true): Promise<AxiosResponse<R>>;
export function post<R>(path: string, data: APIData = {}, requireAxiosResponse?: boolean) {
    const { params, config, errorHandler, injectToken } = data;
    return request<R>('POST', path, params, config, errorHandler, injectToken, requireAxiosResponse);
}

export function put<R>(path: string, data: APIData, requireAxiosResponse?: false): Promise<R>;
export function put<R>(path: string, data: APIData, requireAxiosResponse: true): Promise<AxiosResponse<R>>;
export function put<R>(path: string, data: APIData = {}, requireAxiosResponse?: boolean) {
    const { params, config, errorHandler, injectToken } = data;
    return request<R>('PUT', path, params, config, errorHandler, injectToken, requireAxiosResponse);
}

export function deleteRequest<R>(path: string, data: APIData, requireAxiosResponse?: false): Promise<R>;
export function deleteRequest<R>(path: string, data: APIData, requireAxiosResponse: true): Promise<AxiosResponse<R>>;
export function deleteRequest<R>(path: string, data: APIData = {}, requireAxiosResponse?: boolean) {
    const { params, config, errorHandler, injectToken } = data;
    return request<R>('DELETE', path, params, config, errorHandler, injectToken, requireAxiosResponse);
}

export function patch<R>(path: string, data: APIData, requireAxiosResponse?: false): Promise<R>;
export function patch<R>(path: string, data: APIData, requireAxiosResponse: true): Promise<AxiosResponse<R>>;
export function patch<R>(path: string, data: APIData = {}, requireAxiosResponse?: boolean) {
    const { params, config, errorHandler, injectToken } = data;
    return request<R>('PATCH', path, params, config, errorHandler, injectToken, requireAxiosResponse);
}

export function request<R = any>(
    method: Method,
    path: string,
    params: { [key: string]: string | number | undefined | null | Object } | undefined | FormData,
    config: AxiosRequestConfig | undefined,
    errorHandler: ((error: any) => void) | undefined,
    injectToken: boolean | undefined,
    requireAxiosResponse: boolean | undefined
): Promise<R | AxiosResponse<R>> {
    const isDataMethod = DATA_METHODS.has(method);
    const myParams = isDataMethod ? undefined : params;
    const data = isDataMethod ? params : undefined;

    const excludeHeaders = excludeExtraHeaders(path);

    const myConfig: AxiosRequestConfig = config ?? {};
    myConfig.headers = {
        'Content-Type': isDataMethod ? 'application/json' : 'text/plain',
        ...(excludeHeaders ? {} : getScreeningEagleHeaders()),
        ...(myConfig.headers ?? {}),
        ...(!!injectToken || authInfo.alwaysInjectToken ? { authorization: authInfo.token } : {}),
    };

    return instance({
        method,
        data,
        url: path,
        params: myParams,
        withCredentials: true,
        ...myConfig,
    })
        .then((response: AxiosResponse<R>) => {
            return requireAxiosResponse ? response : response.data;
        })
        .catch((error) => {
            if (errorHandler) {
                errorHandler(error);
            }
            throw error;
        });
}
