import { routeUtils, browserUtils } from 'tds-common-fe';
import config from '../config';
import { intlProxy } from '../localization/IntlProxy';
import * as storageUtils from '../utils/storageUtils';
import envConfig from '../config';

export interface ClientParams {
    product: string;
    client: string;
    version: string;
    appVersion: string;
}

export const consumerInfo: Partial<ClientParams> = {};

export const getScreeningEagleHeaders = (): { [key: string]: string } => {
    const { client } = consumerInfo;
    const isWeb = client === 'web';
    const isApp = client === 'ios';

    if (isWeb || isApp) {
        return {
            'X-User-Agent': `product=workspace; version=${config.APP_VERSION}; client=web`,
            'X-Accept-Language': intlProxy.locale,
            'X-SE-Client-Id': browserUtils.getDeviceID(),
        };
    }

    return {};
};

export const retrieveConsumerInfo = () => {
    const { product, client, version, appVersion } = routeUtils.parseQuery<ClientParams>(location.search, true);
    if (product) {
        storageUtils.saveSessionItem('product', product);
    }
    if (client) {
        storageUtils.saveSessionItem('client', client);
    }
    if (version || appVersion) {
        storageUtils.saveSessionItem('version', version ?? appVersion ?? '');
    }

    consumerInfo.product = product ?? storageUtils.getSessionItem('product') ?? 'workspace';
    consumerInfo.client = client ?? storageUtils.getSessionItem('client') ?? 'web';
    consumerInfo.version = version ?? appVersion ?? storageUtils.getSessionItem('version') ?? config.APP_VERSION;
};

const PATTERNS_NOT_REQUIRE_EXTRA_HEADER: string[] = [envConfig.INSIGHTS_DOMAIN_PATTERN as string];

export const excludeExtraHeaders = (url: string) => {
    for (const domain of PATTERNS_NOT_REQUIRE_EXTRA_HEADER) {
        if (url.match(domain)) {
            return true;
        }
    }
    return false;
};
