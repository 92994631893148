import { difference, isEmpty, isEqual, union } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table } from 'antd';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useLocation } from 'react-router';
import Pagination from 'antd/es/pagination';
import { useQueryClient } from '@tanstack/react-query';
import FormattedMessage from '../../localization/FormatMessage';
import styles from './DataView.styl';
import { DEFAULT_PAGE_SIZE, useDataViewContext } from './DataViewProvider';
import { OrderDir } from '../../api/measurementService';
import TableWrapper from '../TableWrapper';
import ButtonRefresh from './ButtonRefresh';
import { useCustomMaterials, useProductData } from '../../hooks/useProductData';
import CustomMaterialReadings from './DataViewers/Readings/CustomMaterialReadings';
import { tableLocaleSetting } from '../shared/MyEmpty';
import { CustomMaterialItem } from '../../types/customMaterial';
import { getEquotipColumnConfig, getSchmidtColumnConfig } from './ColumnConfig/columnConfigCustomMaterial';
import { BluetoothFileType } from '../../types/measurement';
import { ToggleUnsynced } from './ToggleSwitch';
import { getMeasurementFolders } from '../../api/folderService';
import MeasurementActionMenu from './MeasurementActionMenu';
import ButtonDelete from './ButtonDelete';
import { ReactQueryKeys } from '../../queries/queryKeys';
import MeasurementModalProvider from './MeasurementModals/MeasurementModalProvider';
import MeasurementModals from './MeasurementModals';
import analytics from '../../analytics/firebaseAnalytics';
import { MeasurementPage } from '../../analytics/analyticsConstants';

const DataTableCustomMaterial: React.FunctionComponent = () => {
    const {
        product,
        activeFolder,
        fetchCustomMaterialList,
        isFetching,
        page,
        setPage,
        pageSize,
        setPageSize,
        orderConfig,
        setOrderConfig,
        withUnsynced,
        fileType,
    } = useDataViewContext();

    const queryClient = useQueryClient();
    const productData = useProductData(product);
    const customMaterials = useCustomMaterials();

    const { customMaterialIDs, measurementCount } = productData;
    // const { data: measurementCount = 0 } = useCustomMaterialDataCount(product, withUnsynced);

    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

    const handleSelectKeys = useCallback((values: React.Key[]) => {
        setSelectedKeys(values.map((d) => d.toString()));
    }, []);

    useEffect(() => {
        setExpandedRowKeys([]);
    }, [product, activeFolder]);

    useEffect(() => {
        setSelectedKeys([]);
    }, [customMaterials]);

    const { state } = useLocation<{ measurementID: string } | undefined>();
    const initMeasurementID = state?.measurementID;

    useEffect(() => {
        if (product && initMeasurementID) {
            setExpandedRowKeys([initMeasurementID]);
        }
    }, [product, initMeasurementID]);

    const customMaterialDataSource = useMemo(() => {
        const ids = customMaterialIDs;
        return (ids ?? []).map((id, index) => {
            return {
                key: customMaterials[id]?.id || index,
                ...customMaterials[id],
            };
        });
    }, [customMaterialIDs, customMaterials]);

    const renderActionCell = useCallback(
        (_value: string, item: CustomMaterialItem) => {
            return (
                <div className={styles.action_wrapper}>
                    {product && (
                        <ButtonDelete
                            product={product}
                            mIDs={[item.id]}
                            fileType={fileType}
                            isPermanentDelete
                            primaryStyle
                            isTableButton
                        />
                    )}
                    <MeasurementActionMenu measurement={item} fileType={fileType} setSelectedKeys={setSelectedKeys} />
                </div>
            );
        },
        [fileType, product]
    );

    const onExpandRow = useCallback(
        (id: string, isExpanded: boolean) => {
            analytics.logMeasurementView(MeasurementPage.measurement, product);
            setExpandedRowKeys((keys) => {
                return isExpanded ? difference(keys, [id]) : union(keys, [id]);
            });
        },
        [product]
    );

    const columns = useMemo(() => {
        const columns: ColumnsType<CustomMaterialItem> = [];
        const descriptionParams = {
            isExpanded: (id: string) => expandedRowKeys.includes(id),
            onExpand: onExpandRow,
        };
        switch (product) {
            case 'EQUOTIP':
                columns.push(...getEquotipColumnConfig(descriptionParams));
                break;
            case 'SCHMIDT':
                columns.push(...getSchmidtColumnConfig(descriptionParams));
                break;
        }

        const actionColumn: ColumnType<CustomMaterialItem> = {
            key: 'action',
            className: styles.action_cell,
            title: <FormattedMessage id="App.Actions" />,
            dataIndex: 'id',
            width: 120,
            fixed: 'right',
            render: renderActionCell,
        };

        columns.push(actionColumn);

        return columns;
    }, [expandedRowKeys, onExpandRow, product, renderActionCell]);

    const expandedRowRender = useCallback(
        (record: CustomMaterialItem, index: number, indent: number, expanded: boolean) => {
            return product && expanded ? <CustomMaterialReadings product={product} record={record} /> : null;
        },
        [product]
    );

    const showLoading = useMemo(() => {
        return (
            isFetching &&
            (customMaterialDataSource.slice(pageSize * (page - 1), pageSize * page).some((data) => !data.id) ||
                isEmpty(customMaterialDataSource))
        );
    }, [isFetching, customMaterialDataSource, pageSize, page]);

    const handleRefresh = useCallback(async () => {
        fetchCustomMaterialList();
        if (product) {
            getMeasurementFolders({ product, withUnsynced });
            queryClient.invalidateQueries({
                queryKey: ReactQueryKeys.verificationDataCount(product, withUnsynced),
            });
        }
    }, [fetchCustomMaterialList, product, queryClient, withUnsynced]);

    const handleOnChange = useCallback(
        (
            _: any,
            tableFilters: Record<string, (React.Key | boolean)[] | null>,
            tableSorter: SorterResult<CustomMaterialItem> | SorterResult<CustomMaterialItem>[]
        ) => {
            if (!Array.isArray(tableSorter) && tableSorter.column) {
                const orderBy = String(tableSorter?.columnKey);
                const orderDir = tableSorter?.order === 'ascend' ? OrderDir.ASC : OrderDir.DESC;
                const newOrderConfig = { orderBy, orderDir };
                if (!isEqual(orderConfig, newOrderConfig)) {
                    setOrderConfig(newOrderConfig);
                }
            } else {
                setOrderConfig(undefined);
            }
        },
        [orderConfig, setOrderConfig]
    );

    return (
        <div className={styles.content_column}>
            <div className={styles.dataView_header}>
                <div className={styles.common_actions}>
                    {selectedKeys.length === 0 && <ButtonRefresh onClick={handleRefresh} disabled={isFetching} />}
                    {product && selectedKeys.length > 0 && (
                        <ButtonDelete
                            product={product}
                            mIDs={selectedKeys}
                            fileType={fileType}
                            isPermanentDelete
                            primaryStyle
                        />
                    )}
                    <ToggleUnsynced />
                </div>
                <Pagination
                    size={'small'}
                    pageSize={pageSize}
                    current={page}
                    total={measurementCount ? measurementCount[BluetoothFileType.CustomMaterial] : 0}
                    pageSizeOptions={['20', '50', '100']}
                    onChange={(page, pageSize) => {
                        setPage(page);
                        setPageSize(pageSize ?? DEFAULT_PAGE_SIZE);
                    }}
                    className={styles.dataView_pagination}
                    showSizeChanger
                />
            </div>
            <div className={styles.dataView_content}>
                <MeasurementModalProvider>
                    <TableWrapper>
                        {(tableHeight) => (
                            <Table<CustomMaterialItem>
                                key={product}
                                columns={columns}
                                className={'scroll-table'}
                                rowKey="key"
                                dataSource={customMaterialDataSource}
                                expandable={{
                                    expandedRowRender,
                                    expandedRowKeys,
                                    expandIconColumnIndex: -1,
                                }}
                                pagination={{
                                    pageSize,
                                    current: page,
                                    style: {
                                        display: 'none',
                                    },
                                }}
                                onRow={(record: { id: string }) => {
                                    return {
                                        onClick: () => onExpandRow(record.id, expandedRowKeys.includes(record.id)),
                                    };
                                }}
                                onChange={handleOnChange}
                                scroll={{ y: (tableHeight ?? 0) - 55 }} // 55 is header row height
                                loading={showLoading}
                                locale={tableLocaleSetting}
                                sticky
                                rowSelection={{ selectedRowKeys: selectedKeys, onChange: handleSelectKeys }}
                            />
                        )}
                    </TableWrapper>
                    <MeasurementModals />
                </MeasurementModalProvider>
            </div>
        </div>
    );
};
export default DataTableCustomMaterial;
