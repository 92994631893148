import React, { useCallback, useEffect, useState } from 'react';
import { useInterval } from 'tds-common-fe';
import { Button, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { useImportMeasurementsInfo } from '../../../hooks/useProductData';
import * as measurementService from '../../../api/measurementService';
import { useFormatMessage } from '../../../localization/useFormatMessage';
import { getDataViewRoute } from '../../Routes/urls';
import { SystemFolderID } from '../../../types/measurement';
import { productCodeToIconMap } from '../../shared/AppIcon';
import useImportMeasurementCount from './useImportMeasurementCount';
import useImportMeasurementDidSuccess from './useImportMeasurementDidSucess';
import { Handler } from './ImportMeasurementContext';
import { ProductCode } from '../../../types/proceq';

// Poll for every 30 seconds
const POLL_INTERVAL = 30 * 1000;

export default function useImportMeasurementDataPolling() {
    const info = useImportMeasurementsInfo();
    const { product, jobID, totalCount = 0, hasSuccessfulImport } = info;

    const formatMessage = useFormatMessage();
    const history = useHistory();

    const [hasError, setHasError] = useState(false);
    const [customViewFilesHandler, setCustomViewFilesHandler] = useState<Handler>({});

    const { importCount } = useImportMeasurementCount();
    const shouldPoll = !hasError && product !== ProductCode.GPR_INSIGHTS && jobID && importCount > 0;

    const handlePolling = useCallback(() => {
        if (!jobID || !product) {
            return;
        }
        measurementService
            .getImportMeasurementsStatus({
                jobID,
                product,
            })
            .catch(() => setHasError(true));
    }, [jobID, product]);

    // Poll for the status of the import job
    useInterval(handlePolling, shouldPoll ? POLL_INTERVAL : null);

    // Perform the poll action for first time
    useEffect(() => {
        if (shouldPoll) {
            handlePolling();
        }
    }, [shouldPoll, handlePolling]);

    // Show success message when any of the measurement completed the import
    const didSuccessHandler = useCallback(() => {
        if (hasSuccessfulImport && totalCount > 0 && product) {
            const completedCount = totalCount - importCount;
            const key = `import-${Date.now()}`;

            const handleShowFile = () => {
                message.destroy(key);

                if (customViewFilesHandler.action) {
                    customViewFilesHandler.action();
                } else {
                    const productName = productCodeToIconMap[product];
                    history.push(getDataViewRoute({ productName: productName || product }), {
                        folderID: SystemFolderID.Imported,
                    });
                }
            };

            message.success({
                key,
                content: (
                    <span>
                        {formatMessage({ id: 'Import.Success.Message' }, { count: completedCount, totalCount })}
                        <Button type="link" onClick={handleShowFile}>
                            {formatMessage({ id: 'Import.Success.ShowFile' }, { count: completedCount })}
                        </Button>
                    </span>
                ),
                duration: 5,
            });
        }
    }, [hasSuccessfulImport, totalCount, product, importCount, formatMessage, customViewFilesHandler, history]);

    useImportMeasurementDidSuccess(didSuccessHandler);

    return {
        viewImportMeasurementsHandler: customViewFilesHandler,
        setViewImportMeasurementsHandler: setCustomViewFilesHandler,
    };
}
