import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';
import DataViewFolders from './DataViewFolder';
import DataTableMeasurement from './DataTableMeasurement';
import DataTableCustomMaterial from './DataTableCustomMaterial';
import { DataViewProvider, useDataViewContext } from './DataViewProvider';
import DataViewModal from './DataViewModal';
import AppLayout from '../shared/AppLayout';
import { getDataViewRoute } from '../Routes/urls';
import { productMap } from '../shared/AppIcon';
import { BluetoothFileType, SystemFolderID, ViewType } from '../../types/measurement';
import { productDataCategoryRoutes } from './DataViewContext';
import { ProductCode } from '../../types/proceq';
import useRefWithChange from '../../hooks/useRefWithChange';
import NonEditableModal from './NonEditableModal';
import ImportFileExists from '../MeasurementImport/ImportFileExists';
import { useProceqAppConfig } from '../../queries/utilsQueries';

interface DataViewProps {
    customCurveNameConflicts?: string[];
}

const DataView: React.FunctionComponent = () => {
    const history = useHistory();
    const params = useParams<{ productName: string; viewType: ViewType; folderID: string }>();
    const productCode = productMap[params.productName]?.productCode ?? (params.productName as ProductCode);

    const locationDataViewState = useLocation<DataViewProps>();
    const [customCurveNameConflicts, setCustomCurveNameConflicts] = useState<string[] | undefined>(
        locationDataViewState.state?.customCurveNameConflicts
    );

    const stateFolderIDRef = useRefWithChange(params.folderID);

    const { setProduct, fileType, setFileType, setViewType, setActiveFolder } = useDataViewContext();

    const isMeasurement = fileType !== BluetoothFileType.CustomMaterial;

    useProceqAppConfig(productCode);

    useEffect(() => {
        setActiveFolder(params.folderID);
    }, [params.folderID, setActiveFolder]);

    useEffect(() => {
        setProduct(productCode, stateFolderIDRef.current);
    }, [productCode, setProduct, stateFolderIDRef]);

    useEffect(() => {
        if (params.viewType === ViewType.Archived) {
            setViewType(ViewType.Archived);
        } else {
            setViewType(ViewType.Active);
        }
    }, [params.viewType, setViewType]);

    useEffect(() => {
        if (productDataCategoryRoutes.some((category) => category === params.folderID)) {
            setFileType(params.folderID);
        } else {
            const route = getDataViewRoute({
                productName: params.productName,
                viewType: params.viewType,
                folderID: params.folderID || SystemFolderID.All,
            });
            if (location.pathname !== route) {
                history.push(route);
            }
            setFileType('');
        }
    }, [history, params.folderID, params.productName, params.viewType, setFileType]);

    return (
        <AppLayout>
            <DataViewFolders />
            {isMeasurement ? <DataTableMeasurement /> : <DataTableCustomMaterial />}
            <ImportFileExists
                onClose={() => setCustomCurveNameConflicts(undefined)}
                customCurveNameConflicts={customCurveNameConflicts}
            />
        </AppLayout>
    );
};

const DataViewWithProvider: React.FunctionComponent = () => {
    return (
        <DataViewProvider>
            <DataView />
            <DataViewModal />
            <NonEditableModal />
        </DataViewProvider>
    );
};

export default DataViewWithProvider;
